

/**
 * 创建申请单
 */
export async function updatePatient(para) {
    await axios.post('/api/Order/Patient/UpdatePatient', para);
}


/**
 * 创建申请单
 */
export async function linkPatient(para) {
    await axios.post('/api/Order/Patient/LinkPatient', para);
}