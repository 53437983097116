<template>
  <a-modal
      v-model:visible="options.showDialog"
      title="请重新登录"
      centered
      :width="480"
      :closable="false"
      :maskClosable="false"
  >
    <template #footer>
      <div>
        <a-button type="link" @click="logout()">
          返回登录页
        </a-button>

        <a-button
            type="primary"
            :loading="options.isBusy"
            @click="doReLogin()"
            style="margin-left: 16px;"
        >
          登录
        </a-button>
      </div>
    </template>
    <a-form
        class="login-form"
        :wrapperCol="{ span: 24 }"
        :model="para"
        style="margin: 4px 16px;"
        :labelCol="{ span: 3 }"
    >
      <a-form-item
          name="UserName"
          v-if="(para.LoginType=='User')"
          label="用户名"
      >
        <a-input
            v-model:value="para.UserName"
            placeholder="用户名"
            :disabled="true"
            @keyup.enter.native="doReLogin()"
        >
        </a-input>
      </a-form-item>

      <a-form-item v-if="(para.LoginType=='CA')" label="用户名">
        <a-row :gutter="4">
          <a-col :span="18">
            <a-select
                v-model:value="para.CACertificateId"
                :options="options.certs"
                :disabled="true"
            >
            </a-select>
          </a-col>
          <a-col :span="6">
            <a-button type="primary" @click="loadCurUser" block>
              重试
            </a-button>
          </a-col>
        </a-row>
      </a-form-item>

      <a-form-item
          name="Password"
          v-if="(para.LoginType =='User' || para.LoginType == 'CA')"
          label="密码"
      >
        <a-input
            v-model:value="para.Password"
            type="password"
            placeholder="密码"
            @keyup.enter.native="doReLogin()"
            style="text-security: disc; -webkit-text-security: disc;"
        >
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-alert
            v-if="errInfo.show"
            :message="errInfo.message"
            type="error"
            show-icon
            closable
            @close="errInfo.clear()"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { LoginProxy }    from "@src/scripts/ris/ca/caLogin";
import { login, logout } from "@scripts/ris/auth/login";
import { reactive }      from "vue";

let para = reactive({
  UserName:        $ris.user.UserName,
  Password:        undefined,
  CACertificateId: undefined,
  LoginType:       undefined,
});

let resolve = null;

let options = reactive({
  setting:    {
    EnableCAIntegration: $ris.appSetting.EnableCAIntegration,
  },
  certs:      [],
  showDialog: false,
  isBusy:     false,
});

let loginHelper = new LoginProxy(options as any, para, true);
para.LoginType  = loginHelper.curLoginType;

let errInfo = reactive({
  show:    false,
  message: undefined,
  clear:   () => {
    errInfo.message == "";
  },
});

async function loadCurUser() {
  await loginHelper.loadAndBindCurUser();
}

async function doReLogin() {
  if (!para.Password) {
    $util.throwError("请输入密码");
  }

  let result = null;
  try {
    result = await loginHelper.login(() => {
      return login($ris.user.UserName, para.Password);
    }, false);
  } catch (e: any) {
    $util.throwError(e);
  }

  if (!result.StaffId) {
    $util.throwError(result.ErrorMessage);
  }

  para.Password      = undefined;
  options.showDialog = false;
  resolve();
}

async function show() {
  options.showDialog = true;
  await loginHelper.loadAndBindCurUser();
  return new Promise((res, rej) => {
    resolve = res;
  });
}

defineExpose({ show });
</script>

<style lang="less" scoped></style>
