<template>
  <a-modal title="HIS申请单" v-model:visible="para.visible" :width="900" :footer="null">
    <div :style="para.viewerStyle">
      <PdfViewer :src="para.preview"/>
    </div>
  </a-modal>
</template>

<script setup lang="ts">

import { reactive } from 'vue'

let para = reactive({
  visible:     false,
  viewerStyle: {},
  preview:     undefined,
});

defineExpose({
  show,
  showWithContent,
})

async function show(orderId) {

  await showDialog();

  let params = {
    orderId,
    type:   'HisOrder',
    format: 'Pdf',
  }

  let rsp     = await axios.get('/api/System/Print/GetDocument', { params, responseType: 'blob' });
  para.preview = rsp.data;
}

async function showWithContent(order) {
  await showDialog();
  let params = {
    templateType: 'HisOrder',
  }

  let rsp     = await axios.post('/api/System/Print/PreviewWithContent', order, { params, responseType: 'blob' });
  para.preview = rsp.data;
}

async function showDialog() {
  para.visible     = true;
  para.preview     = undefined;
  para.viewerStyle = {
    height: (getHeight() - 200) + 'px',
  }
  await $util.delay(300);   //等待弹窗动画
}

function getHeight() {
  let height = document.documentElement.clientHeight;
  height     = Math.min(height, 1440);
  height     = Math.max(height, 600)
  return height - 100;
}
</script>

<style scoped>

</style>