/**
 * 判断用户是否处于登录状态
 */
export function isUserLogin() {
    return hasSessionCookie();
}

/**
 * 清除会话信息
 */
export async function clearSession() {
    await $axios.delete('api/System/Login');
}

function hasSessionCookie(): boolean {
    let cookieName = 'session_auth';
    let reg        = new RegExp("(^| )" + cookieName + "=([^;]*)(;|$)");
    let cookies    = document.cookie.match(reg);
    return cookies !== undefined && cookies !== null;
}


