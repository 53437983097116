<template>
  <a-select v-model:value="valueRef" :mode="mode" :options="optionsRef"/>
</template>

<script setup lang="ts">

//主要用于解决基础数据被删除后，id无法显示的问题, 开销更大，更复杂， 非必要的场景下不要适用此select
import { ref, watch } from 'vue'

let props = defineProps<{
  options: any[],
  mode?: string,
  value?: number | string | any[],
  fallback?: (value: any) => Promise<any>,
}>();

//实现value的双向绑定（透传）
let emits = defineEmits(['update:value'])

let valueRef = ref(props.value);
watch(() => valueRef.value, v => emits('update:value', v));

let optionsRef = ref(props.options);

watch(() => props.value, value => {
  if (props.value !== valueRef.value) {
    valueRef.value = props.value;
    doUpdateOption();
  }
}, { immediate: true });

doUpdateOption();

function doUpdateOption() {
  optionsRef.value = $util.deepClone(props.options);
  enableFallback();
}

function enableFallback() {

  if (!props.value) {
    return;
  }

  //多选的时候props.value可能是数组
  let values = [props.value].flat()
                            .filter(value => !props.options.find(i => i.value === value));

  if (values.length === 0) {
    return;   //值都能匹配上，无需fallback
  }

  //当前的value没有匹配到默认值
  let fallback = props.fallback;
  if (!fallback) {
    console.warn('no dataType set. no fallback value ');
    return;
  }


  let isMultiSelect = props.mode === 'multiple' || props.mode === 'tags';

  values.forEach(value => {
    let extOpt = {
      label:    ref(''),
      value:    value,
      disabled: !isMultiSelect,     //多选模式不能禁用，禁用后不能去掉了
    };

    optionsRef.value.push(extOpt as any);
    fallback(value).then(name => extOpt.label.value = name);
  })
}


</script>

<style scoped>

</style>