import { reactive, watch, computed } from 'vue'
import { login, LoginResult }        from '@scripts/ris/auth/login'
import * as session                  from "@scripts/ris/session";
import { LoginProxy }                from '@src/scripts/ris/ca/caLogin';

export class LoginAction {
    helper: LoginProxy = null;
    para               = reactive({
        LoginType:       'User',
        UserName:        '',
        Password:        '',
        CACertificateId: ''
    });

    errInfo = reactive({
        show:    false,
        message: undefined,
    });

    options = reactive({
        isInitFinished:  false,
        setting:         {} as session.LoginSetting,
        isBusy:          false,
        certs:           [],
        loginTypeOption: [],
        rules:           {
            UserName: [{ required: true, message: '请输入用户名' }],
            Password: [{ required: true, message: '请输入密码' }]
        }
    });

    validate = undefined as () => Promise<boolean>
    private _waitTimerId = Symbol();

    constructor() {
        this.validate = $util.form.useForm(this.para, this.options.rules).validate;
        watch(() => this.options.rules, () => {
            this.validate = $util.form.useForm(this.para, this.options.rules).validate;
        });
    }

    async init() {
        //应用登录配置
        this.options.setting = await session.getLoginSetting();
        this.helper          = new LoginProxy(this.options, this.para);
        this.helper.loadLoginOptions();

        this.options.isInitFinished = true;
    }

    async doLogin() {
        this.errInfo.show = false;
        if (!await this.validate()) {
            return;
        }

        await this.loginCallback(this.helper.login(() => {
            return login(this.para.UserName, this.para.Password)
        }));
    }

    clearErrInfo() {
        this.errInfo.show = false;
        this.clearWaitTimer();
    }

    //启用等待计时器，更新界面消息
    async startWaitTimer(waitSeconds: number) {

        if (waitSeconds <= 0) {
            return;
        }

        let timerId = this._waitTimerId;

        while (--waitSeconds > 0) {

            this.errInfo.message = `在 ${waitSeconds} 秒后可继续操作`
            await $util.delay(1000);
            if (timerId !== this._waitTimerId) {
                //计时器变化了
                return;
            }
        }

        this.errInfo.show = false;
    }

    clearWaitTimer() {
        this._waitTimerId = Symbol();
    }

    private async loginCallback(loginFunc: Promise<LoginResult>) {
        this.options.isBusy = true;
        try {
            let result = await loginFunc;
            if (result.StaffId) {
                await session.loadSessionData(true);
                await $ris.router.push('/');
                this.clearErrInfo();
            }
            else {
                this.errInfo.show    = true;
                this.errInfo.message = result.ErrorMessage;
                this.startWaitTimer(result.WaitSeconds).then();
            }
        } catch (e: any) {
            this.errInfo.show    = true;
            this.errInfo.message = e.ErrorMessage || e;
        } finally {
            this.options.isBusy = false;
        }
    }
}

