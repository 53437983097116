import { reactive, watch } from "vue";
import { PatientData }     from "@src/pages/patient/main/scripts/common";
import * as api            from "@api/patient/patient";

export class OrderTimeLineContext {

    data = reactive({
        checkItems:      [],
        checkItemFilter: undefined,
        orderList:       [],
        activeOrderId:   undefined,
    });

    private _orders: OrderView[] = undefined;

    //通知当前申请单变化
    onOrderChange: (order: OrderView) => void;

    constructor() {
        watch(() => this.data.checkItemFilter, () => this.onCheckItemFilterChg());
        watch(() => this.data.activeOrderId, () => this.notifyOrderChg());
    }

    //患者信息变化
    async updatePatient(patient: PatientData) {
        let data             = this.data;
        data.checkItems      = [];
        data.checkItemFilter = undefined;
        data.orderList       = [];

        this._orders = [];

        if (!patient)
            return;

        let patientId = patient.Id;

        let orders   = await api.getPatientOrders(patientId) as OrderView[];
        this._orders = orders;

        let checkItems = orders.flatMap(i => i.Order.CheckItems.split('|'))
        checkItems     = [...new Set(checkItems)].sort();       //去重

        data.checkItems = checkItems.map(i => {
            return { value: i }
        });

        this.updateOrderList(orders);
    }

    onCheckItemFilterChg() {
        let filter = this.data.checkItemFilter;
        let orders = this._orders;

        if (filter){
            orders = this._orders.filter(i => i.Order.CheckItems.indexOf(filter) >= 0);
        }
        this.updateOrderList(orders);
    }

    notifyOrderChg() {
        let notify = this.onOrderChange;

        if (notify) {
            let order = this._orders.find(i => i.Id === this.data.activeOrderId);
            notify(order);
        }
    }

    updateOrderList(orders: OrderView[]) {
        this.data.orderList = orders.map(o => {
            let arriveTime = o.Order.ArriveTime;
            let timeInfo   = arriveTime ? $util.dayjs(arriveTime).format($util.format.day) : '';

            return {
                value: o.Id,
                label: `${timeInfo} [${o.Order.ModalityName}] ${o.Order.CheckItems}`,
            }
        });

        this.data.activeOrderId = orders[0]?.Id;
    }

}