
/**
 * 更新患者
 */
export async function createPatient(patient) {
    await axios.post('/api/Patient', patient);
}

/**
 * 更新患者
 */
export async function editPatient(patient) {
    await axios.put('/api/Patient', patient);
}


export async function search(para) {
    let rsp = await axios.post('/api/Patient/Search', para);
    return rsp.data;
}


/**
 * 查询同名患者
 * @param name 患者姓名
 * @returns {Promise<Array>}
 */
export async function getOrderByName(name) {
    let rsp = await axios.get('/api/Patient/Search/GetOrderByName', { params: { name } });
    return rsp.data;
}


/**
 * 根据GlobalPatientId查询患者
 */
export async function getByGlobalPatientId(globalPatientId) {
    let rsp = await axios.get('/api/Patient/Search/GetByGlobalPatientId', { params: { globalPatientId } });
    return rsp.data;
}


/**
 * 查找患者所有申请单
 * @returns {Promise<Array>}
 */
export async function getPatientOrders(patientId) {
    let rsp = await axios.get('/api/Patient/Search/GetOrders', { params: { patientId } });
    return rsp.data;
}
