import { dxDataGridOptions } from "devextreme/ui/data_grid";
import * as settingApi       from '@scripts/api/setting/userSetting'

type SelectionMode = 'multiple' | 'none' | 'single'

interface GridOption {
    //选择模式
    selectionMode?: SelectionMode,

    //显示分页信息
    pagerVisible?: true | false | 'auto',

    //store key
    storeKey?: string,

    //是否远端分页
    remotePaging?: boolean,

    // 是否各行换色显示
    rowAlternationEnabled: boolean
};


type PagerVisibleType = true | false | 'auto'       //'auto' (根据数据量和显示内容来自动判定)

/**
 * 生成grid的属性
 */
export function makeGridOption(
    {
        selectionMode = 'multiple' as SelectionMode,
        pagerVisible = true as PagerVisibleType,
        storeKey = undefined,                   //列配置Id
        remotePaging = false,
        rowAlternationEnabled = true
    }: GridOption = {}) {
    let pageSize = $ris.appSetting.PageSize ? $ris.appSetting.PageSize : 30;

    let opt = {
        keyExpr: 'Id',

        rowAlternationEnabled: rowAlternationEnabled,
        showRowLines:          true,
        showColumnLines:       true,
        showBorders:           true,
        columnMinWidth:        48,
        columnAutoWidth:       false,
        allowColumnResizing:   true,
        columnFixing:          { enabled: true },
        columnChooser:         {
            enabled:       true,
            mode:          'select',
            savingTimeout: 1000,
            allowSearch:   true,
            height:        480,
            width:         320,
            title:         '自定义列'
        },
        stateStoring:          saveLayout(storeKey),
        allowColumnReordering: true,
        hoverStateEnabled:     true,

        focusedRowEnabled:        true,
        autoNavigateToFocusedRow: false,
        columnResizingMode:       "widget",

        noDataText: '',
        loadPanel:  { enabled: 'auto', showPane: false, },

        paging: { pageSize: pageSize, },
        pager:  {
            visible:              pagerVisible,
            showInfo:             true,
            showPageSizeSelector: true,
            allowedPageSizes:     [30, 50, 100]
        },

        repaintChangesOnly: true,
        scrolling:          {} as any,

        remoteOperations: false,

        selection: {
            selectAllMode:      "page",           //page | allPages
            mode:               selectionMode,     // 'multiple' | 'none' | 'single'
            showCheckBoxesMode: "onClick",           //'always' | 'none' | 'onClick' | 'onLongTap'
        },
    } as dxDataGridOptions;

    if (!pagerVisible) {
        opt.paging.enabled = false;
    }

    if (remotePaging) {
        opt.keyExpr          = undefined;
        opt.remoteOperations = true;
        // opt.loadPanel.enabled = true;
        // opt.scrolling         = {
        //     mode:                'virtual',
        //     //columnRenderingMode: 'virtual',
        //     rowRenderingMode:    'virtual',
        //     preloadEnabled:      true,
        // };
    }

    return opt;
}

function saveLayout(storeKey) {

    if (!storeKey) {
        return { enabled: false, }
    }

    return {
        enabled:    true,
        type:       'custom',
        customSave: e => _layoutMgr.save(storeKey, e),
        customLoad: () => _layoutMgr.load(storeKey),
    }
}


class GridLayoutManager {
    private _cache = new Map<string, any>();

    //保存布局
    async save(key: string, option: any) {
        //debugger;
        let para = {
            columns: $util.deepClone(option.columns)
        };

        if(key == "ManageList") {
            para.columns.forEach(element => {
                if(element.dataField == "ConsignmentDate") {
                    element.sortIndex = 0;
                    element.sortOrder = "desc";
                }
                else {
                    element.sortOrder = null;
                }
            });
        }

        await settingApi.setGridLayout(key, para);

        this._cache.set(this.getCacheKey(key), para);
    }

    //加载布局
    async load(key: string) {
        try {
            let layout = this._cache.get(this.getCacheKey(key)) ?? await settingApi.getGridLayout(key);
            return layout;
        } catch (e) {
            //可能因为用户超时无法获取，由于不在vue的上下文里，不能统一走异常处理函数， 手动处理
            return undefined;       //此异常无需处理， 返回默认值即可，待加载数据时处理异常即可
        }
    }

    getCacheKey(key) {      //切换用户后缓存需要失效，加上用户名作为key的一部分
        return key + '.' + $ris.user.UserName;
    }
}

let _layoutMgr = new GridLayoutManager();