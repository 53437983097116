<template>

  <a-modal v-model:visible="data.visible" title="患者申请单历史记录" :footer="null" :width="1000">
    <div style=" margin: -8px; height: 800px;">
      <patient-detail :patient="data.patientData" :readOnly="true"/>
    </div>
  </a-modal>

</template>

<script setup lang="ts">

import * as api        from '@api/patient/patient'
import PatientDetail   from '@src/pages/patient/detail/patientDetail.vue'
import { PatientData } from '@src/pages/patient/main/scripts/common'
import { reactive }    from 'vue'

let data = reactive({
  visible:     false,
  patientData: undefined as PatientData
});

defineExpose({
  show,
});

async function show(globalPatientId: string) {
  data.visible     = true;
  data.patientData = await api.getByGlobalPatientId(globalPatientId);
}

</script>

<style scoped>

</style>