import { reactive }       from "vue";
import { getOrderScheme } from "../../orderEditor/scripts/scheme";

let schemeMap = undefined;
/**
 * 根据label查询可见状态
 */
export function isVisible(label) {

    if (!schemeMap) {
        let scheme = reactive(getOrderScheme());

        schemeMap = new Map();
        let schemeFields = Object.values(scheme).flatMap(Object.values);
        for (const scheme of schemeFields) {
            schemeMap.set(scheme.label, scheme);
        }
    }


    let fieldScheme = schemeMap.get(label);
    if (!fieldScheme)
        return  true;
    return fieldScheme.visible;
}