import { Defer }           from '@scripts/types'
import { ref, watch, Ref } from 'vue'

export class StoreHelper<T> {
    private store: Storage;
    readonly key: string;

    constructor(key: string, store: Storage = undefined) {
        this.store = store ?? localStorage;
        this.key   = key;
    }

    getValue(defaultValue: T = undefined) {
        let value = this.store.getItem(this.key)
        if (!value)
            return defaultValue;
        else
            return JSON.parse(value) as T;
    }

    setValue(value: T) {
        this.store.setItem(this.key, JSON.stringify(value));
    }

    removeValue() {
        this.store.removeItem(this.key);
    }
}

//获取一个全局的ref<T>, 值发生变化时可以自动保存
export function getGlobalAutoRef<T>(key: string, defaultValue: T): Ref<T> {

    return GlobalRefStore.allocate(key,  defaultValue);
}


export class GlobalRefStore {

    static refValues   = new Map<string, any>();
    static waitHandlers = new Map<string, Defer<void>>();

    key: string;

    constructor(key) {
        this.key = key;
    }

    //获取一个全局的ref<T>, 值发生变化时可以自动保存
    static allocate<T>(key: string, defaultValue: T): Ref<T> {
        if (!key) {
            throw 'key is empty';
        }

        let refValue = this.refValues.get(key);
        if (refValue) {
            return refValue;
        }

        refValue = ref(defaultValue);
        this.refValues.set(key, refValue);
        this.waitHandlers.set(key,  new Defer<void>());

        //从indexdb中加载数据
        $util.indexDB.getItem(key).then(v => {
            if (v)
                refValue.value = v;

            //通知加载完成
            this.waitHandlers.get(key).resolve(undefined);
            this.waitHandlers.delete(key);
        });
        //数据变更后保存数据
        watch(() => refValue.value, v => $util.indexDB.setItem(key, v));

        return refValue;
    }

    //等待加载完成
    static async waitLoadValue(key) {
        let defer = this.waitHandlers.get(key);
        if (!defer){
            return;
        }
        await defer.promise;
    }
}