<template>
  <div style="height: 107px;">
    <div class="login-header"></div>
  </div>
  
  <div style="background-image: linear-gradient(to left, #8579C5, #4169E1); position:relative;">
  <div class="login-warpper">
    
    <div class="login-box">
      <h2 class="hospital" v-if="isLogin">{{ options.setting.SystemName }}</h2>
      <a-form v-if="options.isInitFinished && isLogin"
              class="login-form"
              :wrapperCol="{ span: 24 }"
              :model="para"
              :rules="action.rules">
              
        <!--    防止自动填充  -->
        <input style="opacity: 0;position:absolute;width:0;height:0;">
        <input type="password" style="opacity: 0;position:absolute;width:0;height:0;">

        <a-form-item v-if="options.setting.EnableCAIntegration && (options.loginTypeOption.length>0)">
          <div class="select-wraper">
            <span class="select-icon"><ris-icon icon="IdcardOutlined" class="icon"/></span>
            <a-select
                class="login-type-select"
                :options="options.loginTypeOption"
                v-model:value="para.LoginType"
                placeholder="登录方式">
            </a-select>
          </div>
        </a-form-item>
        <a-form-item name="UserName" v-if="(para.LoginType=='User')">
          <a-input v-model:value="para.UserName" placeholder="用户名" autofocus="autofocus" 
                   @keyup.enter.native="action.doLogin()">
            <template #prefix>
              <ris-icon icon="UserOutlined" class="icon"/>
            </template>
          </a-input>
        </a-form-item>
        <a-form-item v-if="(para.LoginType=='CA')">
          <a-select v-model:value="para.CACertificateId" :options="options.certs">
          </a-select>
        </a-form-item>
        <a-form-item name="Password" v-if="(para.LoginType =='User' || para.LoginType == 'CA')">
          <a-input 
            v-model:value="para.Password"
            type="password" 
            placeholder="密码" 
            @keyup.enter.native="action.doLogin()">
            <template #prefix>
              <ris-icon icon="LockOutlined" class="icon"/>
            </template>
          </a-input>
        </a-form-item>
        <a-form-item style="padding-top: 50px;">
          <a-button type="primary" :loading="options.isBusy" block @click="action.doLogin()">
            <span style="margin-left: 24px; margin-right: 24px">登录</span>
          </a-button>
        </a-form-item>
        <a-form-item>
          <a-button type="link" block @click="switchLoginRegist" danger>
            <span style="margin-left: 24px; margin-right: 24px">立即注册</span>
          </a-button>
        </a-form-item>
        <a-form-item>
          <a-alert v-if="errInfo.show" :message="errInfo.message" type="error"
                   show-icon closable
                   @close="action.clearErrInfo()"/>
        </a-form-item>
      </a-form>
      <h2 class="hospital" v-if="!isLogin">欢迎注册</h2>
      <a-form v-if="!isLogin" class="login-form"
              :wrapperCol="{ span: 24 }">
          
          <a-form-item><a-button type="link" @click="switchLoginRegist" danger>已有账号？去登录</a-button></a-form-item>
          <a-form-item>
            <a-input placeholder="请完整填写企业名称" v-model:value="registUserInfo.EnterpriseName">
              <template #prefix>
                <insurance-outlined />
              </template>
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-input placeholder="请输入真实姓名" v-model:value="registUserInfo.Name">
              <template #prefix><user-outlined /></template>
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-input placeholder="请输入您的手机号,可用于登录" v-model:value="registUserInfo.Telephone">
              <template #prefix>+86</template>
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-input placeholder="请设置登录密码" type="password" v-model:value="registUserInfo.Password" @keyup.enter.native="registUser">
              <template #prefix>
                <lock-outlined />
              </template>
            </a-input>
          </a-form-item>
          <a-form-item style="padding-top: 1vh;">
            <a-button type="primary" :loading="options.isBusy" block @click="registUser">
              <span style="margin-left: 24px; margin-right: 24px">去注册</span>
            </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
  </div>
  <div style="height: 107px;">
  <div class="login-footer"></div>
  </div>
</template>

<script setup lang="ts">

import { ref } from 'vue';
import { LoginAction } from './scripts/login'
import { InsuranceOutlined, UserOutlined, LockOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { registeClientUser } from '@src/scripts/api/consignment/consignment';

let action  = new LoginAction();
let errInfo = action.errInfo;
let para    = action.para;
let options = action.options;
let isLogin = ref(true);
let registUserInfo = ref({
  EnterpriseName: '' as string,
  Name:'' as string,
  Telephone: '' as string,
  Password: '' as string
});

action.init();

function switchLoginRegist() {
  isLogin.value = !isLogin.value;
}

async function registUser() {
  if(!registUserInfo.value.EnterpriseName) {
    message.error('请输入企业名称');
    return;
  }

  if(!registUserInfo.value.Name) {
    message.error('请输入姓名');
    return;
  }

  if(!registUserInfo.value.Telephone) {
    message.error('请输入手机号');
    return;
  }

  if(!registUserInfo.value.Password) {
    message.error('请输入密码');
    return;
  }

  let result = await registeClientUser(registUserInfo.value);

  if(result.ErrMsg) {
    message.error(result.ErrMsg);
  }
  else{
    message.success("注册成功，请等待审核");
    registUserInfo.value.EnterpriseName = '';
    registUserInfo.value.Password = '';
    registUserInfo.value.Telephone = '';
    registUserInfo.value.Telephone = '';
    registUserInfo.value.Name = '';
  }

}

</script>

<style lang="less" scoped>

</style>

<style scoped>

.login-warpper {
  position: relative;
  display: flex;
  justify-content: right;
  height: 69vh;
  background: url(/static/image/login/bg-body.fw.png) no-repeat 20vh center;
  background-size: 80vw 60vh;
  padding-right: 150px;
}

.login-header {
  float: right;
  align-items: center;
  justify-content: right;
  background: url(/static/image/login/bg-header.fw.png) no-repeat center center;
  width: 351px; height: 107px;
}

.login-footer {
  display: flex;
  align-items: center;
  justify-content: right;
  height: 160px;
  background: url(/static/image/login/bg-footer.fw.png) no-repeat 65vh center;
  left: 100px
}

.login-box {
  display: inline-block;
  width: 35vh;
  height: 30vh;
  /*background: #fff url(/static/image/login/bg2.jpg) no-repeat left bottom;*/
  /* background: #fff; */
  box-shadow: 0 0 20px #333;
  border-radius: 10px;
  /* top: 7vw;
  right: 17vw; */
  top: 19%;
  right: 18%;
  position: absolute;
}

.hospital {
  padding: 15px 20px 0 20px;
  line-height: 32px;
  font-size: 26px;
  font-weight: normal;
  color:black;
}

.login-form {
  padding: 10px 20px;
}

.login-type-select :deep(.ant-select-selection-item) {
  margin-left: 20px
}

.icon {
  color: rgba(0, 0, 0, 0.25)
}

.select-icon {
  position: absolute;
  left: 12px;
  display: inline-block;
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  z-index: 1;
}


</style>