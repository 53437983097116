import * as client        from "@api/main/client";
import * as reportSearch  from '@api/report/search'
import { bufferToBase64 } from '@scripts/util/common'

/**
 * 打印申请单
 */
export async function printOrder(orderId, template: PrintType, count = 1) {

    /*
     * templateType 取值类型
     * 1. HisOrder HIS申请单
     * 2. InformedConsent 知情同意书
     * 3. Checkin  到检小条
     * 4. Schedule 预约回执
     * 5. ExamNotice 检查通知单
     * 6. FetchFilm 取片凭据
     * 7. Report 根据申请单Id打印报告 （特殊流程）
     */
    if (['HisOrder', 'InformedConsent', 'Checkin', 'Schedule', 'ExamNotice', 'FetchFilm', 'Report'].indexOf(template) < 0) {
        $util.throwError(`无效打印类型 【${template}】`);
    }

    if (template === 'Report') {
        let reportId = await reportSearch.getReportIdByOrder(orderId);
        await printReport(reportId);
        return;
    }

    let fileName = $util.dayjs().format('HHmmss') + ".Xps";
    let params   = {
        orderId,
        fileName,
        type:     template,
        isReport: false,
    };

    let rsp = await axios.get('/api/System/Print/GetDocument',
        { responseType: 'arraybuffer', params })

    let data = bufferToBase64(rsp.data);
    await client.print(fileName, data, 'Xps', template, count);
}


/**
 * 根据报告Id打印报告
 * @param reportId
 */
export async function printReport(reportId) {

    //获取打印内容
    let rsp = await axios.get('/api/Report/Print/Content',
        { responseType: 'arraybuffer', params: { reportId } })

    let data     = bufferToBase64(rsp.data);
    let fileName = $util.dayjs().format('HHmmss') + ".pdf";

    await client.print(fileName, data, ".pdf", "Report", 1);

    //设置打印标记
    await axios.put('/api/Report/Print/SetPrintedFlag', undefined,
        { params: { reportId } });
}


export function canPrintOrder(order: OrderView, printType: PrintType) {

    let status = order.Status;
    if (status === "Canceled")
        return false;

    switch (printType) {

        case "Schedule":
            return status === 'Ordered';

        case 'Checkin':
            return status !== "Ordered" && !order.IsArchived;		//到检后都可以打印

        case 'HisOrder':
            return order.Order.IsFromHIS;

        case 'Report':
            return status === 'Reported' || ($ris.appSetting.EnablePrintBeforeAudit && status === 'Reporting');

        case 'FetchFilm':
            return status === 'Studyed';

        case 'ExamNotice':
        case 'InformedConsent':
            return true;

    }


    return false;
}