<template>
  <div class="root disable-select-text" >
    <a-space style="margin-left: -24px">
      <a-breadcrumb style=" ">
        <a-breadcrumb-item @click="goHome" href="javascript:void(0)">
          <ris-icon icon="HomeOutlined"/>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <router-link to='/'>
            首页
          </router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item v-if="!ctx.isRoot">
          <span style="font-weight: bold">{{ ctx.name }}</span>
        </a-breadcrumb-item>
      </a-breadcrumb>
    </a-space>
  </div>
</template>

<script setup lang="ts">

import { useRoute }                   from "vue-router";
import { watch, reactive, onMounted } from "vue";

let ctx = reactive({
  name:   '',
  isRoot: true,
});

const route = useRoute()
watch(() => route.path, update);

onMounted(update)

function update() {
  //目前就一级导航
  let route  = $ris.router.currentRoute.value;
  ctx.name   = route.name as string;
  ctx.isRoot = route.path === '/' || route.name === undefined;
}

function goHome() {
  $ris.router.push('/');
}


</script>

<style scoped>
.root {
  display: flex;
  align-items: center; /* 垂直居中 */
  height: 100%;
}

</style>