import { createApp } from 'vue'

import App   from './App.vue'
import gInit from './scripts'
import Print from 'vue3-print-nb'
import VueDragscroll from 'vue-dragscroll'

createApp(App)
    .use(gInit)
    .use(VueDragscroll)
    .use(Print)
    .mount('#app');
