//自定义控件
import NavLayout   from "/src/components/common/NavLayout.vue";
import RisIcon     from "/src/components/common/RisIcon.vue";
import PdfViewer   from "/src/components/common/pdfViewer.vue";
import RisSelect   from "/src/components/common/RisSelect.vue";
import RisTabs     from "/src/components/tab/RisTabs.vue";
import RisTreeView from "/src/components/tree/RisTreeView.vue";


//此处只存放全局通用组件，尽量避免将组件放入此处

export let CustomControls = {
    install: (app, options) => {
        //自定义控件
        app.component('NavLayout', NavLayout);
        app.component('RisIcon', RisIcon);
        app.component('RisSelect', RisSelect);
        app.component('PdfViewer', PdfViewer);
        app.component('RisTabs', RisTabs);
        app.component('RisTreeView', RisTreeView);


        // //解决quill复制时多余换行问题  todo 全局导入
        // //https://github.com/quilljs/quill/issues/861
        // let Block     = Quill.import('blots/block');
        // Block.tagName = 'DIV';
        // Quill.register(Block, true);
    }
};

