/**
 * 异步加载的时候，有一个先发后至的问题：
 * 1. key1的数据还没有加载出来的时候， 切换到key2
 * 2. value2 先返回
 * 3. value1 后返回
 * 4. 此时界面上 key2上 对应展示了 value1
 *
 * 需要对每个请求加个sessionId， 判断当前请求的key是否已经变化
 */

export class AsyncTaskKey {
    private _key = Symbol();

    generate() {
        this._key = Symbol();
        return this._key;
    }

    get current() {
        return this._key;
    }

    // /**
    //  * 异步加载，加入了防止请求变更检测
    //  * @param task
    //  * @param callback  如果请求的key变化了 ，则不执行
    //  */
    // async loadAsync<T>(task: Promise<T>, callback: (data: T)=> void ) {
    //     //每次请求key都会变化
    //     let key  = this.generate();
    //     let data = await task;
    //
    //     if (key !== this.current) {
    //         callback(data);
    //     }
    // }
}


