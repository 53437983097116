

export async function getAll(orderId) {
    let rsp = await axios.get('/api/Order/Comment', { params: { orderId } });
    return rsp.data;
}

export async function add(orderId, message, staff) {
    let data = {
        staff,
        message,
    };

    let rsp = await axios.post(
        '/api/Order/Comment',
        data,
        { params: { orderId } }
    );
    return rsp.data;
}


export async function remove(commentId) {
    await axios.delete('/api/Order/Comment', { params: { commentId } })
}
