
import '@src/style/antd/antd.patch.less'
import '@src/style/antd/antd.global.css'
import '@src/style/antd/antd.patch.v3.css'

//antd vue
import Antd          from 'ant-design-vue';
import * as antIcons from '@ant-design/icons-vue';

export let AntControls = {
    install: (app, options) => {
        app.use(Antd);

        // 添加到全局
        app.config.globalProperties.$antIcons = antIcons

        function useIcon(icon) {
            app.component(icon, antIcons[icon]);
        }
    }
}