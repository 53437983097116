import { AxiosError }     from 'axios';
import { OperationError } from "@scripts/types/err";
import { logout }         from "@scripts/ris/auth/login";


//一些异步异常信息捕获用vue捕获不到， 这里可以处理， 但目前控制台仍然有异常信息输出
//如：报告工作站重名自定义查询保存应给出重名提示
window.addEventListener('unhandledrejection',
    e => {
        if (tryProcessErr(e.reason)) {
            e.stopImmediatePropagation();
        }
    });

/**
 * 全局异常处理程序
 */
export function onGlobalError(err, vm, info) {

    if (tryProcessErr(err)) {
        return;
    }

    console.error(err);
    throw err;
}

//错误处理函数
function tryProcessErr(err) {

    if (err instanceof OperationError) {
        err.process();
        return true;
    }

    if (handleRestError(err)) {
        return true;
    }

    return false;
}

const utf8Enc = new TextDecoder("utf-8");

/**
 * 解析通用Rest异常信息
 */
export function getRestErrInfo(err: AxiosError) {

    if (!err.isAxiosError) {
        return undefined;
    }


    let status = err.response.status;
    let data = parseData(err.response.data);

    return {
        status,
        data,
        message:     data?.Message as string,
        needConfirm: data?.NeedConfirm as boolean,
    }


    function parseData(data: any) {

        if (!data) {
            return undefined;
        }

        if (data instanceof ArrayBuffer) {
            //部分获取图片的场景会使用ArrayBuffer类型
            let json = utf8Enc.decode(data);
            return JSON.parse(json);
        }

        return data;
    }
}

//处理ris api返回的rest异常
function handleRestError(err: AxiosError) {

    let errInfo = getRestErrInfo(err);
    if (!errInfo) {
        return false;
    }

    switch (errInfo.status) {

        case 401:           //和 onAuthError 的 Expired 场景走同一个处理函数
            onAuthError({ State: 'Expired' });
            break;

        case 435:                //用户认证信息异常
            onAuthError(errInfo.data);
            break;

        case 436:               //操作错误， 一般提示给用户， 不进行下一步操作
            //$util.modal.error({title:'错误', content: data.Message})
            if (!errInfo.needConfirm)
                $util.message.error(errInfo.message);
            else
                $util.dialogs.confirm(errInfo.message, '错误', true).then();
            break;

        default:
            $util.notify.error({
                message:     '系统错误, 请联系管理员',
                description: undefined,
                placement:   'bottomRight',
            });

            // alert('系统错误, 请联系管理员')
            console.error("系统错误, 未识别异常， 请联系管理员", errInfo);
    }
    return true;
}




//处理身份认证异常
function onAuthError(error) {

    //可能多个异常同时上报，导致此接口并发执行， 先设置标志位，防重入
    if ($ris.user.IsLogout) {
        return;
    }

    switch (error.State) {
        case 'Expired':
            showAuthErr('用户登录超时，请重新登录');
            break;

        case 'NotLogin':
            break;

        case 'ErrorAuthorizationCode':
            showAuthErr('当前用户已经在其它地方登录');
            break;

        default:
            console.error('未知异常状态', error);      //异常流程，需要排查
            $util.modal.error({
                title:   '系统错误',
                content: '错误的用户状态，请联系管理员',
            });
            break;
    }
}

//是否已经显示该错误对话框 （可能有身份认证消息同时发出，这里加个标记，防止显示多个对话框）
let _isAuthErrShown = false;

function showAuthErr(errMsg: string) {

    if (_isAuthErrShown) {
        return;
    }

    $util.modal.warning({
        title:   '提醒',
        content: errMsg,
        onOk:    doLogout,
    });

    _isAuthErrShown = true;

    //防止异常情况下onOK回调没有执行， 只抑制15s
    setTimeout(()=> _isAuthErrShown = false, 15000);

    function doLogout() {
        _isAuthErrShown = false;
        logout().then();
    }
}



