/**
 * 搜索报告
 * @param para
 */
export async function searchReports(para) {
    let result = await axios.post('/api/Report/Search', para);
    return result.data;
}

/**
 * 根据Id查询报告
 */
export async function searchReportById(reportId) {
    let result = await axios.get('/api/Report/Search', { params: { reportId } });
    return result.data as ReportListView;
}



/**
 * 根据申请单Id获取报告Id
 */
export async function getReportIdByOrder(orderId) {
    let result = await axios.get('/api/Report/Search/GetIdByOrder', { params: { orderId } });
    return result.data as string;
}
