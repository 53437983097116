<template>
  <div>

    <time-line :order-id="order.Id"></time-line>

    <orderComments :orderId="order.Id" :is-read-only="!allowAddComments" :min-height="20"></orderComments>
    
    <a-divider orientation="left">患者信息</a-divider>
    <showAllBtn style="margin-top: -40px" v-model:showAll="$ris.stations.order.viewOption.showAll.value"/>

    <a-descriptions bordered size="small" :column="2">

      <a-descriptions-item label="患者姓名" v-if="isVisible('患者姓名')">
        {{ order.Patient.Name }}
      </a-descriptions-item>

      <a-descriptions-item label="拼音名" v-if="isVisible('拼音名')">
        {{ order.Patient.SpellName }}
      </a-descriptions-item>

      <a-descriptions-item label="患者编号" v-if="isVisible('患者编号')">
        <span v-if="hideLinkOnPid">{{ order.Patient.GlobalPatientId }}</span>
        <span v-else>
          <a @click="showPatientOrders">{{ order.Patient.GlobalPatientId }}</a>
          
          <a v-if="$ris.common.canEditPatient(order)"
            style="color: red; margin-left: 4px"
            @click="onChangePatient">
            更改
          </a>
        </span>

      </a-descriptions-item>

      <a-descriptions-item label="HIS患者编号" v-if="isVisible('HIS患者编号')">
        {{ order.Order.HisPatientId }}
      </a-descriptions-item>

      <a-descriptions-item label="性别" v-if="isVisible('性别')">
        {{ order.Patient.GenderDisplay }}
      </a-descriptions-item>


      <a-descriptions-item label="年龄" v-if="isVisible('年龄')">
        {{ order.Patient.AgeDisplay }}
      </a-descriptions-item>

      <a-descriptions-item label="出生日期" v-if="isVisible('出生日期')">
        {{ $util.display.dayToStr(order.Patient.DateOfBirth) }}
      </a-descriptions-item>

      <a-descriptions-item label="民族" v-if="isVisible('民族')">
        {{ order.Patient.NationDisplay }}
      </a-descriptions-item>

      <a-descriptions-item label="身份证号" v-if="isVisible('身份证号')">
        {{ order.Patient.IDCard }}
      </a-descriptions-item>

      <a-descriptions-item label="婚姻状况" v-if="isVisible('婚姻状况')">
        {{ order.Patient.MarriageDisplay }}
      </a-descriptions-item>

      <a-descriptions-item label="社保号" v-if="isVisible('社保号')">
        {{ order.Patient.SocietyNumber }}
      </a-descriptions-item>

      <a-descriptions-item label="联系电话" v-if="isVisible('联系电话')">
        {{ order.Patient.Telephone }}
      </a-descriptions-item>

      <a-descriptions-item label="地址" v-if="isVisible('地址')">
        {{ order.Patient.Address }}
      </a-descriptions-item>

      <a-descriptions-item label="邮箱" v-if="isVisible('邮箱')">
        {{ order.Patient.Email }}
      </a-descriptions-item>


    </a-descriptions>

    <a-divider orientation="left">检查信息</a-divider>

    <a-descriptions bordered size="small" v-if="isVisible('检查号')" :column="2">
      <a-descriptions-item label="检查号">

        <span v-if="!order.Order.IsMatch">
          {{ order.Order.AccessionNumber }}
        </span>

        <a style="margin-left: 4px" v-else
           @click="$ris.common.openImage(order)">
          {{ order.Order.AccessionNumber }}
        </a>

      </a-descriptions-item>

      <a-descriptions-item label="HIS申请单号" v-if="isVisible('HIS申请单号')">
        {{ order.Order.HisOrderCode }}
      </a-descriptions-item>

      <a-descriptions-item label="申请科室" v-if="isVisible('申请科室')">
        {{ order.Order.ApplyDepartment }}
      </a-descriptions-item>

      <a-descriptions-item label="申请医生" v-if="isVisible('申请医生')">
        {{ order.Order.ApplyDoctor }}
      </a-descriptions-item>

      <a-descriptions-item label="检查科室" v-if="isVisible('检查科室')">
        {{ order.Order.ExecDepartment }}
      </a-descriptions-item>

      <a-descriptions-item label="设备类型" v-if="isVisible('设备类型')">
        {{ order.Order.ModalityName }}
      </a-descriptions-item>

      <a-descriptions-item label="检查室" v-if="isVisible('检查室')">
        {{ order.Order.ExamRoom }}
      </a-descriptions-item>

      <a-descriptions-item label="专项检查" v-if="isVisible('专项检查')">
        {{ (order.Order.OrderSpecialClass?'是':'否') }}
      </a-descriptions-item>

      <a-descriptions-item label="诊断组" v-if="isVisible('诊断组')">
        {{ order.Order.DiagnoseGroup }}
      </a-descriptions-item>

      <a-descriptions-item label="总费用">
        {{ order.Order.TotalFee }} (元)
      </a-descriptions-item>

    </a-descriptions>


    <procedureViewer :order="order"/>
    <div style="height: 8px"></div>
    <a-descriptions bordered size="small" :column="2">

      <a-descriptions-item label="特殊要求" v-if="isVisible('特殊要求')" :span="2">
        {{ order.Order.SpecialRequire }}
      </a-descriptions-item>

      <a-descriptions-item label="备注" v-if="isVisible('备注')" :span="2">
        {{ order.Order.Note }}
      </a-descriptions-item>

      <a-descriptions-item v-if="order.Schedule" label="预约时间" :span="2">
        {{ order.Schedule.ScheduleTime }}
      </a-descriptions-item>

    </a-descriptions>


    <a-divider orientation="left">就诊信息</a-divider>

    <a-descriptions bordered size="small" :column="2">

      <a-descriptions-item label="VIP" v-if="order.Visit.IsVIP">
        是
      </a-descriptions-item>

      <a-descriptions-item label="授权医生" v-if="order.Visit.IsVIP">
        {{ order.Visit.VIPReportDoctor }}
      </a-descriptions-item>


      <a-descriptions-item label="患者来源" v-if="isVisible('患者来源')">
        {{ order.Visit.PatientTypeDisplay }}
      </a-descriptions-item>

      <a-descriptions-item label="危重值" v-if="isVisible('危重值')">
        {{ order.Visit.CriticalDisplay }}
      </a-descriptions-item>

      <a-descriptions-item label="紧急程度" v-if="isVisible('紧急程度')">
        {{ order.Visit.EmergencyDegreeDisplay }}
      </a-descriptions-item>

      <a-descriptions-item label="医嘱类型" v-if="isVisible('医嘱类型')">
        {{ order.Visit.DoctorAdviceTypeDisplay }}
      </a-descriptions-item>

      <a-descriptions-item label="住院号" v-if="isVisible('住院号')">
        {{ order.Visit.InpatientNumber }}
      </a-descriptions-item>

      <a-descriptions-item label="门诊号" v-if="isVisible('门诊号')">
        {{ order.Visit.ClinicalNumber }}
      </a-descriptions-item>


      <a-descriptions-item label="病案号" v-if="isVisible('病案号')">
        {{ order.Visit.MedicalRecordNumber }}
      </a-descriptions-item>

      <a-descriptions-item label="病床号" v-if="isVisible('病床号')">
        {{ order.Visit.BedNumber }}
      </a-descriptions-item>

      <a-descriptions-item label="病区号" v-if="isVisible('病区号')">
        {{ order.Visit.RoomNumber }}
      </a-descriptions-item>

      <a-descriptions-item label="就诊流水号" v-if="isVisible('就诊流水号')">
        {{ order.Visit.VisitSerialNumber }}
      </a-descriptions-item>


      <a-descriptions-item label="身份" v-if="isVisible('身份')">
        {{ order.Visit.PatientIdentityDisplay }}
      </a-descriptions-item>


      <a-descriptions-item label="费用类别" v-if="isVisible('费用类别')">
        {{ order.Visit.ChargeTypeDisplay }}
      </a-descriptions-item>

      <a-descriptions-item label="过敏史" v-if="isVisible('过敏史')" :span="2">
        {{ order.Visit.AllergyHistory }}
      </a-descriptions-item>

      <a-descriptions-item label="传染病检查" v-if="isVisible('传染病检查')" :span="2">
        {{ order.Visit.InfectionResultDisplay }}
      </a-descriptions-item>

      <a-descriptions-item label="UBT" v-if="isVisible('UBT')" :span="2">
        {{ order.Visit.HpTestResultDisplay }}
      </a-descriptions-item>


      <a-descriptions-item label="临床诊断" v-if="isVisible('临床诊断')" :span="2">
        {{ order.Visit.ClinicalDiagnosis }}
      </a-descriptions-item>

      <a-descriptions-item label="化验结果" v-if="isVisible('化验结果')" :span="2">
        {{ order.Visit.LaboratoryReport }}
      </a-descriptions-item>

      <a-descriptions-item label="其它诊断" v-if="isVisible('其它诊断')" :span="2">
        {{ order.Visit.OtherDiagnosis }}
      </a-descriptions-item>


      <a-descriptions-item label="症状" v-if="isVisible('症状')" :span="2">
        {{ order.Visit.Symptom }}
      </a-descriptions-item>

      <a-descriptions-item label="体征" v-if="isVisible('体征')" :span="2">
        {{ order.Visit.Sign }}
      </a-descriptions-item>


      <a-descriptions-item label="病史" v-if="isVisible('病史')" :span="2">
        {{ order.Visit.DiseaseHistory }}
      </a-descriptions-item>

      <a-descriptions-item label="既往史" v-if="isVisible('既往史')" :span="2">
        {{ order.Visit.PastHistory }}
      </a-descriptions-item>

    </a-descriptions>

    <a-divider orientation="left">附加信息</a-divider>
    <showAllBtn style="margin-top: -40px" v-model:showAll="$ris.stations.order.viewOption.showExtra.value"/>
    <div v-if="$ris.stations.order.viewOption.showExtra.value">

      <a-descriptions bordered size="small" :column="2">
        <a-descriptions-item v-for="(field, name) in exScheme.fields"
                             :label="field.label">
          {{ exData[name] }}
        </a-descriptions-item>
      </a-descriptions>

      <a-row style="margin: 16px; ">
        <a-checkbox v-for="(opt, name) in exScheme.options" :checked="exOptions.has(name)">
          {{ opt.label }}
        </a-checkbox>
      </a-row>
    </div>

    <link-patient :ref="dialogs.changePatient"/>
    <patientOrders :ref="dialogs.patientOrders"/>

  </div>


</template>

<script setup lang="ts">

import TimeLine            from "./widgets/timeLine.vue";
import ProcedureViewer     from "../procedureList/procedureViewer.vue";
import OrderComments       from "../comments/orderComments.vue";
import ShowAllBtn          from "../orderEditor/widgets/showAllBtn.vue";
import LinkPatient         from "@src/pages/patient/share/linkPatient/linkPatient.vue";
import PatientOrders     from "./widgets/patientOrders.vue";

import * as scheme         from './scripts/scheme'
import { getExtendScheme } from "../orderEditor/scripts/extraInfo";
import { computed, ref }   from "vue";


const props = defineProps<{
  order: OrderView,
  allowAddComments?: boolean,
  hideLinkOnPid?: boolean
}>();

let exScheme  = getExtendScheme();
let exData    = computed(() => props.order.Additional);
let exOptions = computed(() => new Set(props.order.Additional.options));

let dialogs = {
  changePatient: ref(),
  patientOrders: ref(),
}

async function onChangePatient() {
  let order = props.order;
  await dialogs.changePatient.value.show(order);
}

function showPatientOrders(){
  let gPid = props.order.Patient.GlobalPatientId;
  dialogs.patientOrders.value.show(gPid);
}

function isVisible(label) {
  return scheme.isVisible(label);
}
</script>

<style lang="less" scoped>

.ant-descriptions ::v-deep(.ant-descriptions-item-label) {
  padding: 4px 4px; /* 调低label高度，减少空间占用 */
  width: 90px;
}

.ant-descriptions ::v-deep(.ant-descriptions-item-content) {
  padding: 4px 4px; /* 调低label高度，减少空间占用 */
  width: 180px;
}

</style>