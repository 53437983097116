<template>

  <div style="height: 100%; overflow-y: hidden">
    <iframe style="overflow:hidden; height:100%; width:100%; border: 1px solid rgba(187, 187, 188, 1);"
            :src="frameSrc"></iframe>
  </div>

</template>

<script setup lang="ts">

import { PdfViewerHelper }         from './scripts/pdfViewer'
import { ref, watch, onUnmounted } from 'vue'

//使用pdfjs渲染的pdf查看器
let props = defineProps<{
  src?: any,   //文件路径，支持url和blob类型
}>();

let sessionId = 1;    //异步更新时防止后发先至
let frameSrc  = ref(undefined as string);


let pdfHelper = new PdfViewerHelper();

watch(() => props.src, updateFrameSrc, { immediate: true });
onUnmounted(()=> pdfHelper.destory());


async function updateFrameSrc() {
  sessionId++;

  if (!props.src) {
    frameSrc.value = undefined;
    return;
  }

  let urlSessionId = sessionId
  let fileUrl      = await pdfHelper.getUrl(props.src);
  let url          = '/static/pdfjs/web/viewer.html?canPrint=false&file=' + encodeURIComponent(fileUrl);

  if (urlSessionId !== sessionId) {
    return;
  }
  frameSrc.value = url;
}

</script>

<style scoped>

</style>