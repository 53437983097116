import { getUserSetting }    from "@api/setting/userSetting";
import { loadOrderTemplate } from '@src/pages/order/regist/scripts/templates'
import { getStationInfo }    from "./stations";
import { toPortalConfig }    from "./portal";

let _loadSessionTask = undefined;

/**
 * 加载会话信息
 */
export async function loadSessionData(forceLoad = false) {

    if (!_loadSessionTask || forceLoad)
        _loadSessionTask = loadCommonData();

    await _loadSessionTask;

    async function loadCommonData() {

        let storeKey  = 'user.session-data.v1';
        let cacheData = await $util.indexDB.getItem(storeKey) as ICommonData;
        if (!cacheData) {
            cacheData = {} as ICommonData;
        }

        let ctxData = await loadContextData(cacheData?.Md5);

        let fields = Object.keys(ctxData);
        fields.forEach(field =>
            ctxData[field] = ctxData[field] || cacheData[field]);       //如果返回数据为空， 则使用缓存数据

        $ris.dataDic    = ctxData.DataDic;
        $ris.basicData  = ctxData.BasicData;
        $ris.appSetting = ctxData.AppSetting;

        await initUserContext(ctxData.Staff, ctxData.Portal);

        await $util.indexDB.setItem(storeKey, ctxData);
        //document.title = $ris.appSetting.SystemName;        //主界面刷新时，不会有登录界面展示，所以这儿也要更新标题
    }
}


export interface LoginSetting {
    SystemName: string;
    EnableCAIntegration: boolean;
    SystemLoginType:string
}
export async function getLoginSetting() {
    let result     = await axios.get('/api/SystemSetting/AppSetting/LoginSetting');
    let setting    = result.data as LoginSetting;
    document.title = setting.SystemName;        //登录时显示标题
    return setting;
}

/**
 * 初始化用户上下文
 */
async function initUserContext(staffData, portalData) {
    $ris.user             = staffData;
    //$ris.user.UserSetting = await getUserSetting();
    $ris.sessionData      = {} as any;

    //$ris.portal   = toPortalConfig(portalData, $ris.dataDic, $ris.basicData);
    //$ris.stations = getStationInfo();
}


async function loadContextData(md5: any) {
    let para = md5 || {};
    let rsp  = await axios.post('/api/System/ContextData', para);

    await loadOrderTemplate();
    return rsp.data as ICommonData;
}

interface ICommonData {
    DataDic?: any,
    BasicData?: any,
    AppSetting?: any,
    Staff?: any,
    Portal?: any,
    Md5: any,
}