/**
 * 搜索申请单
 * @param para
 */
import { OrderView } from '@scripts/types'

export async function searchOrders(para) {
    let result = await axios.post('/api/Order/Search', para);
    return result.data;
}

/**
 * 根据Id搜索申请单（单条）
 * @param orderId
 */
export async function searchById(orderId) {
    let result = await axios.get('/api/Order/Search/' + orderId);
    return result.data as OrderView;
}


export async function searchHisOrders(para) {
    let result = await axios.post('/api/Order/His/GetHisOrders', para);
    return result.data;
}

