import controls          from './controls'
import { onGlobalError } from './ris/error'
import { onBeforeRoute } from './ris/router'

import { util } from './util/util'
import { ris }  from './ris/ris'
import axios    from "axios";
import signalr from './util/signalR'

window.axios  = axios;
window.$axios = axios;
window.$ris   = ris
window.$util  = util;
window.$signalr = signalr.signal;


//作为vue的插件导入全局控件
export default {
    install: (app, options) => {

        //添加路由守卫
        $ris.router.beforeEach(onBeforeRoute);

        app.use($ris.router)
        app.config.productionTip          = false;
        app.config.globalProperties.$util = $util;
        app.config.globalProperties.$ris  = $ris;
        app.config.globalProperties.$signalr = signalr.signal;
        app.use(controls);
        app.config.errorHandler = onGlobalError;
    }
}

window.process = {
    env: {}          //antd 上传组件依赖 process.env 变量， 没有就报错
}
