<template>

  <a-modal title="添加留言" v-model:visible="data.visible" :width="500" @ok="onOK" @cancel="onCancel">
    <a-textarea :auto-size="{ minRows: 5 }" :maxlength="100" showCount style="margin-bottom: 12px"
                v-model:value="data.message"/>
  </a-modal>

</template>

<script setup lang="ts">

import { Defer }    from '@scripts/types'
import { reactive } from 'vue'

let data = reactive({
  message: undefined,
  visible: false,
});

defineExpose({ show });

let defer = new Defer<string>()

function onOK() {
  if (!data.message)
    $util.throwError("请输留言");

  data.visible = false;
  defer.resolve(data.message);
}

function onCancel() {
  data.visible = false;
  defer.resolve(undefined);
}

function show() {
  data.message = undefined;
  data.visible = true;

  defer = new Defer<string>();
  return defer.promise;
}
</script>

<style scoped>

</style>