<template>

  <div :key="data.offsetWeek">
    <div>
      <a-space>
        <slot name="device"/>
        <a-button @click="data.offsetWeek--">
          <ris-icon icon="LeftOutlined"/>
          上周
        </a-button>
        <a-button type="primary" @click="data.offsetWeek = 0">
          本周
        </a-button>
        <a-button type="primary" style="background: rgba(34,139,34,0.85)" @click="gotoToday">
          今天
        </a-button>
        <a-button @click="data.offsetWeek++">
          下周
          <ris-icon icon="RightOutlined"/>
        </a-button>
      </a-space>
    </div>
    <a-radio-group
        v-model:value="data.weekDay"
        button-style="solid"
        class="day-selector">
      <a-radio-button v-for="opt in dateOptions()" :key="opt.weekDay" :value="opt.value">
        {{ opt.label }}
        <div>{{ opt.day }}</div>
      </a-radio-button>
    </a-radio-group>
  </div>

</template>

<script setup lang="ts">

import { reactive, watch } from 'vue'

let emits = defineEmits(['onDateChanged'])

let data = reactive({
  offsetWeek: 0,
  weekDay:    $util.dayjs().isoWeekday(),      //1-7, where 1 is Monday and 7 is Sunday
});

watch(() => data, onDateChanged, { deep: true });

function gotoToday() {
  data.offsetWeek = 0;
  data.weekDay    = $util.dayjs().isoWeekday();
}

function getStartDay() {
  return $util.dayjs().startOf('week')
              .add(data.offsetWeek, 'weeks');
}

function onDateChanged() {
  let time      = getStartDay().add(data.weekDay - 1, 'days');
  let timeValue = time.format($util.format.day);
  emits('onDateChanged', timeValue);
}

function dateOptions() {
  let output = [];

  for (let i = 0; i <= 6; i++) {
    let date = getStartDay().add(i, 'days');
    output.push({
      label: date.format('dddd'),
      day:   date.format($util.format.day),
      value: date.isoWeekday(),
    });
  }

  return output;
}

</script>

<style scoped>
.day-selector {
  padding: 8px 0px;
  display: table;
  width: 100%;
}

.day-selector :deep(.ant-radio-button-wrapper) {
  height: auto;
  font-size: 16px;
  padding: 4px 0;
  display: table-cell;
}

.day-selector :deep(.ant-radio-button-wrapper span.ant-radio-button+span) {
  display: block;
  text-align: center;
  line-height: 150%;
}
</style>