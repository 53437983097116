import * as clientApi from '@api/main/client'
import { ref }        from 'vue'


export let isClientReady = ref(true);

/**
 * 打开pacs client
 * http://wiki.hinacom.com:322/pages/viewpage.action?pageId=193691780
 */
export async function openPacsClient(studyId = undefined, accNumber = undefined) {
    let pacsServer = $ris.appSetting.PacsServerAddress;
    let para       = `--start ${$ris.user.UserName}@${pacsServer}`;

    if (studyId) {
        para += ` -s ${studyId}`
    }
    if (accNumber) {
        para += ` -a ${accNumber}`;
    }
    para += ' -r 1 -o 001/';


    //console.log('openPacsClient..', para);
    await $axios.post('/Rest/Account/LoginFromRIS');
    await $util.delay(100);

    await executePacsCmd(para);
}

function encode4Pacs(str){
    if (str == null || str == undefined || (Object.prototype.toString.call(str)!=="[object String]")) {
        return "";
    }
    return str.replace(/[^a-z0-9-_.]/ig, function () {
        var cCode = (arguments[0].charAt(0).charCodeAt(0)).toString(16);
        var o = "";
        if (cCode <= 127) {
            o=cCode.length < 2 ? ("%0" + cCode) : "%" + cCode;
        } else {
            o = encodeURIComponent(arguments[0]);
        }
        return o;
    });
}

async function executePacsCmd(para:string, openWithCmd:boolean = true){
    let cmd = `xi-viewpro://num=${encode4Pacs(' '+para)}`;

    if (!isClientReady.value){
        //没有客户端情况下，使用浏览器打开
        document.location.replace(cmd);
        checkClientStatus().then();      //触发请求，不等待本次结果
        return;
    }

    try {
        //通过client发送， 发送失败改用浏览器
        await clientApi.invokePacsClient(cmd);
    }
    catch (err) {
        isClientReady.value = await clientApi.ping();

        //客户端是可用的情况下，直接抛异常 （可能是没装pacs客户端）
        if (isClientReady.value)
            throw err;
        else if(openWithCmd)
            document.location.replace(cmd);
        else
            console.log(`Ris client is not installed,command(${cmd}) will not be executed.`);
    }
}

/**
 * 关闭Study
*/
export async function closeStudy(accNumber = undefined, studyId = undefined){
    let para='';
    if (studyId) {
        para += ` -s ${studyId}`
    }

    if (accNumber) {
        para += ` -a ${accNumber}`;
    }

    if(para =='') return;
    para += ' -r 1 -o 001/';
    await executePacsCmd(`--CloseCurrentStudy ${para}`);
}

/**
 * 关闭PacsClient
*/
export async function closePacsClient(){
    await executePacsCmd('--quit', false);
}

// checkClientStatus().then();
// setInterval(checkClientStatus, 5 * 60 * 1000);


export async function checkClientStatus() {
    isClientReady.value = await clientApi.ping();
}

