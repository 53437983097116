<template>
  <a-modal title="下载" :footer="null"
           v-model:visible="actionOpt.showDownloadView">
    <div class="container" style="margin-top: -18px">
      <a-divider style="font-weight: bold" orientation="left">客户端下载</a-divider>
      <p>
        RIS客户端
        <a style="margin-left: 8px" :href="risClient.runtime">前置程序</a>
        <a :href="risClient.url">下载</a>
      </p>
      <p v-for="data in clients">
        {{ data.name }}
        <a :href="data.url" target="_blank">下载</a>
      </p>

      <a-divider style="font-weight: bold" orientation="left">手册下载</a-divider>
      <p v-for="data in manuals">
        {{ data.name }}
        <a :href="data.url" target="_blank">下载</a>
      </p>
    </div>
  </a-modal>
</template>

<script setup lang="ts">

import { actionOpt } from "../scripts/actions";


let risClient = {
  url:     getDownloadUrl('/RisClientSetup.exe'),
  runtime: getDownloadUrl('/RisClientEnv.exe'),
}

interface LinkData {
  name: string,
  url: string,
}

let clients: LinkData[] = [
  {
    name: 'PACS 32位客户端',
    url:  getPacsUrl('/MIISWeb/download/miPlatformViewerInstall.exe')
  },
  {
    name: 'PACS 64位客户端',
    url:  getPacsUrl('/MIISWeb/download/miPlatformViewerInstall_x64.exe')
  },
  {
    name: 'PACS DVD光盘刻录安装包',
    url:  getPacsUrl('/MIISWeb/download/DVDBurner.exe')
  },
];

let manuals: LinkData[] = [
  {
    name: 'RIS用户手册',
    url:  getDownloadUrl('/Documents/RIS用户手册.pdf')
  },
  {
    name: 'RIS管理员手册',
    url:  getDownloadUrl('/Documents/RIS管理员手册.pdf')
  },
  {
    name: 'RIS快速参考手册(院内)',
    url:  getDownloadUrl('/Documents/RIS快速参考手册(院内).pdf')
  },
  {
    name: 'RIS快速参考手册(区域)',
    url:  getDownloadUrl('/Documents/RIS快速参考手册(区域).pdf')
  },
  {
    name: 'RIS故障排查手册',
    url:  getDownloadUrl('/Documents/RIS故障排查手册.pdf')
  },
  {
    name: 'PACS安装指导手册',
    url:  getPacsUrl('/MIISWeb/document/ViewerInstallationandConfigurationManual_cn.pdf')
  },


];



function getDownloadUrl(name: string) {
  return '/Download' + name;
}

function getPacsUrl(url: string) {
  return 'http://' + $ris.appSetting.PacsServerAddress + url;
}

</script>

<style scoped>

.container > p {
  margin-left: 32px;
  margin-bottom: 18px;
  font-size: 11pt;
}

.container > p > a {
  margin-left: 4px;
}

</style>