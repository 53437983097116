<template>

  <a-form :labelCol="opt.labelStyle" :wrapperCol="opt.contentStyle">

    <a-row>
      <a-form-item label="患者姓名" v-bind="validateInfos.Name">
        <a-input v-model:value="data.Name"
                 @blur="nameToPinyin"/>
      </a-form-item>

      <a-form-item label="拼音名" v-bind="validateInfos.SpellName">
        <a-input v-model:value="data.SpellName"/>
      </a-form-item>

      <a-form-item label="性别" v-bind="validateInfos.Gender">
        <a-select v-model:value="data.Gender"
                  v-bind="$util.form.getOptionsEx('Gender')"/>
      </a-form-item>

      <a-form-item label="出生日期" v-bind="validateInfos.DateOfBirth">
        <a-date-picker style="width: 100%"
                       :valueFormat="$util.format.isoDate"
                       v-model:value="data.DateOfBirth"/>
      </a-form-item>

      <a-form-item label="身份证号">
        <a-input v-model:value="data.IDCard"/>
      </a-form-item>

      <a-form-item label="社保号">
        <a-input v-model:value="data.SocietyNumber"/>
      </a-form-item>

      <a-form-item label="联系电话">
        <a-input v-model:value="data.Telephone"/>
      </a-form-item>

      <a-form-item label="邮箱">
        <a-input v-model:value="data.Email"/>
      </a-form-item>
      
    </a-row>


    <a-form-item label="地址" :wrapperCol="opt.content2Style">
      <a-input v-model:value="data.Address"/>
    </a-form-item>

    <a-form-item label="备注" :wrapperCol="opt.content2Style">
      <a-input v-model:value="data.Note"/>
    </a-form-item>

  </a-form>

</template>

<script setup lang="ts">

import { watch, }      from 'vue';
import { PatientData } from "@src/pages/patient/main/scripts/common";

import * as util            from "@api/system/util";
import { PatientValidator } from "@src/pages/patient/share/editor/scripts/validator";

let props = defineProps<{
  data: PatientData
}>();

let validator     = new PatientValidator();
let validateInfos = validator.validateInfos as any;

validator.setData(props.data);
watch(() => props.data, data => validator.setData(props.data));

let opt = {
  labelStyle:    { style: `width: 90px` },
  contentStyle:  { style: `width: 190px` },
  content2Style: { style: `width: 480px` },
}

let api = {
  async validate() {
    await validator.validate();
  },

  clearValidate() {
    validator.clearValidate()
  },
};

export type PatientEditorApi = typeof api;
defineExpose(api);


async function nameToPinyin() {
  let patient = props.data;
  if (patient.Name) {
    patient.SpellName = await util.nameToPinyin(patient.Name)
  }
}


</script>

<style scoped>

</style>