<template>
  <a-modal v-model:visible="data.visible" :width="500"
           @ok="onSave" @cancel="data.visible=false">
    <a-space direction="vertical" style="width: 100%">
      <div style="font-weight: bold">{{ data.title }}：</div>
      <a-input style="width: 100%" v-model:value="data.value"/>

    </a-space>
  </a-modal>
</template>

<script setup lang="ts">

import { reactive } from 'vue'

defineExpose({
  show,
})

let data = reactive({
  trimed:        false,
  visible:     false,
  title:       '',
  emptyErrMsg: undefined as string,
  value:       undefined as string,
})

let _resolve = undefined;

function onSave() {
  if (!data.value || (data.trimed && !!!data.value.trim())){
    $util.throwError(data.emptyErrMsg);
  }

  _resolve(data.value.trim());
  data.visible = false;
}

function show(title: string, oldValue: string, emptyErrMsg: string, trimed: boolean = false) {
  data.title       = title;
  data.value       = oldValue;
  data.emptyErrMsg = emptyErrMsg;
  data.trimed      = trimed;

  data.visible     = true;

  return new Promise((resolve) => {
    _resolve = resolve;
  });
}


</script>

<style scoped>

</style>