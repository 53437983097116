/**
 * 获取用户所有可用子系统
 */
export async function getPortals() {
    ///api/System/User/GetPortals
    let rsp = await axios.get('/api/System/User/GetPortals');
    return rsp.data;
}

/**
 * 切换子系统
 * @param portalId
 */
export async function changePortal(portalId) {
    await axios.put('/api/System/User/ChangePortal', undefined, { params: { portalId } });
}


/**
 * 获取是否需要修改密码的提示信息
 */
export async function getChgPasswordInfo() {
    let rsp = await axios.get('/api/System/User/GetChgPwdInfo');
    return rsp.data as string;
}

/**
 * 不再提示修改密码
 */
export async function noUpdatePwdNotify() {
    let rsp = await axios.put('/api/System/User/NoUpdatePwdNotify');
    return rsp.data as string;
}