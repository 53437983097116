import MessageHost        from '@src/components/common/messageHost.vue';
import { ModalFuncProps } from 'ant-design-vue/lib/modal/Modal'
import { createVNode }    from "vue";

import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { Modal }                     from "ant-design-vue";


//modal 的封装， 用起来更方便些

export function confirm(message: string | string[], title = '提醒', isError = false,okText:string = undefined,cancelText:string = undefined): Promise<boolean> {
    let _resolve = undefined;

    //antd组件有bug， 第二次打开对话框的时候，可能会切换到英文， 这里强制写死
    let para: ModalFuncProps = {
        title,
        icon:    createVNode(ExclamationCircleOutlined),
        content: createVNode(MessageHost as any, { message }),
        okText:  okText,
        cancelText: cancelText,
        onOk() {
            _resolve(true);
        },
        onCancel() {
            _resolve(false);
        },
    };

    if (!isError)
        Modal.confirm(para);
    else
        Modal.error(para);

    return new Promise((resolve) => {
        _resolve = resolve;
    });
}


type iconType = 'info' | 'success' | 'error' | 'warn' | 'warning';

//消息提示框（只有一个确认按钮）， 支持多行文本
export function message(icon: iconType, message: string | string[], title = '提醒') {

    let modalFunc = Modal[icon] ?? Modal.info;       //eg: Modal.info, Modal.success
    return modalFunc({
        title,
        content: createVNode(MessageHost as any, { message }),
    });
}