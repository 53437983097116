<template>
  <a-modal title="查看报告" v-model:visible="para.visible" :width="900" :footer="null">
    <div :style="para.viewerStyle" class="root">
      <PdfReport :report-id="para.reportId" :archive-id="para.archiveId"/>
      <a-button @click="$ris.common.printReport(para.reportId)"
                type="link" class="toolbar">
        <ris-icon icon="PrinterOutlined"/>
        打印报告
      </a-button>
    </div>
  </a-modal>
</template>

<script setup lang="ts">

import { reactive, watch } from 'vue'
import PdfReport           from './pdfReport.vue'

let para = reactive({
  visible:     false,
  viewerStyle: {},
  reportId:    undefined as string,
  archiveId:   undefined as string,
  src:         undefined as any,
});

defineExpose({
  show,
})

async function show(reportId, archiveId) {
  await showDialog();
  para.reportId  = reportId;
  para.archiveId = archiveId;
}

watch(() => para.visible, isVisible => {
  if (!isVisible) {
    para.reportId = undefined;    //关闭窗口时重置，防止下次打开时不重新加载
  }
});


async function showDialog() {
  para.visible     = true;
  para.src         = undefined;
  para.viewerStyle = {
    height: (getHeight() - 200) + 'px',
  }
  await $util.delay(300);   //等待弹窗动画
}

function getHeight() {
  let height = document.documentElement.clientHeight;
  height     = Math.min(height, 1440);
  height     = Math.max(height, 800)
  return height - 100;
}
</script>

<style scoped>

.root {
  margin: -16px -16px;
  position: relative;
}

.toolbar {
  position: absolute;
  top: 3px;
  right: 4px;
}

</style>