import {
  UpdatePreRecordedDto,
  UpdateMobilizationDto,
  PriceListItem,
  IYardPresettingSearchPara,
  IRefundRecordSearchPara,
  IBillingCenterSearchPara,
  IScheduleTaskSearchPara,
} from "./../../types/consignment";
import { ConsignmentOrderView } from "@src/scripts/types/consignment";

/**
 * 基础数据返回类型
 */
export interface IBasicDataResult {
  Id: string;
  Name: string;
}
export interface IModelDataForOrder {
  ConsignmentTypeName: string;
  ShipEnglishName: string;
  ShipChineseName: string;
  VoyageNumber: string;
  BillLadingNo: string;
  BoxNo: string;
  PackingNo: string;
  BoxRuler: string;
  RefrigeratorType: string;
  Temperature: string;
  Humidity: string;
  IsVentilation: number;
  IsEmptyBox: number;
  DestinationPort: string;
  CustomsAreaName: string;
  CurrentIndex: number;
  CustomerInfoId: string;
  CustomerName: string;
  Contacts: string;
  UnloadingYardName: string;
  CreateId: string;
  ScheduleDate: string;
  ScheduleTimeSpan: string;
  IsEmptyOrder: boolean;
  DeparturePlace: string;
  DestinationPlace: string;
}
export interface ISplitBill {
  Index: number;
  BillNo: string;
}
export interface IBatchBill {
  Index: number;
  OrderId: string;
}

/**
 * 车辆信息返回类型
 */
export interface ICarInfo {
  Id: string;
  CarNo: string;
  Driver: string;
  DriverTelphone: string;
}

export interface IAppearInfo {
  Id: string;
  AppearCarNo: string;
  Driver: string;
  DriverTelphone: string;
  AbnormalExitReasonId: string;
  AbnormalExitReasonRemark: string;
}

/**
 * 获取关区
 * @returns
 */
export async function getAbnormalExitReasons() {
  let rsp = await axios.get("/api/Consignment/BasicData/GetAbnormalExitReason");
  return rsp.data as IBasicDataResult[];
}

/**
 * 获取异常出场原因
 * @returns
 */
export async function getCustomsAreas() {
  let rsp = await axios.get("/api/Consignment/BasicData/GetCustomsAreas");
  return rsp.data as IBasicDataResult[];
}

/**
 *
 * @returns 获取箱尺
 */
export async function getBoxRulers() {
  let rsp = await axios.get("/api/Consignment/BasicData/GetBoxRulers");
  return rsp.data as IBasicDataResult[];
}

/**
 * 获取委托类型
 * @returns
 */
export async function getConsignmentTypes() {
  let rsp = await axios.get("/api/Consignment/BasicData/GetConsignmentTypes");
  return rsp.data as IBasicDataResult[];
}

/**
 * 根据编码获取委托类型
 * @returns
 */
export async function getConsignmentTypeByCode(code) {
  let rsp = await axios.get(
    "/api/Consignment/BasicData/GetConsignmentTypeByCode/" + code
  );
  return rsp.data as IBasicDataResult;
}

/**
 * 获取落箱堆场
 * @returns
 */
export async function getUnloadingYards() {
  let rsp = await axios.get("/api/Consignment/BasicData/GetUnloadingYards");
  return rsp.data as IBasicDataResult[];
}

/**
 * 获取客户信息
 * @returns
 */
export async function getCustomerInfos() {
  let rsp = await axios.get("/api/Consignment/BasicData/GetCustomerInfos");
  return rsp.data as IBasicDataResult[];
}

export function getAboutPoingRecordsTotal()
{
  return axios.post(
    "/api/Consignment/Manage/getAboutPoingRecordsTotal"
  )
}
/**
 * 获取出发地和目的地
 * @returns
 */
export async function getDepartureDestinationPlaces() {
  let rsp = await axios.get(
    "/api/Consignment/BasicData/getDepartureDestinationPlaces"
  );
  return rsp.data as IBasicDataResult[];
}
/**
 * 获取中文船名信息
 * @returns
 */
export async function getChineseShipInfos() {
  let rsp = await axios.get("/api/Consignment/BasicData/GetChineseShipInfos");
  return rsp.data as IBasicDataResult[];
}

/**
 * 获取英文船名信息
 * @returns
 */
export async function getEnglishShipInfos() {
  let rsp = await axios.get("/api/Consignment/BasicData/GetEnglishShipInfos");
  return rsp.data as IBasicDataResult[];
}
/**
 * 获取直集英文船名信息
 * @returns
 */
export async function getZhijiEnglishShipInfos() {
  let rsp = await axios.get(
    "/api/Consignment/BasicData/GetZhijiEnglishShipInfos"
  );
  return rsp.data as IBasicDataResult[];
}
/**
 * 获取英文船名信息
 * @returns
 */
export async function GetCostTypes() {
  let rsp = await axios.get("/api/Consignment/BasicData/GetCostTypes");
  return rsp.data as IBasicDataResult[];
}
/**
 * 获取联系人信息
 * @returns
 */
export async function GetOriginators() {
  let rsp = await axios.get("/api/Consignment/BasicData/GetOriginators");
  return rsp.data as IBasicDataResult[];
}

/**
 * 获取录入人信息
 * @returns
 */
export async function GetContacts() {
  let rsp = await axios.get("/api/Consignment/BasicData/GetContacts");
  return rsp.data as IBasicDataResult[];
}

/**
 * 根据条件查询委托单
 * @param searchObj
 * @returns
 */
export async function getOrders(searchObj) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/SearchConsignmentOrder",
    searchObj
  );
  return rsp.data;
}

/**
 * 根据条件查询委托单
 * @param searchObj
 * @returns
 */
export async function getOrdersForJG(searchObj) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/SearchConsignmentOrderForJG",
    searchObj
  );
  return rsp.data;
}
/**
 * 根据条件查询委托单
 * @param searchObj
 * @returns
 */
export async function getOrdersForAboutPoint(searchObj) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/SearchConsignmentOrderForAPDetail",
    searchObj
  );
  return rsp.data;
}
/**
 * 根据条件查询委托单For Schedule Detail
 * @param searchObj
 * @returns
 */
export async function getOrdersForScheduleDetail(searchObj) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/SearchConsignmentOrderForScheduleDetail",
    searchObj
  );
  return rsp.data;
}
/**
 * 根据条件查询委托单
 * @param searchObj
 * @returns
 */
export async function getOrdersForReceivable(searchObj) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/GetOrdersForReceivable",
    searchObj
  );
  return rsp.data;
}
/**
 * 根据条件查询应收记录
 * @param searchObj
 * @returns
 */
export async function getChargs(searchObj) {
  let rsp = await axios.post("/api/Consignment/Manage/GetChargs", searchObj);
  return rsp.data;
}

/**
 * 根据条件查询应收未开票金额
 * @param searchObj
 * @returns
 */
export async function getChargsNotIsInvoiceFee(searchObj) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/GetChargsNotIsInvoiceFee",
    searchObj
  );
  return rsp.data;
}
/**
 * 根据条件查询约点信息
 * @param searchObj
 * @returns
 */
export async function getAboutPoingRecords(searchObj) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/getAboutPoingRecords",
    searchObj
  );
  return rsp.data;
}
/**
 * 根据条件查询委托单
 * @param searchObj
 * @returns
 */
export async function getOrdersForCondition(condition: string) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/SearchConsignmentOrderByCondition/" + condition
  );
  return rsp.data;
}

/**
 * 批量接收
 * @param orderIds
 */
export async function receivedOrders(orderIds, unloadingYardId) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/ReceivedOrders/" + unloadingYardId,
    orderIds
  );
  return rsp.data;
}

/**
 * 模板上传委托单
 * @param orderIds
 */
export async function UploadOrdersFromTemplate(TemplateData) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/UploadOrdersFromTemplate",
    TemplateData
  );
  return rsp.data;
}

/**
 * 模板上传码头信息
 * @param orderIds
 */
export async function UploadWharfFromTemplate(TemplateData) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/UploadWharfFromTemplate",
    TemplateData
  );
  return rsp.data;
}

/**
 * 批量删除委托单
 * @param orderIds
 */
export async function BatchDelOrders(orderIds) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/BatchDelOrders",
    orderIds
  );
  return rsp.data;
}

/**
 * 批量锁定委托单
 * @param orderIds
 */
export async function BatchChangeOrderLock(orderIds) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/BatchChangeOrderLock",
    orderIds
  );
  return rsp.data;
}

/**
 * 批量解锁委托单
 * @param orderIds
 */
export async function BatchChangeOrderUnLock(orderIds) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/BatchChangeOrderUnLock",
    orderIds
  );
  return rsp.data;
}

/**
 * 批量锁定委托单
 * @param orderIds
 */
export async function batchChangeOrderLockYundi(orderIds) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/BatchChangeOrderLockYundi",
    orderIds
  );
  return rsp.data;
}

/**
 * 批量解锁委托单
 * @param orderIds
 */
export async function batchChangeOrderUnLockYundi(orderIds) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/BatchChangeOrderUnLockYundi",
    orderIds
  );
  return rsp.data;
}

/**
 * 新增委托
 * @param orderIds
 */
export async function createConsignmentOrder(consignmentOrder) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/CreateConsignmentOrder",
    consignmentOrder
  );
  return rsp.data;
}

/**
 * 新增应收
 * @param orderIds
 */
export async function addOrderReceivable(consignmentOrder) {
  console.log(consignmentOrder);
  let rsp = await axios.post(
    "/api/Consignment/Manage/AddOrderReceivable",
    consignmentOrder
  );
  return rsp.data;
}

/**
 * 接收委托单
 * @param orderId
 */
export async function receivedOrder(orderId: string, unloadingYardId: string) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/ReceivedOrder/" + orderId + "/" + unloadingYardId
  );
  return rsp.data;
}

/**
 * 更改委托单锁定/解锁的状态
 * @param orderId
 */
export async function ChangeOrderStatus(orderId: string) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/ChangeOrderStatus/" + orderId
  );
  return rsp.data;
}
/**
 * 更改委托单发运抵/勿发运抵的状态
 * @param orderId
 */
export async function ChangeOrderArrivalStatus(orderId: string) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/ChangeOrderArrivalStatus/" + orderId
  );
  return rsp.data;
}

/**
 * 更新委托单
 * @param consignmentOrder
 * @returns
 */
export async function updateConsignmentOrder(consignmentOrder) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/UpdateConsignmentOrder",
    consignmentOrder
  );
  return rsp.data;
}
/**
 * 分票信息更新
 * @param consignmentOrder
 * @returns
 */
export async function spliteBills(consignmentOrder) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/spliteBills",
    consignmentOrder
  );
  return rsp.data;
}

/**
 * 合票信息更新
 * @param consignmentOrder
 * @returns
 */
export async function batchBills(consignmentOrder) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/BatchBills",
    consignmentOrder
  );
  return rsp.data;
}

/**
 * 批量设置计划号
 * @param consignmentOrder
 * @returns
 */
export async function batchSetScheduleNo(consignmentOrder) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/batchSetScheduleNo",
    consignmentOrder
  );
  return rsp.data;
}

/**
 * 批量设置计划号
 * @param consignmentOrder
 * @returns
 */
export async function batchSetScheduleNoForPoint(consignmentOrder) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/batchSetScheduleNoForPoint",
    consignmentOrder
  );
  return rsp.data;
}

/**
 * 删除委托单
 * @param orderId
 * @returns
 */
export async function deleteOrder(orderId: string) {
  let rsp = await axios.post("/api/Consignment/Manage/DeleteOrder/" + orderId);
  return rsp.data;
}

/**
 * 恢复委托单
 * @param orderId
 * @returns
 */
export async function recoveryOrder(orderId: string) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/RecoveryOrder/" + orderId
  );
  return rsp.data;
}

/**
 * 保存进场信息
 * @param mobilizationInfo
 * @returns
 */
export async function saveMobilizationInfo(mobilizationInfo) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/SaveMobilizationInfo",
    mobilizationInfo
  );
  return rsp.data;
}

/**
 * 获取车辆信息
 * @returns
 */
export async function getCarInfoByNo(carNo: string) {
  let rsp = await axios.get(
    "/api/Consignment/BasicData/GetCarInfoByNo/" + carNo
  );
  return rsp.data as ICarInfo;
}

/**
 * 保存出场信息
 * @param appearInfo
 * @returns
 */
export async function saveAppearInfo(appearInfo: IAppearInfo) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/SaveAppearInfo",
    appearInfo
  );
  return rsp.data;
}

/**
 * 获取车辆信息
 * @returns
 */
export async function getOrderInfoById(id: string) {
  let rsp = await axios.post("/api/Consignment/Manage/GetOrderInfoById/" + id);
  return rsp.data as ConsignmentOrderView;
}

/**
 * 更新实际场位
 * @param position
 */
export async function updatePosition(orderId: string, position: string) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/UpdatePosition/" + orderId + "/" + position
  );
  return rsp.data;
}

/**
 * 更新备注
 * @param remark
 */
export async function updateRemark(orderId: string, remark: string) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/UpdateRemark/" + orderId + "/" + remark
  );
  return rsp.data;
}

/**
 * 获取操作记录
 * @param orderId
 * @returns
 */
export async function getOprateHistory(orderId: string) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/GetOprateHistory/" + orderId
  );
  return rsp.data;
}

/**
 * 注册客户端用户
 * @param userInfo
 * @returns
 */
export async function registeClientUser(userInfo) {
  let rsp = await axios.post(
    "/api/SystemSetting/Staff/RegisteClientUser/",
    userInfo
  );
  return rsp.data;
}

/**
 * 更新预录状态
 * @param preRecordeds
 * @returns
 */
export async function updatePreRecorded(preRecordeds: UpdatePreRecordedDto[]) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/UpdatePreRecorded",
    preRecordeds
  );
  return rsp.data;
}

/**
 * 更新进场信息
 * @param mobilizations
 * @returns
 */
export async function updateMobilization(
  mobilizations: UpdateMobilizationDto[]
) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/UpdateMobilization",
    mobilizations
  );
  return rsp.data;
}

/**
 * 根据英文名查询船信息
 * @param shipName
 * @returns
 */
export async function searchShipInfo(shipName: string) {
  let rsp = await axios.post(
    "/api/Consignment/BasicData/GetShipInfosByEnglishName/" + shipName
  );
  return rsp.data;
}

/**
 * 更新转船信息
 * @param transShipInfo
 * @returns
 */
export async function updateTransshipment(transShipInfo) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/UpdateTransshipment",
    transShipInfo
  );
  return rsp.data;
}
/**
 * 保存自动运抵的任务
 * @param transShipInfo
 * @returns
 */
export async function SaveAutoYunDiTaskInfo(saveObj) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/SaveAutoYunDiTaskInfo",
    saveObj
  );
  return rsp.data;
}
/**
 * 获取委托单分页
 * @param searchObj
 */
export async function searchConsignmentOrderPage(searchObj) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/SearchConsignmentOrderPage",
    searchObj
  );
  return rsp.data;
}
/**
 * 获取默认查询的数量
 */
export async function getDefaultQueryNumber() {
  let rsp = await axios.post("/api/Consignment/Manage/GetDefaultQueryNumber");
  return rsp.data;
}

/**
 * 获取价目
 * @returns
 */
export async function getPriceList() {
  let rsp = await axios.get("api/Consignment/Manage/GetPriceList");
  return rsp.data;
}

/**
 * 获取客户价目
 * @returns
 */
export async function getCustomerPriceList(customerId) {
  let rsp = await axios.get(
    "api/Consignment/Manage/GetCustomerPriceList/" + customerId
  );
  return rsp.data;
}

/**
 * 保存价目信息
 * @param priceListInfo
 * @returns
 */
export async function savePriceListInfo(
  priceListInfo: PriceListItem[],
  isDeleteAll: boolean
) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/SavePriceListInfo/" + isDeleteAll,
    priceListInfo
  );
  return rsp.data;
}

/**
 * 保存客户价目信息
 * @param priceListInfo
 * @returns
 */
export async function saveCustomerPriceListInfo(
  priceListInfo: PriceListItem[],
  customerInfoId
) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/SaveCustomerPriceListInfo/" + customerInfoId,
    priceListInfo
  );
  return rsp.data;
}

/**
 * 返回公司账户信息
 * @returns
 */
export async function getCompanyAccountInfo() {
  let rsp = await axios.get("/api/Consignment/BasicData/GetCompanyAccount");
  return rsp.data;
}

/**
 * 保存缴费记录
 * @param paymentRecord
 * @returns
 */
export async function savePaymentRecord(paymentRecord) {
  let rsp = await axios.post(
    "/api/Consignment/Payment/SavePaymentRecord",
    paymentRecord
  );
  return rsp.data;
}

/**
 * 获取缴费记录
 * @param searchObj
 * @returns
 */
export async function searchPaymentRecordPage(searchObj) {
  let rsp = await axios.post(
    "/api/Consignment/Payment/SearchPaymentRecordPage",
    searchObj
  );
  return rsp.data;
}

/**
 * 获取缴费记录
 * @param searchObj
 * @returns
 */
export async function searchPaymentRecord(searchObj) {
  let rsp = await axios.post(
    "/api/Consignment/Payment/SearchPaymentRecord",
    searchObj
  );
  return rsp.data;
}

/**
 * 审核充值
 * @param id
 * @returns
 */
export async function approvePaymentInfo(id) {
  let rsp = await axios.post("/api/Consignment/Payment/ApprovePayment/" + id);
  return rsp.data;
}

/**
 * 删除充值
 * @param id
 * @returns
 */
export async function deletePaymentInfo(id) {
  let rsp = await axios.post("/api/Consignment/Payment/DeletePayment/" + id);
  return rsp.data;
}

/**
 * 查询预置场位数据
 * @param searchPara
 * @returns
 */
export async function searchYardPresettings(
  searchPara: IYardPresettingSearchPara
) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/SearchYardPresettingsPage",
    searchPara
  );
  return rsp.data;
}

/**
 * 获取预置场位页面的数量
 * @returns
 */
export async function getYardNumber() {
  let rsp = await axios.post("/api/Consignment/Manage/GetYardNumber");
  return rsp.data;
}

/**
 * 更新预置场位
 * @param position
 */
export async function updatePresetPosition(
  boxRuler: string,
  shipEnglishName: string,
  voyageNumber: string,
  position: string,
  unloadingYardId: string
) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/UpdatePresetPosition/" +
      boxRuler +
      "/" +
      shipEnglishName +
      "/" +
      voyageNumber +
      "/" +
      position +
      "/" +
      unloadingYardId
  );
  return rsp.data;
}

/**
 * 获取相同位置的委托单
 * @param position
 */
export async function getSamePresetPosition(
  boxRuler: string,
  position: string,
  unloadingYardId: string
) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/GetSamePresetPosition/" +
      boxRuler +
      "/" +
      position +
      "/" +
      unloadingYardId
  );
  return rsp.data;
}

/**
 * 获取相同位置的委托单
 * @param position
 */
export async function getSamePresetPositionByShipName(
  boxRuler: string,
  position: string,
  unloadingYardId: string,
  shipName: string
) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/getSamePresetPositionByShipName/" +
      boxRuler +
      "/" +
      position +
      "/" +
      unloadingYardId +
      "/" +
      shipName
  );
  return rsp.data;
}

/**
 * 批量预置场位
 * @param positionInfo
 * @returns
 */
export async function updatePresetPositions(positionInfo) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/UpdatePresetPositions",
    positionInfo
  );
  return rsp.data;
}

/**
 * 暂落预置场位
 * @param positionInfo
 * @returns
 */
export async function updateZanluoPresetPositions(position20, position40) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/UpdateZanluoPresetPositions/" +
      position20 +
      "/" +
      position40
  );
  return rsp.data;
}

/**
 * 获取暂落预置场位
 * @returns
 */
export async function getZanluoPresetPosition() {
  let rsp = await axios.post("/api/Consignment/Manage/GetZanluoPresetPosition");
  return rsp.data;
}

/**
 * 删除预置场位
 * @param orderId
 * @returns
 */
export async function remvePresetPosition(orderId, unloadingYardId) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/DeletePresetPosition/" +
      orderId +
      "/" +
      unloadingYardId
  );
  return rsp.data;
}

/**
 * 获取退款记录
 * @param searchObj
 * @returns
 */
export async function getRefundRecords(searchObj: IRefundRecordSearchPara) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/GetRefundRecordsPage",
    searchObj
  );
  return rsp.data;
}

/**
 * 添加退款记录
 * @param newRefund
 * @returns
 */
export async function addRefundRecord(newRefund) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/AddRefundRecord",
    newRefund
  );
  return rsp.data;
}

export async function updateRefunDatetime(id, refundDatetime) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/UpdateRefunDatetime/" + id + "/" + refundDatetime
  );
  return rsp.data;
}

export async function batchUpdateRefundDatetime(ids, refundDatetime) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/BatchUpdateRefundDatetime/" + refundDatetime,
    ids
  );
  return rsp.data;
}

export async function getBillsPage(searchObj: IBillingCenterSearchPara) {
  let rsp = await axios.post("/api/Consignment/Manage/getBillsPage", searchObj);
  return rsp.data;
}

export async function getBillCenterAmount(searchObj: IBillingCenterSearchPara) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/GetBillCenterAmount",
    searchObj
  );
  return rsp.data;
}

export async function getBills(searchObj: IBillingCenterSearchPara) {
  let rsp = await axios.post("/api/Consignment/Manage/GetBills", searchObj);
  return rsp.data;
}

export async function unlockStaff(staffId: string) {
  let rsp = await axios.post(
    "/api/Consignment/BasicData/UnlockStaff/" + staffId
  );
  return rsp.data;
}

/**
 * 保存转船申请
 * @param applicationInfo
 * @returns
 */
export async function saveTransshipmentApplication(applicationInfo) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/CreateTransshipmentApplication",
    applicationInfo
  );
  return rsp.data;
}

/**
 * 审核转船申请
 * @param remark
 */
export async function approveTransshipment(id: string) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/ApproveTransshipmentApplication/" + id
  );
  return rsp.data;
}

/**
 * 获取保函文件名称
 * @param billLadingNo
 * @returns
 */
export async function getGuarantee(billLadingNo) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/GetGuarantee/" + billLadingNo
  );
  return rsp.data;
}

/**
 * 获取二级客户
 */
export async function getSecondaryCustomers(customerId) {
  let rsp = await axios.post(
    "/api/Consignment/BasicData/GetSecondaryCustomers/" + customerId
  );
  return rsp.data;
}

/**
 * 删除晚集港
 * @param ids
 * @returns
 */
export async function removeLateGatheringPort(ids) {
  let rsp = await axios.post(
    "/api/Consignment/Manage/RemoveLateGatheringPort",
    ids
  );
  return rsp.data;
}

/**
 * 随行进场
 * @param ids
 * @returns
 */
export async function suiXingJinChang(data) {
  let rsp = await axios.post("/api/Consignment/Manage/SuiXingJin", data);
  return rsp.data;
}

/**
 * 随行出场
 * @param ids
 * @returns
 */
export async function suiXingChuChang(data) {
  let rsp = await axios.post("/api/Consignment/Manage/SuiXingChu", data);
  return rsp.data;
}

/**
 * 查询调度任务数据
 * @param searchPara
 * @returns
 */
export async function searchScheduleTasks(searchPara: IScheduleTaskSearchPara) {
  let rsp = await axios.post(
    "/api/FieldDiagram/FieldDiagram/SearchScheduleTasksPage",
    searchPara
  );
  return rsp.data;
}

/**
 * 批量修改申请单
 * @param ids
 * @returns
 */
export async function batchModifyOrders(data) {
  let rsp = await axios.post("/api/Consignment/Manage/BatchModifyOrders", data);
  return rsp.data;
}

/**
 * 保存调度任务
 * @param data
 * @returns
 */
export async function saveTaskInfo(data) {
  let rsp = await axios.post(
    "/api/FieldDiagram/FieldDiagram/SaveTaskInfo",
    data
  );
  return rsp.data;
}

/**
 * 获取时间段
 * @param data
 * @returns
 */
export async function getTimeSpan() {
  let rsp = await axios.post("/api/FieldDiagram/FieldDiagram/GetTimeSpan");
  return rsp.data;
}

/**
 * 获取有预约信息的船名
 * @returns
 */
export async function getScheduleShips() {
  let rsp = await axios.post("/api/FieldDiagram/FieldDiagram/GetScheduleShips");
  return rsp.data;
}

/**
 * 取消任务
 * @param id
 * @returns
 */
export async function cancelScheduleTask(id) {
  let rsp = await axios.post("/api/FieldDiagram/FieldDiagram/CancelTask/" + id);
  return rsp.data;
}

/**
 * 下发任务
 * @param id
 * @returns
 */
export async function distributeScheduleTask(id) {
  let rsp = await axios.post(
    "/api/FieldDiagram/FieldDiagram/DistributeTask/" + id
  );
  return rsp.data;
}

/**
 * 获取数量
 */
export async function getNumbers() {
  let rsp = await axios.post("/api/FieldDiagram/FieldDiagram/GetNumbers");
  return rsp.data;
}

/**
 * 获取用户信息
 * @param id
 * @returns
 */
export async function getStaffInfo(id) {
  let rsp = await axios.post("/api/SystemSetting/Staff/GetStaffInfo/" + id);
  return rsp.data;
}
