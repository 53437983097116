import 'devextreme/dist/css/dx.common.css';

import 'devextreme/dist/css/dx.light.css';
import '/src/style/dx/dx.custom.patch.css';



//国际化
import zhMessages               from './dx.zh.json'
import { locale, loadMessages } from "devextreme/localization";

loadMessages(zhMessages);
locale(navigator.language);

import * as dxGrid from 'devextreme-vue/data-grid';

import { DxLoadIndicator } from 'devextreme-vue/load-indicator';
import { DxScrollView }    from 'devextreme-vue/scroll-view';


export let DxControls = {
    install: (app, options) => {

        app.component('DxLoadIndicator', DxLoadIndicator);
        app.component('DxScrollView', DxScrollView);

        app.component('DxDataGrid', dxGrid.DxDataGrid);
        app.component('DxSelection', dxGrid.DxSelection);
        app.component('DxSearchPanel', dxGrid.DxSearchPanel);
        app.component('DxEditing', dxGrid.DxEditing);

    }
}