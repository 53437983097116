<template>
  <a-drawer
      :mask="true"
      :title="para.order?.Patient.Name"
      v-model:visible="para.visible"
      :width="750">
    <orderViewer
        :order="para.order"
        :allowAddComments="allowAddComments" style="margin-top: -16px"/>
  </a-drawer>
</template>

<script setup lang="ts">

//使用drawer展示申请单详细信息
import OrderViewer  from './orderViewer.vue'
import { computed, reactive } from 'vue'
import * as api from "@scripts/api/order/search"

let para = reactive({
  visible: false,
  order:   undefined as OrderView
});

let allowAddComments=computed(()=>{
  if(para.order && para.order.IsArchived){
    return false;
  }
  return true;
});

function show(order) {
  para.order   = order;
  para.visible = true
}

async function showByOrderId(orderId){
  para.order=await api.searchById(orderId);
  para.visible=true;
}
defineExpose({
  show,
  showByOrderId
})

</script>

<style scoped>

</style>