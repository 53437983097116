<template>
  <div class="layer-vertical menu-host">
    <div class="logo">
      <router-link to='/'><img src="/static/image/main/logo.png" style="width: 60px; height: 50px"/></router-link>
    </div>
    <div style="margin-right: -2px">
      <a-menu :selectedKeys="data.activeView" theme="dark"
              mode="vertical" class="inlineWithText">
        <a-menu-item v-for="node in data.noChildrenNodes" :key="node.route" @click="doAction(node)" @dblclick="dbDoAction(node)">
          <ris-icon :icon="node.icon" style="font-size: 18px; margin-right: 10px"/>
          <span>{{ node.shortLabel }}</span>
          <template #title>
            <ris-icon :icon="node.icon" style="font-size: 11pt"/>
            <span style="font-size: 10pt; margin-left: 4px"> {{ node.label }}</span>
          </template>
        </a-menu-item>
        <a-sub-menu v-for="node1 in data.childrenNodes" :key="node1.label" style="padding-right: 20px;">
          <template #title>
            <!-- <ris-icon :icon="node.icon" style="font-size: 18px; margin-right: 10px" /><br /> -->
            <a-image :src="node1.image" width="39px" height="43px" :preview="false" style="padding-bottom: 1px;"></a-image>
            <!-- <span style="margin-right: 10px"> {{ node1.shortLabel }}&nbsp;&nbsp;&nbsp;</span> -->
          </template>
          <a-menu-item v-for="subMenu in node1.subMenu" :key="subMenu.route" @click="doAction(subMenu)">
            <span style="font-size: 10pt; margin-left: 4px"> {{ subMenu.label }}</span>
          </a-menu-item>
        </a-sub-menu>
      </a-menu>
    </div>

    <div class="layer-content"></div>
    <div style="margin-right: -2px">
      <a-menu style="padding-bottom: -0px" theme="dark"
              :collapsed="true"
              mode="inline" class="inlineWithText">
        <a-sub-menu @click="changeNavBarVisible" v-if="globalView.showNavIcon.value">
          <template #title>
            <ris-icon :icon="globalView.showNavBar.value ? 'DoubleLeftOutlined' : 'DoubleRightOutlined'"
                      :title="globalView.showNavBar.value ? '隐藏导航栏' : '显示导航栏'"/>
          </template>
        </a-sub-menu>
      </a-menu>
    </div>


  </div>

</template>

<script setup lang="ts">

import * as actions        from '@src/pages/desktop/scripts/actions'
import { WorkNode }        from '@src/pages/desktop/scripts/actions'
import { reactive, watch } from 'vue'
import { useRoute }        from 'vue-router'

import { globalView } from '@scripts/ris/common'

let initPath = $ris.router.currentRoute.value.path;

let data = reactive({
  workNodes:   [] as WorkNode[],
  noChildrenNodes: [] as WorkNode[],
  childrenNodes: [] as WorkNode[],
  actionNodes: [] as WorkNode[],
  activeView:  [initPath],
  doAction,
})

const route = useRoute()
watch(() => route.path, (path) => {
  data.activeView = [path];
});

watch(() => actions.actionOpt.viewSession, updateItemNodes, { immediate: true })

let timer = null;
function doAction(node: WorkNode) {
  clearTimeout(timer);
  timer = setTimeout(() => {
    actions.invoke(node);
  }, 300);
}

function dbDoAction(node: WorkNode) {
  clearTimeout(timer);
  actions.dbInvoke(node);
}

function updateItemNodes() {
  data.workNodes   = actions.getWorkNodes(true);
  data.noChildrenNodes = data.workNodes.filter(m => !m.subMenu);
  data.childrenNodes = data.workNodes.filter(m => m.subMenu && m.subMenu.length > 0);
  data.actionNodes = actions.getActionNodes();
}

function changeNavBarVisible(e: PointerEvent) {
  e.stopPropagation();
  if (globalView.showNavBar) {
    globalView.showNavBar.value = !globalView.showNavBar.value;
  }
}

</script>

<style scoped>

.logo {
  text-align: center;
  margin: 12px 0;
}


/*.menu-host {*/
/*  background: white;*/
/*  border-right: 1px solid #dddddd;*/
/*  overflow-x: hidden*/
/*}*/

.menu-host {
  border-right: 1px solid #001529;
  overflow-x: hidden
}

/*#001529*/

.inlineWithText :deep(.ant-menu.ant-menu-inline-collapsed >.ant-menu-item) {
  height: auto;
  padding: 0px;
}

.inlineWithText.ant-menu-vertical :deep(.ant-menu-item) {
  padding: 0px;
  height: auto;
}

.inlineWithText :deep(.ant-menu-item span) {
  display: block;
}

.inlineWithText :deep(.ant-menu-item span.anticon) {
  margin: 8px 0 0 8px;
  line-height: 16px;
}

.inlineWithText :deep(.ant-menu-item span.anticon +span) {
  opacity: 1;
  margin: 2px 0 4px 0;
  display: block;
  line-height: 150%;
  text-align: center;
  font-size: 13px;
  padding-left: 4px;
  padding-right: 4px;
  white-space: break-spaces;
}
</style>