/**
 * 此结构用于实现附加信息的写入，附加信息只用于前端展示， 后台只负责存储，不解析，方便扩展
 * 利用extendObject 保存， 利用extendScheme生成界面
 *
 * 界面布局：
 * 目前没有复杂的布局信息设计， 采用的方式是：
 * 1. 前面fields字段统一使用文本框，
 * 2. 后面统一使用复选框
 */


export function getExtendScheme() {
    return {
        fields:  {
            Height:          { label: '身高' },
            Weight:          { label: '体重' },
            BloodSugarLevel: { label: '血糖水平' },
            BloodPressure:   { label: '血压' },
            HeartRate:       { label: '心率' },
            Temperature:     { label: '体温' },
            DrugAllergy:     { label: '药物过敏' },
            KidneyDisease:   { label: '肾脏疾病' },
        },
        options: {
            Diabetic:       { label: '糖尿病患者' },
            KidneyDialysis: { label: '做过肾透析' },
            Pregnancy:      { label: '怀孕' },
            PregnancyTest:  { label: '做过妊娠试验' },
            Breastfeeding:  { label: '母乳喂养' },
        }
    }
}