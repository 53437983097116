import * as print    from "@scripts/ris/common/print";
import * as search   from "@api/order/search";
import { OrderView } from '@scripts/types'

type OrderActionTypes = 'Checkin' | 'ReCheckin' | 'ReSchedule' | 'Modify' | 'Cancel' | 'Restore'

/**
 * 申请单常用操作
 */
export class OrderActions {

    order: OrderView;
    status: OrderStatus;

    constructor(order: OrderView) {
        this.order  = order;
        this.status = order.Status as OrderStatus;
    }


    canDoAction(action: OrderActionTypes) {
        let status = this.status;

        if (this.order.IsArchived)
            return false;

        if (!$ris.user.Actions['ModifyOrder'])
            return false;

        switch (action) {
            case 'Checkin':
                return status === 'Ordered'

            case 'ReCheckin':
                return status === 'Arrived'

            case 'ReSchedule':
            case 'Cancel':
                return (status === "Ordered" || status === "Arrived" || status === "Studying") ;

            case 'Modify':
                return status !== "Canceled";

            case "Restore":
                return status === 'Canceled';
        }
    }

    //检查是否可以打印
    canPrint(printType: PrintType) {
        return print.canPrintOrder(this.order, printType);
    }

    async print(printType: PrintType, count = 1) {
        await print.printOrder(this.order.Id, printType, count);
    }

    async reSchedule() {
        return await $ris.common.reSchedule(this.order);
    }

    /**
     * 刷新申请单
     */
    async refresh() {
        //此更新会直接更新order对象
        let newOrder = await search.searchById(this.order.Id);
        Object.assign(this.order, newOrder);
    }


    //是否有申请单
    hasReport() {
        let status = this.order.Status;
        return status === 'Reported';
    }


    //根据申请单状态判断是否允许修改检查项目 (已报告不允许修改，申请医生除外)
    canEditCheckItems() {

        //是否允许修改申请单
        if (!this.canDoAction('Modify')){
            return false;
        }

        let order = this.order;
        if (order.Order.ApplyDoctorId === $ris.user.Id) {
            return true;
        }

        let status = order.Status;
        if (status === undefined)       //创建申请单的时候没有状态
            return  true;

        if (status === 'Reported' && order.Order.IsMatch) {
            return false;           //未关联的时候可以编辑
        }

        return true;
    }

    //判断是否允许修改患者 (申请单详情页面修改患者)
    canEditPatient() {
        return this.order.Status !== 'Reported';
    }

    static async loadOrder(orderId: string) : Promise<OrderView> {
        return  await search.searchById(orderId);
    }
}


