<template>
  <div style="padding-top:8px;  width: 100%">
    <DxDataGrid v-bind="$util.grid.gridOption( {selectionMode: 'single', pagerVisible:false})"
                :height="gridHight"
                :focused-row-enabled="false"
                :dataSource="order.Order.Procedures"
                :columns="columns">
    </DxDataGrid>
  </div>
</template>

<script setup lang="ts">
import { computed }   from 'vue'
import { getColumns } from "./script/grid";

const props = defineProps<{
  order: OrderView,
}>();

let columns = getColumns(undefined, true);

let gridHight = computed(() => {
  return props.order.Order.Procedures?.length > 1 ? '100%' : '66px';
});


</script>

<style scoped>

</style>