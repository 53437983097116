<template>
  <a-modal v-model:visible="data.visible" title="更改患者" @ok="onOK" :width="900">

    <a-tabs v-model:activeKey="data.activeTab"
            style="margin-top: -12px">

      <a-tab-pane key="LinkPatient">
        <template #tab>
          <ris-icon icon="TeamOutlined"/>
          已有患者
        </template>
        <patientView :ref="view.patientList" :source-patient="data.patient"
                     style="height: 230px;" @onDblClick="onPatientDblClick"/>
      </a-tab-pane>

      <a-tab-pane key="NewPatient">
        <template #tab>
          <ris-icon icon="UserAddOutlined"/>
          新患者
        </template>
        <div style="height: 230px">
          <patientEditor :ref="view.patientEditor" :data="data.patient"/>
        </div>
      </a-tab-pane>

    </a-tabs>

  </a-modal>

</template>

<script setup lang="ts">

//将申请单中的患者链接到其它患者中心的患者，或从患者中心链接的患者分离

import { reactive, ref }    from "vue";
import PatientEditor        from "../editor/patientEditor.vue";
import PatientView          from "./widgets/patientView.vue";
import * as patientApi      from '@api/order/patient'
import { PatientInfoView }  from "@scripts/types";
import { PatientViewAPI }   from "./widgets/patientView.vue";
import { OrderActions }     from "@scripts/ris/actions/orderActions";
import { PatientEditorApi } from "@src/pages/patient/share/editor/patientEditor.vue";


let data = reactive({
  visible:   false,
  activeTab: 'LinkPatient',
  patient:   undefined as PatientInfoView
});


let view = {
  patientList:   ref<PatientViewAPI>(),
  patientEditor: ref<PatientEditorApi>(),
}


let _order: OrderView = undefined;

async function show(order: OrderView) {
  _order       = order;
  data.patient = $util.deepClone(order.Patient);
  view.patientEditor.value?.clearValidate();
  data.visible = true;

}

async function onOK() {
  if (data.activeTab === 'LinkPatient')
    await linkToPatient();
  else
    await saveAsNewPatient();
}

defineExpose({
  show,
});


async function onPatientDblClick(row) {
  let patient = row.data;
  let isConfirm = await $util.dialogs.confirm(`是否切换当前申请单的患者为 【${ patient.Name }】?`)
  if (!isConfirm)
    return;

  await linkToPatient();
}

/**
 * 链接到其它患者
 */
async function linkToPatient() {
  let patient = view.patientList.value.focusPatient();

  if (!patient)
    $util.throwError('未选中任何患者信息');

  let para = {
    OrderId:       _order.Id,
    PatientLinkId: patient.Id,
  };
  await patientApi.linkPatient(para);
  await new OrderActions(_order).refresh();
  data.visible = false;
}

/**
 * 作为新患者保存 （从已关联的患者中分离）
 */
async function saveAsNewPatient() {

  await view.patientEditor.value.validate();

  let para = {
    OrderId:      _order.Id,
    Patient:      data.patient,
    AsNewPatient: true,
  }
  await patientApi.updatePatient(para);
  Object.assign(_order.Patient, data.patient);
  data.visible = false;
}


</script>

<style scoped>

</style>