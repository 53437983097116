<template>


  <a-modal v-model:visible="ctx.visible" :title="ctx.title" :width="850" :footer="null">
    <div style="width: 800px;" v-if="ctx.visible">

      <a-alert v-if="!ctx.isClientReady" type="error" show-icon banner style="margin-bottom: 8px">
        <template #message>
          <a-space>
            <span>未检测到RIS客户端，无法扫描申请单。 </span>
            <a @click="checkClient">重新检测</a>
          </a-space>
        </template>
      </a-alert>

      <a-upload list-type="picture-card"
                v-model:file-list="ctx.fileList"
                :openFileDialogOnClick="false"
                :disabled="ctx.readOnly"
                @preview="onPreview"
                class="upload"
                @remove="onRemove">
        <template #itemRender="data">
          <div class="thumbnail">
            <img @click="data.actions.preview()" :src="data.file.url"/>
            <ris-icon v-if="!ctx.readOnly"
                      @click="data.actions.remove()" class="remove" icon="CloseSquareFilled"/>
          </div>
        </template>

        <div v-if="ctx.canScan" class="scanBtn" @click="onScan">
          <ris-icon class="icon" icon="CameraOutlined"/>
        </div>

      </a-upload>

      <div style="text-align: center; margin-bottom: 8px;">
        <a-image :width="800" :src="ctx.preview" style="object-fit: fill"/>
      </div>

    </div>
  </a-modal>


</template>

<script setup lang="ts">

import * as  client from '@api/main/client'
import * as api     from '@api/order/paperOrder'

import { reactive, watch } from 'vue'

interface IPaperOrderPara {
  orderId?: string,
  title?: string,
}

defineExpose({
  show,
});

let props = withDefaults(defineProps<IPaperOrderPara>(), {
  orderId: undefined,
  title:   '纸质单',
});

let ctx = reactive({
  visible:       false,
  title:         '纸质单',
  readOnly:      false,
  fileList:      [],
  maxCount:      10,
  isClientReady: true,
  preview:       undefined,
  canScan:       false,
});

watch([()=> ctx.readOnly, ()=>ctx.fileList.length], updateCanScan);
watch(() => ctx.visible, visible => registShortcut(visible));

let _orderId = undefined;

async function show(orderId, { readOnly = false, title = '纸质单' } = {}) {
  _orderId = orderId;

  let images        = await api.getAll(orderId);
  ctx.maxCount      = $ris.stations.order.setting.PaperOrderThreshold;
  ctx.fileList      = images.map(getFileNode);
  ctx.visible       = true;
  ctx.title         = title;
  ctx.readOnly      = readOnly;
  ctx.isClientReady = true;

  updateCanScan();
  checkClient().then();
  onPreview(ctx.fileList[0]);
}

function updateCanScan() {
  ctx.canScan = !ctx.readOnly && ctx.fileList.length < ctx.maxCount;
}

async function onScan() {
  if (!ctx.canScan){
    return;
  }

  let data    = await client.scanPaperOrder();
  let imgData = await api.add(_orderId, data);

  let fileNode = getFileNode(imgData);
  ctx.fileList.push(fileNode);
  onPreview(fileNode);
}

function onPreview(file) {
  ctx.preview = file ? file.url : undefined;
}

async function onRemove(file) {
  await api.remove(file.uid);
  return true;
}

function getFileNode(data) {
  return {
    uid:    data.Id,
    name:   'paperOrder.png',
    status: 'done',
    url:    data.PreviewUrl,
  }
}


async function checkClient() {
  if (ctx.readOnly)
    return;

  ctx.isClientReady = await client.ping();
}


function registShortcut(enable: boolean) {
  if (enable) {
    if (!ctx.readOnly) {
      document.addEventListener("keydown", onKeyEvent);   //不能用嵌套函数， 无法解注册
    }
  }
  else {
    document.removeEventListener("keydown", onKeyEvent);
  }
}

function onKeyEvent(e: KeyboardEvent) {
  if (e.key === ' ') {
    onScan();    //空格键进行快捷拍照
  }
}


</script>

<style lang="less" scoped>

.thumbnail {
  width: 100%;
  height: 100%;

  background: whitesmoke;
  border: whitesmoke solid 1px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }

  .remove {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 20px;
    margin: 2px;
    background: white;
    color: rgba(255, 0, 0, 0.7);
    cursor: pointer;

    &:hover {
      color: #FFF;
      background: #f37979;
    }
  }
}

.scanBtn {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .icon {
    width: 100%;
    font-size: 32px;
    vertical-align: center
  }
}


</style>