import { reactive, ref } from "vue";
import { Form }          from "ant-design-vue";
import { PatientData }   from "@src/pages/patient/main/scripts/common";


export class PatientValidator {

    useForm: ReturnType<typeof Form.useForm>
    data: PatientData;

    validateInfos = ref();


    setData(data: PatientData) {
        fixDataField(data);
        this.useForm             = Form.useForm(data, getRules());
        this.validateInfos.value = this.useForm.validateInfos;
    }

    clearValidate() {
        this.useForm.clearValidate();
    }

    async validate() {
        try {
            await this.useForm.validate();
        }
        catch (err) {
            $util.throwError('患者信息不完整，请检查输入')
        }
    }

}


function getRules() {
    let rules = reactive({
        Name:        required('姓名'),
        SpellName:   required('拼音名'),
        Gender:      required('性别'),
        DateOfBirth: required('出生日期'),
    });

    return rules;

    function required(label: string) {
        return [
            {
                required: true,
                message:  '请输入' + label,
            },
        ];
    }
}


function fixDataField(data) {
    if (Object.keys(data).length == 0) {
        Object.assign(data, {
            Name:        undefined,
            SpellName:   undefined,
            Gender:      undefined,
            DateOfBirth: undefined,
        })
    }
}
