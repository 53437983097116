<template>
  <PdfViewer :src="data.url" style="height: 100%"/>
</template>

<script setup lang="ts">

//通过pdf的方式展示报告， 支持通过orderId和reportId来检索
//若有pdf报告文件，则使用报告文件，弱没有报告文件，则实时生成pdf

import { reactive, watch } from "vue";
import { AsyncTaskKey }    from "@scripts/util/asyncLoader";

let props = defineProps<{
  reportId?: string,
  archiveId?: string,
}>();


let data = reactive({
  url: undefined,
});

//防止后发先至
let _reqMgr = new AsyncTaskKey();

watch(() => props.reportId, update, { immediate: true });


async function update() {

  data.url   = undefined;
  let reqKey = _reqMgr.generate();
  let url    = await getPreviewUrl();

  //数据源已经切换
  if (reqKey !== _reqMgr.current)
    return;

  data.url = url;
}

async function getPreviewUrl() {

  if (!props.reportId) {
    return undefined;
  }

  let version = $util.dayjs().format('HHmmss');

  //todo 后续要考虑支持多报告， 多报告的时候是一个数组
  return `/api/Report/Print/Content?reportId=${props.reportId}&archiveId=${props.archiveId}&version=${version}`;
}

</script>

<style scoped>

</style>