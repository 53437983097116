<template>
  <a-modal v-model:visible="data.visible" title="更改患者" @ok="onOk()" @cancel="onCancel()" :width="900">
    <patient-editor v-if="data.patient" :ref="view.editor" :data="data.patient"/>
  </a-modal>
</template>

<script setup lang="ts">


import { ref, reactive } from "vue";
import PatientEditor     from './patientEditor.vue'

import { PatientData }      from "../../main/scripts/common";
import { PatientEditorApi } from "./patientEditor.vue";

import * as api from '@api/patient/patient'

let props = defineProps<{
  isCreate: boolean,
}>();

let emit = defineEmits(['onAddPatient']);

let view = {
  editor: ref<PatientEditorApi>(),
}

let editorRef = ref<PatientEditorApi>();
let _patient  = undefined as PatientData;

let data = reactive({
  visible: false,
  patient: undefined as PatientData,
  title:   props.isCreate ? '创建患者' : '修改患者',
});

defineExpose({
  show,
})


function show(patient: PatientData) {
  _patient     = patient;
  data.patient = $util.deepClone(patient);

  data.visible = true;
}

async function onOk() {
  await view.editor.value.validate();

  if (props.isCreate) {
    await api.createPatient(data.patient);
    emit('onAddPatient');
  }
  else {
    await api.editPatient(data.patient);
    Object.assign(_patient, data.patient);
  }

  data.visible = false;
}

function onCancel() {
  data.visible = false;
  view.editor.value.clearValidate();
}


</script>

<style scoped>

</style>