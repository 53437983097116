
import { createVNode } from "vue";
import RisIcon         from "@src/components/common/RisIcon.vue";

export interface TreeNodeData {

    //节点key， 要求整个树上唯一
    key: string | number;

    //标题
    title?: string;

    //使用ris-icon动态创建图标，
    //需要将tree中的icon配置为函数， 配合该字段使用
    nodeIcon?: string,          //(icon字段被ui控件内置， 不能直接使用)

    //节点类型， 配合业务使用
    nodeType?: string,

    //自定义slot (映射为antd vue 的标准api)
    slots?: object,

    //子节点
    children?: this[];

    //是否是叶子节点
    isLeaf?: boolean,

    //是否被禁用
    disabled?: boolean,

    //是否可选中
    selectable?: boolean,

    para?: any,
}



//tree 的主要问题是选中的回调无法获取绑定的tag

/**
 * 根据key查询节点
 * @param nodes
 * @param key
 */
export function findTreeNode(nodes: TreeNodeData[], key: string | number)
    : TreeNodeData | undefined {

    //通过遍历的方式查询子节点
    for (let node of nodes) {

        if (node.key === key) {
            return node;
        }

        if (node.children) {
            let result = findTreeNode(node.children, key);
            if (result)
                return result;
        }
    }

    return undefined;
}


/**
 * 找到第一个叶子节点
 */
export function findFirstLeafNode(node: TreeNodeData) {

    if (!node || isLeafNode(node)) {
        return node;
    }

    for (const child of node.children) {
        let leafNode = findFirstLeafNode(child);
        if (leafNode)
            return leafNode;
    }

    return undefined;
}




export function getNodeIcon(e, defaultLeafIcon = 'FileTextOutlined') {

    if (!isLeafNode(e)) {
        return createFolderIcon(e.expanded);
    }

    let data = e.dataRef as TreeNodeData;
    if (data?.nodeIcon) {
        return createIcon(data.nodeIcon)
    }
    return createIcon(defaultLeafIcon);
}

function isLeafNode(node: TreeNodeData) {
    return !Boolean(node.children);
}


export function createIcon(icon) {
    let vNode = createVNode(RisIcon as any, { icon })
    return vNode;
}

export function createFolderIcon(isExpand) {
    let icon = isExpand ? 'FolderOpenOutlined' : 'FolderOutlined';
    return createIcon(icon);
}



let g_TreeNodeIdSeed = 1000;

/**
 * 用于自动生成唯一的节点Id
 */
export function createNodeId(prefix = undefined) {
    let nodeId = `${ g_TreeNodeIdSeed++ }`;

    if (prefix) {
        nodeId = prefix + "_"+nodeId;
    }
    return nodeId;
}

//https://2x.antdv.com/components/tree-cn#API
