import { reactive }        from "vue";
import { getOperationLog } from "@api/audit/opLog";

export class PatientEditLogContext {
    para = reactive({
        visible: false,
        source:  [],
    });

    patientId: string;
    private fieldsDict    = {
        Address:             '地址',
        ClinicalNumber:      '门诊号',
        InpatientNumber:     '住院号',
        SocietyNumber:       '社保号',
        Code:                '编码',
        DateOfBirth:         '出生日期',
        Email:               '邮箱',
        Gender:              '性别',
        GlobalPatientId:     '患者编号',
        IDCard:              '身份证号',
        BaseGlobalPatientId: "基准患者",
        Name:                '姓名',
        Note:                '备注',
        SpellName:           '拼音名',
        Telephone:           '联系电话',
        OperationMessage:    ''
    };
    private eventTypeDict = {
        EditPatient:      '修改',
        SplitPatient:     '患者拆分',
        MergePatient:     '患者合并',
        AutoMergePatient: '自动合并',
    };

    constructor() {
    }

    async doSearch() {
        let para    = this.para;
        para.source = [];

        if (!this.patientId) {
            $util.throwError('No patient Id set');
        }

        let source  = await getOperationLog(null, this.patientId, {
            isAscending:   false,
            operationType: 'PatientAudit'
        });
        para.source = this.adapt(source);
        console.log(para.source)
    }

    show(patientId) {
        this.patientId    = patientId;
        this.para.visible = true;
        this.doSearch();
    }

    private adapt(items): Array<any> {
        return items.map(v => {
            let old = v.Data.Old;
            let cur = v.Data.New;

            let row = {
                Id:        v.Id,
                EventType: this.eventTypeDict[v.EventType] || v.EventType,
                User:      v.StaffName,
                Time:      v.Time,
            };

            let changes = [];
            Object.keys(v.Data.Old).forEach(p => {
                if (this.fieldsDict.hasOwnProperty(p) && old[p] != cur[p]) {
                    changes.push({
                        field:     p,
                        fieldName: p == 'OperationMessage' ? '' : (this.fieldsDict[p] || p),
                        oldValue:  getDisplay(p, old[p]),
                        newValue:  getDisplay(p, cur[p]),
                    });
                }
            });

            row['Fields']   = changes.map(c => {
                return c.fieldName;
            }).join(' , ');
            row['OldValue'] = changes.map(c => {
                return !!c.fieldName ? `${c.fieldName}: ${c.oldValue}` : `${c.oldValue}`;
            }).join('\r\n');
            row['NewValue'] = changes.map(c => {
                return !!c.fieldName ? `${c.fieldName}: ${c.newValue}` : `${c.newValue}`;
            }).join('\r\n');
            return row;
        });
    }
}


function getDisplay(field, value) {
    if (!value)
        return '';

    if (field === 'DateOfBirth') {
        return $util.display.dayToStr(value);
    }

    return value;
}

export function getGridColumns() {
    return [
        { caption: '修改项目', dataField: 'Fields', width: 150 },
        { caption: '原始值', cellTemplate: 'oldValue', width: 180 },
        { caption: '当前值', cellTemplate: 'newValue', width: 180 },
        { caption: '操作类型', dataField: 'EventType', width: 100 },
        { caption: '操作用户', dataField: 'User', width: 100 },
        {
            caption:  '操作时间', dataField: 'Time', width: 180,
            dataType: 'date', format: $util.gridFormat.date,
        },
    ];
}