//用户注销
import { JSEncrypt }      from "jsencrypt";
import { clearSession }   from "@scripts/ris/auth/auth";
import { Lazy }           from "@scripts/types/lazy";


/**
 * 用户登录
 */
export async function login(userName: string, password: string){
    let para = {
        UserName: userName,
        Password: encryptPassword(password)
    }

    let reply = await axios.post('api/System/Login', para);     //登录
    return  reply.data as LoginResult;
}

/**
 * 证书登录
 * @param userName 
 * @param serverNodeId
 * @returns 
 */
export async function loginByCert(userName: string,serverNodeId: string, type: LoginType = 'User'){
    let reply = await axios.post('api/System/Login/LoginByCert', {
        UserName: encryptPassword(userName),
        ServerNode: serverNodeId,
        LoginType: type
    });
    return  reply.data as LoginResult;
}

export type LoginType = 'CA' | 'User' | 'CA_QRCode';

export interface LoginResult {
    //用户id， 为空时登录失败
    StaffId : string,

    //错误详情
    ErrorMessage: string,

    //下次可登录时需要等待时间
    WaitSeconds: number,
}


/**
 * 用户登出
 */
export async function logout() {

    //可能多个异常同时上报，导致此接口并发执行， 先设置标志位，防重入
    if ($ris.user.IsLogout) {
        return;
    }
    $ris.user.IsLogout = true;

    try {
        await clearSession();
    } catch (e) {
        console.error(e);
    }
    await $ris.router.push('/Login');
    window.location.reload();
}

/**
 * 通过rsa公钥加密密码，避免密码明文传输
 */
export function encryptPassword(password) {
    return _pwdEncrypt.value.encrypt(password);
}


//加密对象， lazy模式
let _pwdEncrypt = new Lazy(()=>  {
    let key     = 'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAPWkppjcemqum59WKpWAADIyOE5X8A278qd3edRhXi023YViwc1N5yP7xt4ableeS3J0GwZdttmdf6fCT0LDt6kCAwEAAQ==';       //加密密码用的公钥
    let encrypt = new JSEncrypt();          //https://github.com/travist/jsencrypt
    encrypt.setPublicKey(key);
    return encrypt;
});
