/**
 * 获取报告质控
 */
export async function getQualityControl(reportId) {
    let result = await axios.get('/api/Report/ReportQuality', { params: {reportId} });
    return result.data;
}

/**
 * 设置报告质控
 */
export async function setQualityControl(data) {
    await axios.put('/api/Report/ReportQuality', data);
}


/**
 * 获取病例追踪
 */
export async function getReportFollowUp(reportId) {
    let result = await axios.get('/api/Report/ReportFollowUp', { params: {reportId} });
    return result.data;
}


/**
 * 设置病例追踪
 */
export async function setReportFollowUp(data) {
    await axios.put('/api/Report/ReportFollowUp', data);
}


/**
 * 获取病例讨论
 */
export async function getCaseDiscussion(reportId) {
    let result = await axios.get('/api/Report/CaseDiscussion', { params: {reportId} });
    return result.data;
}

/**
 * 设置病例讨论
 */
export async function setCaseDiscussion(data) {
    await axios.put('/api/Report/CaseDiscussion', data);
}


/**
 * 获取ActionFlags
 */
export async function getActionFlags(reportId) {
    let result = await axios.get('/api/Report/ReportExFlags', { params: {reportId} });
    return result.data;
}

/**
 * 设置ActionFlags
 */
export async function setActionFlags(data) {
    await axios.put('/api/Report/ReportExFlags', data);
}