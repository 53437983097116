import { createRouter, createWebHashHistory } from 'vue-router'
import { isUserLogin }                        from "@scripts/ris/auth/auth";
import { loadSessionData }                    from "@scripts/ris/session";


const history = createWebHashHistory()

export const router = createRouter({
    history,
    routes: [
        {
            path:      '/',
            name:      '首页',
            component: () => import('@src/pages/desktop/workplace.vue')
        },
        {
            path:      '/login',
            component: () => import('@src/pages/desktop/login.vue')
        },
        {
            path:      '/order',
            name:      '预约登记',
            component: () => import('@src/pages/order/main/order.vue')
        },
        {
            path:      '/schedule-view',
            name:      '预约情况',
            component: () => import('@src/pages/order/main/scheduleView.vue')
        },
        {
            path:      '/perform',
            name:      '技师工作站',
            component: () => import('@src/pages/perform/main/performIndex.vue')
        },
        {
            path:      '/report',
            name:      '报告工作站',
            component: () => import('@src/pages/report/main/mainView.vue')
        },
        {
            path:      '/report-distribute',
            name:      '报告自动分配设置',
            component: () => import('@src/pages/reportDist/distributeIndex.vue')
        },
        {
            path:      '/report-pacs',
            name:      'PACS写报告',
            component: () => import('@src/pages/report/share/pacs/pacsEntry.vue')
        },
        {
            path:      '/us',
            name:      '超声工作站',
            component: () => import('@src/pages/report/main/mainView.vue')
        },
        {
            path:      '/es',
            name:      '内窥镜工作站',
            component: () => import('@src/pages/report/main/mainView.vue')
        },
        {
            path:      '/ecg',
            name:      '心电工作站',
            component: () => import('@src/pages/report/main/mainView.vue')
        },
        {
            path:      '/sc',
            name:      '病理工作站',
            component: () => import('@src/pages/report/main/mainView.vue')
        },
        {
            path:      '/clinical',
            name:      '临床医生工作站',
            component: () => import('@src/pages/report/main/mainView.vue')
        },

        {
            path:      '/order-makeup',
            name:      '申请单补录',
            component: () => import('@src/pages/report/main/views/makeup.vue')
        },
        {
            path:      '/audit',
            name:      '审计',
            component: () => import('@src/pages/audit/auditIndex.vue')
        },
        {
            path:      '/account',
            name:      '用户中心',
            component: () => import('@src/pages/setting/account/account.vue')
        },
        {
            path:      '/patient',
            name:      '患者中心',
            component: () => import('@src/pages/patient/main/patient.vue')
        },
        {
            path:      '/stat',
            name:      '数据统计',
            component: () => import('@src/pages/stat/main/statIndex.vue')
        },
        {
            path:      '/setting',
            name:      '系统管理',
            component: () => import('@src/pages/setting/main/mainView.vue')
        },
        {
            path:      '/integration/his',
            name:      'HIS集成',
            component: () => import('@src/pages/integration/his/main/index.vue')
        },
        {
            path:      '/integration/usesOrderRegist',
            name:      '第三方集成-申请单登记',
            component: () => import('@src/pages/integration/uses/orderRegist.vue')
        },
        {
            path:      '/integration/pacsEntry',        //第三方查看影像， 目前只支持超声
            name:      '影像查看',
            component: () => import('@src/pages/integration/pacsEntry.vue')
        },
        {
            path:      '/test',
            name:      '测试',
            component: () => import('@src/pages/desktop/test/test.vue')
        },
        {
            // 会匹配所有路径
            path:      '/:pathMatch(.*)*',
            component: () => import('@src/pages/desktop/404.vue')
        },
        {
            // 委托登记
            path: '/consignmentRegist',
            component: () => import('@src/pages/consignment/registList.vue')
        },
        {
            // 委托登记
            path: '/consignmentRegistEmpty',
            component: () => import('@src/pages/consignment/registListEmpty.vue')
        },
        {
            // 约点管理
            path: '/aboutpoint',
            component: () => import('@src/pages/consignment/aboutpoint.vue')
        },
        {
            // 委托管理
            path: '/consignmentManage',
            component: () => import('@src/pages/consignment/manageList.vue')
        }, 
        {
            // 委托管理
            path: '/consignmentManage_Empty',
            component: () => import('@src/pages/consignment/manageList_Empty.vue')
        }, 
        {
            // 场图显示
            path: '/FieldPattern',
            component: () => import('@src/pages/consignment/fieldpattern.vue')
        },
        {
            // 调度任务
            path: '/ScheduleTask',
            component: () => import('@src/pages/consignment/scheduleTask.vue')
        },
        {
            // 场图显示
            path: '/VehicleMounted',
            component: () => import('@src/pages/consignment/vehiclemounted.vue')
        },
        {
            // 委托管理
            path: '/consignmentProcess',
            component: () => import('@src/pages/consignment/processList.vue')
        },
        {
            // 委托管理
            path: '/consignmentProcess_Empty',
            component: () => import('@src/pages/consignment/processList_Empty.vue')
        },
        {
            // 应收查询
            path: '/consignmentReceivable',
            component: () => import('@src/pages/consignment/QueryReceivable.vue')
        },
        {
            // 退款查询
            path: '/refundRecord',
            component: () => import('@src/pages/consignment/refundRecord.vue')
        },
        {
            // 退款查询
            path: '/billingCenter',
            component: () => import('@src/pages/consignment/billingCenter.vue')
        },
        {
            // 堆场查询
            path: '/yardPresetting',
            component: () => import('@src/pages/consignment/YardPresetting.vue')
        }
    ]
})


/**
 * 路由守卫
 */
export async function onBeforeRoute(to, from, next) {

    //切换路由是需要关闭所有打开的对话框， 否则对话框会被带到下一个页面
    $util.modal.destroyAll();
    $util.message.destroy()

    if (to.path.toLowerCase() === '/login') {
        return next();
    }

    //没有会话信息需要重定向到登录页
    if (!isUserLogin()) {
        return next('/login');
    }

    try {
        //有会话信息需要保证加载完成会话数据
        await loadSessionData();
        return next();
    }
    catch (e) {
        console.error('load session data fail', e);
        return next('/login')
    }

}