export interface IOpLogData {
    EventType: string,
    SourceIndex: string,
    OperationType: string,
    StaffId: string,
    StaffName: string,
    Time: string,
}

/**
 * 获取操作日志
 */
export async function getOperationLog(
    eventType: string,
    sourceIndex: string,
    {
        operationType = undefined,
        staffId = undefined,
        startTime = undefined,
        endTime = undefined,
        isAscending = true,
        isBrief = false,
    } = {}) {

    let para = {
        eventType,
        sourceIndex,
        operationType,
        staffId,
        startTime,
        endTime,
        isAscending,
        isBrief,
    };

    let result = await axios.post('/api/Audits/GetOperationLog', para);
    let source = result.data;

    source.forEach(i => {
        i.Data    = JSON.parse(i.Content);
        i.Content = undefined;
    });

    return source as IOpLogData[];
}