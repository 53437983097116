<template>

  <div>
    <div v-for="line in getLines()">
      {{ line }}
    </div>
  </div>


</template>

<script setup lang="ts">

let props = defineProps<{
  message: string | string[]
}>();


function getLines() : string[] {
  let message = props.message;

  if (!message)
    return [];
  return (typeof message === 'string') ?  message.split('\n') : message;
}

</script>

<style scoped>

</style>