<template>
  <a-row type="flex">
    <a-col flex="auto">
      <a-input :placeholder="toolTip"
               allow-clear
               @pressEnter="doSearch"
               @change="onKeywordChg"
               v-model:value="data.value">
        <template #suffix>
          <a-tooltip :title="toolTip" v-if="!!toolTip">
            <ris-icon icon="InfoCircleOutlined" style="color: rgba(0, 0, 0, 0.45)"/>
          </a-tooltip>
        </template>
      </a-input>
    </a-col>
    <a-col v-if="showSearchBtn">
      <a-button type="primary" @click="doSearch" style="margin-left: 8px">
        <template #icon>
          <ris-icon :icon=" loading ? 'LoadingOutlined' : 'SearchOutlined'"/>
        </template>

        搜索
      </a-button>
    </a-col>

  </a-row>


</template>

<script setup lang="ts">

import { reactive, watch } from 'vue'

let props = defineProps({
  toolTip:       {
    default: '患者姓名，患者编号，HIS患者编号，HIS申请单号，检查号，住院号，门诊号，病案号，身份证号，社保号'
  },
  value:         {
    default: undefined,
  },
  loading:       {
    default: false,
  },
  showSearchBtn: {
    default: true,
  }
})

let data = reactive({
  value: ''
});

watch(()=>props.value,()=>{
  data.value=props.value;
});

data.value = props.value;

let emit = defineEmits(['onSearch', 'update:value']);

function doSearch() {
  emit('update:value', data.value?.trim());
  emit('onSearch');
}

function onKeywordChg(e) {
  emit('update:value', data.value);
  
  if (e.type === 'click' && data.value === '') {
    //点击了清除按钮
    doSearch();
  }
}
</script>

<style scoped>

</style>