import { UserSetting } from "@scripts/types";

/**
 * 获取用户设置
 */
export async function getUserSetting() {
    let result = await axios.get('/api/System/UserSetting');
    return result.data ? result.data : defaultSetting();
}

/**
 * 更新用户设置
 */
export async function updateUserSetting(data) {
    await axios.put('/api/System/UserSetting', data);
}

/**
 * 获取个人信息
 */
export async function getPersonalInfo() {
    let rsp = await axios.get('/api/System/UserSetting/PersonalInfo');
    return rsp.data;
}

/**
 * 设置头像
 */
export async function setAvatar(imgData) {
    await axios.post('/api/System/UserSetting/SetAvatar', imgData);
}

/**
 * 获取头像 (base64格式)
 */
export async function getAvatar() {
    let rsp = await axios.get('/api/System/UserSetting/GetAvator');
    return rsp.data as string;
}

/**
 * 获取个人信息
 */
export async function setPersonalInfo(data) {
    let rsp = await axios.put('/api/System/UserSetting/PersonalInfo', data);
    return rsp.data;
}

/**
 * 获取自定义表格布局
 */
export async function getGridLayout(gridName) {
    let rsp = await axios.get('/api/System/UserSetting/GridLayout', { params: { gridName } });
    return rsp.data;
}

/**
 * 获取自定义表格布局
 */
export async function setGridLayout(gridName, content) {
    await axios.put('/api/System/UserSetting/GridLayout', content, { params: { gridName } });
}

/**
 * 修改密码
 */
export async function changePassword(data) {
    await axios.put('/api/System/UserSetting/ChangePassword', data);
}

/**
 * 获取用户签名图片
 */
export async function getSignature(staffId) {
    let rsp = await axios.get('/api/SystemSetting/Staff/Signature', { params: { staffId } });
    return rsp.data;
}

export function defaultSetting() {
    let userSetting  = {
        Order:   {
            RememberDevice: true,
        },
        Perform: {
            TimeRangeType: 2,
        },
        Report:  {
            ContentInsertMode:         'insert',            //内容模板双击添加方式： insert ， override
            EditorDefaultFontSize:     15,
            EnableContentTemplateTree: true,                //树状结构显示报告模板
            AutoOpenImage:             undefined,           //自动打开影像
            AutoOpenOrder:             undefined,           //自动打开申请单
            SortByArriveTimeAsc:       false,               //报告列表按到检时间排序规则
            ReportWriterMode:          'full',              //写报告时报告内容显示模式。brief | full 简洁:不显示报告标题区域，完整:显示完整报告
        }
    } as UserSetting;
    return userSetting;
}

