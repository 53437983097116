import * as common  from './common'
import * as form    from './form'
import * as display from './display'
import * as dialogs from './dialog'
import * as client  from './client'

import grid         from './grid'

import dayjs        from 'dayjs'
import relativeTime from 'dayjs/plugin/isoWeek'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'

import indexDB from "./indexDB";

import { message, Modal, notification } from "ant-design-vue";

dayjs.extend(relativeTime);
dayjs.extend(quarterOfYear);

//ant modal 参考： https://2x.antdv.com/components/modal-cn#API
//ant message 参考：https://2x.antdv.com/components/message-cn
//ant notification 参考： https://2x.antdv.com/components/notification-cn

//配置message最大持续时间为1.5s
message.config({
    duration: 1.5,
});

let util = {
    message,
    notify:     notification,
    modal:      Modal,
    form:       form,
    grid,
    client,
    display,
    dayjs:      dayjs,
    indexDB,
    delay:      common.delay,
    deepClone:  common.deepClone,
    dialogs,
    throwError: common.throwError,                 //抛出异常， 此类异常可以被全局异常处理器处理
    format:     common.format,
    gridFormat: common.gridFormat,
    shipFormat: common.shipFormat,
    ScheduleDateFormat: common.ScheduleDate,
    distinct: common.distinct,
    checkNumberAndLetter: common.checkNumberAndLetter,

    //前端debug开关， 部分前端日志需要再控制台中开启此开关才输出。
    debugFlag: true,
};

export { util };