<template>

  <div style="background: white;  border: 1px solid rgb(235, 237, 240); ">
    <a-row type="flex">
      <a-col>
        <div style="font-weight: bold; margin-left: 16px; margin-bottom: 8px; height: 18px; margin-top: 8px">
          {{ patient ? title : ' ' }}
        </div>

      </a-col>
      <a-col flex="auto"></a-col>
      <a-col v-if="patient">
        <a-space style="margin-top: 8px; margin-right: 12px">
          <a v-if="!readOnly" @click="onEdit">修改</a>
          <a @click="onViewPatientLog">历史修改记录</a>
        </a-space>
      </a-col>
    </a-row>

    <a-descriptions bordered size="small" :column="2" style="margin: 0 -1px">

      <a-descriptions-item label="患者编号">
        {{ data.GlobalPatientId }}
      </a-descriptions-item>

      <a-descriptions-item label="社保号">
        {{ data.SocietyNumber }}
      </a-descriptions-item>

      <a-descriptions-item label="身份证号">
        {{ data.IDCard }}
      </a-descriptions-item>

      <a-descriptions-item label="联系电话">
        {{ data.Telephone }}
      </a-descriptions-item>

      <a-descriptions-item label="出生日期">
        {{ $util.dayjs(data.DateOfBirth).format($util.format.day) }}
      </a-descriptions-item>

      <a-descriptions-item label="邮箱">
        {{ data.Email }}
      </a-descriptions-item>
    </a-descriptions>

    <edit-patient :ref="view.editor" :is-create="false"/>
    <patient-edit-log :ref="view.patientLog"/>
  </div>


</template>

<script setup lang="ts">
import { ref, watch }  from "vue";
import { PatientData } from "../../main/scripts/common";

import EditPatient from '../../share/editor/editPatient.vue'
import PatientEditLog from '../widgets/patientEditLog.vue'

let props = defineProps<{
  patient: PatientData,
  readOnly?: boolean,
}>();

let title = ref<string>();
let data  = ref<PatientData>();

let view = {
  editor: ref(),
  patientLog:ref(),
}

watch(() => props.patient, update, { immediate: true });

function update(patient: PatientData) {
  title.value = '';
  data.value  = {} as PatientData;

  if (patient) {
    title.value = `${patient.Name} (${patient.GenderDisplay}) `;
    data.value  = patient;
  }
}

function onEdit() {
  view.editor.value.show(props.patient);
}

function onViewPatientLog() {
  view.patientLog.value.show(props.patient.Id);
}

</script>

<style lang="less" scoped>

.ant-descriptions ::v-deep(.ant-descriptions-item-label) {
  padding: 4px 16px; /* 调低label高度，减少空间占用 */
  width: 125px;
}

.ant-descriptions ::v-deep(.ant-descriptions-item-content) {
  padding: 4px 16px; /* 调低label高度，减少空间占用 */
  width: 180px;
}

</style>