<template>
  <a-modal v-model:visible="actionOpt.showLockScreen"
           title="屏幕锁定" @ok="doUnlock"
           :maskClosable="false" :closable="false"
           :width="380" :keyboard="false">

    <template #footer>
      <a-button v-if="loginType === 'CA'" @click="doLogout">
        返回登录
      </a-button>
      <a-button key="submit" type="primary" @click="doUnlock"
                :disabled="!enableUnLock">解锁
      </a-button>
    </template>

    <a-alert type="success" message="屏幕已锁定，您可以安全离开。"
             banner style="margin-bottom: 8px; margin-top: -8px"/>

    <a-form :label-col="{span:4}" :wrapper-col="{offset:1, span :19}"
            @keyup.enter.native="doUnlock">

      <a-form-item label="用户名">
        <a-input :value="para.UserName" :disabled="true" v-if="loginType === 'User'"/>
        <a-row :gutter="4" v-if="loginType === 'CA'">
          <a-col :span="18">
            <a-select
                :options="options.certs"
                :disabled="true"
                v-model:value="para.CACertificateId">
            </a-select>
          </a-col>
          <a-col :span="6">
            <a-button type="primary" @click="loadCurUser">
              重试
            </a-button>
          </a-col>
        </a-row>
      </a-form-item>

      <a-form-item label="密码 ">
        <a-input type="password" placeholder="密码" autocomplete="new-password"
                 v-model:value="para.Password"
                 :disabled="!enableUnLock"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">

import { actionOpt }                            from "@src/pages/desktop/scripts/actions";
import { computed, onMounted, reactive, watch } from "vue";
import { login, logout }                        from "@scripts/ris/auth/login";
import { StoreHelper }                          from "@scripts/util/StoreHelper";
import { LoginProxy }                           from '@src/scripts/ris/ca/caLogin';

let para = reactive({
  UserName:        $ris.user.UserName,
  Password:        undefined,
  CACertificateId: undefined,
  LoginType:       undefined,
});

let options = reactive({
  setting: {
    EnableCAIntegration: $ris.appSetting.EnableCAIntegration
  },
  certs:   [],
});

let loginHelper = new LoginProxy(options as any, para, true);

//从store里面读取初始状态，防止刷新页面后锁定状态无效
let stateStore           = new StoreHelper<boolean>('user.isLocked', sessionStorage);
actionOpt.showLockScreen = stateStore.getValue(false);
let loginType            = computed(() => {
  return loginHelper.curLoginType;
});

let enableUnLock = computed(() => {
  if (loginType.value == 'User') {
    return true;
  }
  return !!para.CACertificateId;
});

//同步锁定状态
watch(() => actionOpt.showLockScreen, value => {
  stateStore.setValue(value);
  loadCurUser();
});

onMounted(() => loadCurUser());

async function loadCurUser() {
  if (actionOpt.showLockScreen) {
    await loginHelper.loadAndBindCurUser();
  }
}

function doLogout() {
  logout();
  stateStore.setValue(false);
}

//解锁
async function doUnlock() {
  if (!para.Password) {
    $util.throwError('请输入密码');
  }

  let result = null;
  try {
    result = await loginHelper.login(() => {
      return login($ris.user.UserName, para.Password);
    }, false);
  } catch (e) {
    $util.throwError(e as any);
  }

  if (!result.StaffId) {
    $util.throwError(result.ErrorMessage);
  }

  para.Password            = undefined;
  actionOpt.showLockScreen = false;
}

</script>

<style scoped>

</style>