//在位获取末班申请单时候的初始值，防止没有初值时界面控件初始化出错
import * as api from '@api/order/register'


let _orderTemplate: OrderView = undefined;

export async function loadOrderTemplate() {
    if (!_orderTemplate)
        _orderTemplate = await api.newTemplate() as OrderView;
}


export function newOrder() {

    if (!_orderTemplate){
        $util.throwError('异常：初始化申请单模板未完成', {isTooltip: false});
    }

    let order = $util.deepClone(_orderTemplate);

    order.Visit.PatientType      = "OP";
    order.Visit.Critical         = "YIBAN";
    order.Visit.EmergencyDegree  = "0";
    order.Visit.DoctorAdviceType = "Common";
    order.Visit.PatientIdentity  = "YBRY";
    return order;
}