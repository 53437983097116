import { ProcedureEditor } from "./procedureEditor";

export function getColumns(actions : ProcedureEditor, readonly: boolean) {
    return [

        { caption: '检查项目', width: 180, dataField: 'CheckItemName', allowEditing: false },
        { caption: '检查费用(￥)', width: 90, dataField: 'CheckFee', },
        { caption: '材料费用(￥)', width: 90, dataField: 'MaterialFee' },
        { caption: '胶片数量', width: 80, dataField: 'FilmAmount' },
        { caption: '胶片规格', width: 80, dataField: 'FilmSpecName', allowEditing: false },
        { caption: '图片数量', width: 80, dataField: 'ImageAmount', visible:false },
        { caption: '曝光次数', width: 80, dataField: 'ExposalAmount', visible:false },
        { caption: '胶片费用(￥)',width: 90, dataField: 'FilmFee' },
        { caption: '检查时长',width: 80, dataField: 'TimeCount' },
        {
            width:   48,
            type:    'buttons', fixedPosition: "right",
            buttons: readonly ? [] : [{ icon: 'close', cssClass:"close-button", hint: '删除', onClick: e => actions.removeItem(e.row.data) },]
        },
    ]
}