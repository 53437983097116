import { OperationError } from '@scripts/types'
import { bufferToBase64 } from '@scripts/util/common'
import { AxiosError }     from 'axios'
import * as _axios        from 'axios'

const proxy = _axios.default.create({
    baseURL: 'http://127.0.0.1:9100',
    timeout: 5000
});


// Add a response interceptor
proxy.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
}, function (error: AxiosError) {

    if (error.isAxiosError && !error.response) {
        return Promise.reject(new OperationError('发送消息至客户端失败', true, false));
    }

    return Promise.reject(error);
});


export async function ping() {
    try {
        await proxy.get('/api/Ping');
        return true;
    } catch (err) {
        return false;
    }
}

/**
 * 扫描纸质申请单
 */
export async function scanPaperOrder() {

    let rsp = await proxy.get(
        '/api/Snap/TakeSnap',
        { responseType: 'arraybuffer' });

    return bufferToBase64(rsp.data);
}

/**
 * 打印
 * @param fileName
 * @param content
 * @param format
 * @param type
 * @param count
 * @returns {Promise<void>}
 */
export async function print(fileName, content, format, type, count) {
    let data = {
        fileName,
        content,
        format,
        type,
        count,
    };

    await proxy.post('/api/Print', data);
}


export async function invokePacsClient(cmd) {
    await proxy.get('/api/InvokePacsClient', { params: { cmd } });
}
