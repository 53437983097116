<template>

  <a-card :loading="false" class="comments" size="small" style="margin-top: 8px;">
    <template #title>
      <div style="font-weight:bolder;">留言区</div>
    </template>
    <template #extra>
      <a @click="add" v-if="!isReadOnly">添加留言</a>
    </template>
    <div :style="{
      maxHeight:(maxHeight || 160)+'px',
      minHeight:(minHeight || 60)+'px',
      overflowY: 'scroll',
      marginRight: '-12px',
    }">
      <a-config-provider>
        <template #renderEmpty>
          <div style="text-align:center;padding:8px 0;">
            暂无数据
          </div>
        </template>
        <a-list>
          <a-list-item v-for="c in data.source">
            <a-comment>
              <template #author>
                <div style="min-width: 80px;font-size:14px;">{{ c.Staff }}</div>
              </template>

              <template #datetime>
                <a-space>
                  <span style="font-size:14px;">{{ $util.display.timeToStr(c.RecordTime) }}</span>

                  <a-popconfirm title="确认删除此留言？"
                                v-if="!isReadOnly"
                                @confirm="()=>remove(c)">
                    <ris-icon icon="DeleteOutlined"/>
                  </a-popconfirm>

                </a-space>
              </template>

              <template #content>
                <div style="font-size:14px;padding:8px 0px;">
                  {{ c.Message }}
                </div>
              </template>
            </a-comment>
          </a-list-item>
        </a-list>
      </a-config-provider>
    </div>

    <AddComment ref="addComment"/>
  </a-card>

</template>

<script setup lang="ts">


import { reactive, watch, ref } from 'vue'
import * as commentApi          from '@api/order/comment'
import AddComment               from "./widgets/addComment.vue";

let props = withDefaults(defineProps<{
      orderId?: string,
      isReadOnly?: boolean,
      minHeight?: number,
      maxHeight?: number,
    }>(),
    { minHeight: 20, maxHeight: 180, isReadOnly: false });

let addComment = ref();

let data = reactive({
  source:     [],
});

watch(() => props.orderId, loadAll, { immediate: true });


async function loadAll() {
  let orderId = props.orderId;

  data.source = [];
  if (!orderId) {
    return;
  }

  await $util.delay(500);    //延迟加载，防止频繁切换
  if (orderId !== props.orderId)
    return;

  let source = await commentApi.getAll(orderId);

  if (orderId !== props.orderId)    //已经切换到其它申请单
    return;

  data.source = source.reverse();
}

async function add() {
  let message = await addComment.value.show();
  if (message) {
    let comment = await commentApi.add(props.orderId, message, $ris.user.Name);
    data.source.unshift(comment);
  }
}

async function remove(msg) {
  let id = msg.CommentId;
  await commentApi.remove(id);
  data.source = data.source.filter(c => c.CommentId !== id);
}
</script>

<style lang="scss" scoped>
.comments {
  :deep(.ant-comment-inner) {
    padding: 6px 0;
  }

  :deep(.ant-card-body) {
    padding: 0 18px;
  }

  :deep(.ant-list-empty-text) {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}


</style>