let _newIds = [];
export async function newId() {

    if (_newIds.length === 0) {
        let result = await axios.get('api/System/Util/BatchNewId');
        _newIds = result.data;
    }
    return _newIds.pop();
}

export async function nameToPinyin(name) {
    let result = await axios.get('api/System/Util/NameToPinyin', { params: { name } });
    return result.data;
}

export async function nameToMnemonicCode(name) {
    let result = await axios.get('api/System/Util/NameToMnemonicCode', { params: { name } });
    return result.data;
}
