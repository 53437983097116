import { router }           from "./router";
import { PortalConfigInfo } from "./portal";
import { CommonAction }     from "./common";
import { StationInfo }      from "./stations";
import * as type            from "@scripts/types";


let ris = {
    router,
    common:      new CommonAction(),
    dataDic:     {} as type.DataDic,
    basicData:   {} as type.BasicDataSource,
    user:        {} as UserInfo,
    sessionData: {} as any,   //记录一些会话相关的变量
    portal:      undefined as PortalConfigInfo,
    stations:    undefined as StationInfo,
    appSetting:  {} as any,
};

interface UserInfo {

    Id: string,
    UserName: string,
    Name: string,
    PerformDeviceIds: string,
    DefaultPortalId: string,
    DepartmentId: string,
    Actions: ActionDic,
    HasMultiPortal: boolean,
    HospitalCode: string,
    UserSetting: type.UserSetting,
    IsLogout: boolean,
    CustomerInfoId: string,
    IsProhibitCreateOrder: boolean
}

export { ris };


