import { OperationError } from "@scripts/types/err";


export const format = {
	date: 'YYYY-MM-DD HH:mm:ss',     //显示完整日期
	day: 'YYYY-MM-DD',              //显示天
	isoDate: 'YYYY-MM-DDTHH:mm:ss',    //主要用于时间空间绑定值，
};

export const gridFormat = {     //用于列表显示的格式， 和控件里面的 moment 格式不大一样
	date: 'yyyy/MM/dd HH:mm:ss',
	day: 'yyyy/MM/dd'
};

export const shipFormat = {     //用于列表显示的格式， 和控件里面的 moment 格式不大一样
	date: 'yyyy.MM.dd HH:mm',
	day: 'yyyy.MM.dd'
};
export const ScheduleDate = {     //用于列表显示的格式， 和控件里面的 moment 格式不大一样
	date: 'yyyy.MM.dd HH:mm',
	day: 'yyyy-MM-dd'
};
export function cloneToIgnoreCase(source:object,target:object){
	let mp={};
	Object.keys(source).forEach(p=> mp[p.toLocaleLowerCase()]=p);
	Object.keys(target).forEach(p=> {
		let k = p.toLocaleLowerCase();
		if(mp.hasOwnProperty(k)) {
			target[p] = source[mp[k]];
		}
	});
}

//深拷贝
export function deepClone<T>(obj: T) {
	if (!obj) {
		return obj;
	}
	return JSON.parse(JSON.stringify(obj)) as T;
}

//延迟
export function delay(ms : number) {
	if (ms < 10)
		ms = 10;

	return new Promise(r => setTimeout(r, ms));
}

export function distinct<T extends Record<K, string | number | boolean>,
    K extends keyof T>(arr: T[], ...keys: K[]): Pick<T, K>[] {
    const key = (obj: T) => JSON.stringify(keys.map(k => obj[k]));
    const val = (obj: T) => keys.reduce((a, k) => (a[k] = obj[k], a), {} as Pick<T, K>);
    const dict = arr.reduce((a, t) => (a[key(t)] = val(t), a), {} as { [k: string]: Pick<T, K> })
    return Object.values(dict);
}

//抛异常
export function throwError(message : string,
                           {
	                           isTooltip = true,               //显示为消息提示还是对话框
	                           isWarning = false               //显示为告警還是錯誤
                           } = {})
{
	//message为空的时候， 只中断流程，不提示消息（多用于确认对话框点取消后中断流程）
	throw new OperationError(message, isTooltip, isWarning)
}


//buffer转base64 string
export function bufferToBase64(buffer) : string {
	let binary = '';
	const bytes = new Uint8Array(buffer);
	const len = bytes.byteLength;
	for (let i = 0; i < len; i++) {
		binary += String.fromCharCode(bytes[i]);
	}
	return window.btoa(binary);
}

//触发浏览器下载
export function triggerDownload(src) {

	//https://segmentfault.com/q/1010000010493203

	// 创建隐藏的可下载链接
	let eleLink           = document.createElement('a');
	eleLink.download      = src;
	eleLink.style.display = 'none';


	eleLink.href = src;
	//触发点击
	document.body.appendChild(eleLink);
	eleLink.click();
	//然后移除
	document.body.removeChild(eleLink);
};

export function checkNumberAndLetter(checkValue) {
	return !/^[A-Za-z0-9]*$/g.test(checkValue)
}


declare global {
	interface Array<T> {

		//从数组中删除元素
		remove(val: T);

		//计算交集（长用于筛选数据）
		intersectWith<U>(keys: U[], keysSelector: (i: T)=> U) : T[];
	}
}

//从数组中删除元素
Array.prototype.remove = function (val) {
	let index = this.indexOf(val);
	if (index > -1) {
		this.splice(index, 1);
	}
}

//计算交集（长用于筛选数据）
Array.prototype.intersectWith = function (keys, keysSelector) {
	let set = new Set(keys);
	return this.filter(item => {
		let value = keysSelector(item);
		return set.has(value);
	});
}