import * as _axios from "axios";

const proxy = _axios.default.create({
  baseURL: "http://localhost:40302/ca/",
  timeout: 5000,
});

proxy.interceptors.response.use(function (response) {
  if (response.data) {
    if (response.data.hasOwnProperty("Succeeded")) {
      return response.data.Succeeded
        ? Promise.resolve(response.data.Data)
        : Promise.reject(
          response.data.Code == "UNEXPECTED"
            ? "未知错误"
            : response.data.Description ||
            response.data.Description ||
            "未知错误"
        );
    } else {
      return Promise.resolve(response.data);
    }
  }
  return Promise.resolve(null);
});

/**
 * 检测客户端
 * @returns true/false
 */
export async function ping() {
  return proxy.get<any, ClientServiceRslt>("ping");
}

/**
 * 获取支持的特性
 * @returns
 */
export function getFeatures() {
  return proxy.get<any, number>("supports");
}

/**
 * 获取客户端证书
 * @returns
 */
export function getCertificates() {
  return proxy.get<any, CACertInfo[]>("certs");
}

/**
 * 获取指定证书信息
 * @param certId 证书Id
 * @returns
 */
export async function getCertificateInfo(certId) {
  return await proxy.get<any, string>(`cert/${encodeURIComponent(certId)}`);
}

/**
 * 获取唯一项
 * @param certId 
 * @returns 
 */
export async function getCertUniqueId(certId) {
  let certs = await getCertificates();
  if (certs) {
    let cert = certs.find((c) => c.Id == certId);
    if (cert) {
      return cert.CertificateUserId;
    }
    throw '选择的证书未找到。USB Key 未插入或已断开。';
  }
  throw '获取证书失败.';
}

/**
 * 获取证书信息
 * @param certId 
 * @returns 
 */
export async function exportCertificate(certId: string) {
  return await proxy.get<any, string>(`export/${encodeURIComponent(certId)}`);
}

/**
 * 获取图片签名
 * @param certId 
 * @returns 
 */
export async function getSignatureBase64Image(certId: string) {
  return await proxy.get<any, string>(`pic/${encodeURIComponent(certId)}`);
}

/**
 * 使用CA证书登录
 * @param certId
 * @param password
 * @param logoutFirst
 * @returns
 */
export function login(
  certId: string,
  password: string,
  logoutFirst: boolean = true
) {
  return proxy.post<any, boolean>("login", {
    CertId: certId,
    Password: password,
    LogoutFirst: logoutFirst,
  });
}

export async function validateCertificate(certId: string) {
  return await proxy.get<any, CertValidRslt>(`validate/${encodeURIComponent(certId)}`);
}

/**
 * 创建签章
 * @param certId 
 * @param content 
 */
export async function createSignature(certId: string, originalData: string) {
  return await proxy.post<any, string>('sig', { CertId: certId, OriginalData: originalData });
}

/**
 * 验证签章
 * @param certId 
 * @param originalData 
 * @param signedData 
 */
export async function verifySignature(certId: string, originalData: string, signature: string) {
  let rslt = (await proxy.post<any, SignVerifyRslt>('verifysig', {
    Certificate: certId,
    OriginalData: originalData,
    Signature: signature
  }));

  console.log(rslt);

  return rslt.Succeed || false;
}

/**
 * 创建时间戳
 * @param orgData 
 */
export function createTimeStamp(orgData) {
  return proxy.post('ts', typeof (orgData) === 'string' ? JSON.stringify(orgData) : orgData, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

/**
 * 验证时间戳
 * @param timeStamp 
 * @param orgData 
 */
export async function verifyTimeStamp(timeStamp, orgData) {
  let rslt = await proxy.post<any, SignVerifyRslt>('verifyts', { OriginalData: orgData, TimeStamp: timeStamp });
  
  console.log(rslt);
  
  return rslt.Succeed || false;
}

/**
 * 检查证书是否可用
 * @param certId 
 * @returns 
 */
export async function isCertAvailable(certId: string) {
  let certs = await getCertificates();
  if (certs == null) certs = [];
  var filted = certs.filter(c => c.Id == certId);
  return (filted.length > 0);
}

/**
 * 获取登录状态
 * @param certId 
 * @returns 
 */
export async function isLoggedIn(certId: string) {
  return await proxy.get<any, boolean>("loggedin/" + encodeURIComponent(certId));
};

export interface CALoginRequest {
  CertId: string;
  CertUserId:string;
  Password: string;
  ServerNodeId: string;
}

export interface CAVerifyResult {
  UniqueId: string;
  UserCert: string;
  CertificateId: string;
}

/**
 * CA证书信息
 */
export interface CACertInfo {
  CertificateUserId: string
  Id: string,
  Name: string,
}

/**
 * Client Servcie 支持的特性
 */
export enum CAFeatures {
  GetValidationPeriods = 1,
  GetLoginAttempts = 2,
  GetSignaturePicture = 4,
  TimeStamp = 8
}

/**
 * 报告签章验证结果
 */
export interface SignVerifyRslt {
  Succeed: boolean,
  Message: string
}

/**
 * 证书验证结果
 */
export interface CertValidRslt {
  ErrorMessage: string,
  Status: number
}

/**
 * Client Service ping返回消息
 */
export interface ClientServiceRslt {
  Description: string,
  Name: string,
  ProductName: string,
  ProductVersion: string,
  Version: string
}