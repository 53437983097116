<template>
  <div class="disable-select-text"
       style="background: white;   border: 1px solid rgb(235, 237, 240);">
    <a-tabs v-model:active-key="data.activeTab"
            @tabClick="tabClick">

      <a-tab-pane key="order">
        <template #tab>
          <ris-icon icon="SolutionOutlined"/>
          申请单
        </template>
      </a-tab-pane>

      <a-tab-pane key="report" :disabled="!data.hasReport">
        <template #tab>
          <ris-icon icon="ReconciliationOutlined"/>
          诊断报告
        </template>
      </a-tab-pane>

      <a-tab-pane key="reportFollowUp" >
        <template #tab>
          <ris-icon icon="CodeOutlined"/>
          病例追踪
        </template>
      </a-tab-pane>

      <a-tab-pane key="film" :disabled="!data.hasFilm">
        <template #tab>
          <ris-icon icon="VideoCameraOutlined"/>
          影像
        </template>
      </a-tab-pane>

    </a-tabs>
  </div>
</template>

<script setup lang="ts">

import { watch, reactive, nextTick } from "vue";
import { DetailViewType }                         from "@src/pages/patient/main/scripts/common";

let props = defineProps<{
  order: OrderView,
  viewType: DetailViewType,
}>();

let emit = defineEmits(['update:viewType']);

let data = reactive({
  hasReport: false,
  hasFilm:   false,
  activeTab: props.viewType,
});

watch(() => props.order, update, { immediate: true })


async function update() {
  let order = props.order;

  if (!order) {
    data.hasReport = false;
    data.hasFilm   = false;
    data.activeTab = 'order';
    return;
  }

  data.hasReport = $ris.common.hasReport(order);

  //getReportFollowUpByOrderId
  data.hasFilm   = order.Order.IsMatch;

  if (!data.hasReport) {
    data.activeTab = 'order';
    emit('update:viewType', data.activeTab);
  }
  else
  {
    data.activeTab = 'report';
    emit('update:viewType', data.activeTab);
  }
}

async function tabClick(tabKey: DetailViewType) {

  if (tabKey === 'film') {
    //影像tab页不对应具体的页面，作为按钮使用
    let activeTab = data.activeTab;
    await nextTick();
    data.activeTab = activeTab;
    $ris.common.openImage(props.order);
  }
  else {
    emit('update:viewType', tabKey);
  }
}

</script>

<style scoped>

</style>