import { OrderView, ScheduleInfo } from '@scripts/types'

export class ScheduleUtil {
    order: OrderView;
    constructor(order) {
        this.order = order;
    }

    getScheduleInfo() {
        return ScheduleUtil.scheduleDetail(this.order.Schedule)
    }

    //设置申请单预约信息
    schedule(date, start, end) {
        this.order.Schedule = {
            Date:      date,
            StartTime: start,
            EndTime:   end,
        }
    }

    clear() {
        this.order.Schedule = undefined;
    }


    static scheduleDetail(schedule: ScheduleInfo)
    {
        if (!schedule?.Date)
            return undefined;

        let date = $util.dayjs(schedule.Date).format($util.format.day);
        return `${date} ${schedule.StartTime}  ~ ${date} ${schedule.EndTime}`;
    }
}