import { ReScheduleApi }    from '@src/pages/order/share/scheduleEditor/reSchedule.vue'
import * as print           from "./print";
import * as registerApi     from "@api/order/register";
import * as reportSearchApi from '@api/report/search'
import { ref, UnwrapRef }   from "vue";
import { OrderActions }     from "@scripts/ris/actions/orderActions";


export class CommonAction {

    promot(title: string, oldValue: string, emptyErrMsg: string = undefined, trimed: boolean = false)
        : Promise<string> {
        return globalView.promot.value.show(title, oldValue, emptyErrMsg, trimed);
    }

    //显示申请单
    viewOrder(order: OrderView | UnwrapRef<OrderView>) {
        globalView.orderDetail.value.show(order);
    }

    //通过Id显示申请单
    async viewOrderById(orderId: String) {
        await globalView.orderDetail.value.showByOrderId(orderId);
    }

    //显示纸质申请单
    viewPaperOrder(orderId: string, readOnly = true) {
        let title = '纸质单'
        globalView.paperOrder.value.show(orderId, { readOnly, title });
    }

    //显示HIS申请单
    viewHisOrder(orderId: string) {
        globalView.hisOrder.value.show(orderId);
    }

    //查看报告
    viewReport(reportId: string, archiveId: string = undefined) {
        globalView.reportViewer.value.show(reportId, archiveId);
    }

    //根据申请单Id查看报告
    async viewReportByOrder(orderId: string) {
        let reportId = await reportSearchApi.getReportIdByOrder(orderId);
        this.viewReport(reportId);
    }

    //打印申请单
    async printOrder(orderId: string, template: PrintType, count = 1) {
        await print.printOrder(orderId, template, count);
    }

    /**
     * 打印报告
     */
    printReport(reportId: string) {
        return print.printReport(reportId);
    }

    //根据申请单状态判断是否允许修改检查项目
    canEditCheckItems(order: OrderView) {
        return new OrderActions(order).canEditCheckItems();
    }


    /**
     * 更新检查项目  （主要用于单独更新检查项目的地方）
     */
    async updateProcedures(order) {
        //todo 目前是整个申请单更新的， 后续改成只更新检查项目
        await registerApi.update(order);
    }

    //判断是否允许修改患者 (申请单详情页面修改患者)
    canEditPatient(order: OrderView) {
        let status = order.Status;
        switch (status) {
            case 'Reporting':
            case 'Reported':
                return false;
            default:
                return true;
        }
    }

    /**
     * 是否有报告供展示或打印
     */
    hasReport(order: OrderView) {
        switch (order.Status) {
            case "Reported":
                return true;

            default:
                return false;
        }
    }

    openImage(order: OrderView) {
        let o       = order as any;
        let studyId = o.Order.StudyInstanceUID;

        $util.message.info('正在打开影像...');
        $util.client.openPacsClient(studyId, order.Order.AccessionNumber).then();
    }


    //重新预约
    async reSchedule(order: OrderView): Promise<any> {
        return await globalView.reSchedule.value.show(order);
    }

    //重新登录
    async showReLogin(){
        return await globalView.reLogin.value.show();
    }
}

export let globalView = {
    paperOrder:   ref(),
    orderDetail:  ref(),
    hisOrder:     ref(),
    reSchedule:   ref<ReScheduleApi>(),
    reportViewer: ref(),
    promot:       ref(),
    reLogin:      ref(),

    showNavIcon: ref(true),
    showNavBar:  ref(true),
}