import { Form }         from 'ant-design-vue';
import { SelectOption } from './select'

export const useForm = Form.useForm;

//保持和老接口的兼容

/**
 * 获取下拉框选项
 */
export function getOptions(source: string | BasicData[],
                           labelField: string                   = undefined,
                           filter: (item: BasicData) => Boolean = undefined,
                           addAllOption: boolean                = false,
) {
    let opt = new SelectOption(source, filter, labelField);
    if (addAllOption) {
        opt.options.unshift({ label: '全部', value: '', searchField: '全部qb' });
    }
    return opt;
}


/**
 * 获取下拉框选项
 */
export function getOptionsEx(
    source: BasicData[] | string,
    {
        labelField = undefined as string,
        multiSelect = false,
        filter = undefined,
        allowClear = false
    } = {}) {

    let opt = new SelectOption(source, filter, labelField);

    if (multiSelect) {
        opt.mode = 'multiple';
    }

    opt.allowClear = allowClear;
    return opt;
}

/**
 * 获取下拉项的第一个的值，常用于初始化
 */
export function getOptionValueAuto(source: any[] | any, current) {
    if (source.options)     //兼容两种类型的参数
        source = source.options;

    if (!(source instanceof Array)) {       //source不是数组类型
        throw Error('invalid para of source');
    }

    if (source.length === 0) {
        return undefined;
    }

    let currentOpt = source.find(i => i.value === current);
    if (currentOpt) {
        return current;
    }

    return source[0].value;
}


/**
 * 筛选设备
 * 筛选参数都为数组， undefined 表示不过滤， 空数组表示不过滤，数组中的 undefined， null 被忽略
 * @param {Array} modalities   设备类型（Code的数组）
 * @param {Array} execDepartments  检查科室（Id的数组）
 */
export function filterDevices(modalities = undefined, execDepartments = undefined) {

    let devices = $ris.portal.devices;

    let modalitySet = buildSet(modalities);
    if (modalitySet.size > 0) {
        devices = devices.filter(d => modalitySet.has(d.ModalityCode))
    }

    let departmentSet = buildSet(execDepartments);
    if (departmentSet.size > 0) {
        devices = devices.filter(d => departmentSet.has(d.DepartmentId))
    }

    return devices;

    function buildSet(source) {
        if (!source)
            source = [];

        //筛掉无效值， null， undefined等
        source = source.filter(i => i);
        return new Set(source);
    }
}

/**
 * 根据申请单筛选设备
 * @param order
 * @returns {[]}
 */
export function filterDevicesByOrder(order: OrderInfoView) {
    return filterDevices([order.ModalityCode], [order.ExecDepartmentId])
}