import { OrderView } from '@scripts/types'

let _templateData = null;

export async function newTemplate() {

    if (_templateData === null) {
        _templateData = (await axios.get('/api/Order/NewTemplate')).data;
    }
    return $util.deepClone(_templateData);
}

/**
 * 创建申请单
 */
export async function register(order, splitSave: boolean) {
    let rsp = await axios.post('/api/Order/Register', order, { params: { splitSave } });
    return rsp.data as IOrderRegistResult[];
}

//申请单登记后的返回值
export interface IOrderRegistResult {
    Id: string,
    AccessionNumber: string,
}

/**
 * 创建申请单
 */
export async function update(order) {
    await axios.put('/api/Order/Update', order);
}


/**
 * 重新预约
 */
export async function reSchedule(orderId, info) {
    await axios.put('/api/Order/ReSchedule/' + orderId, info);
}

/**
 * 到检
 */
export async function checkin(orderId) {
    await axios.put('/api/Order/Checkin', null, { params: { orderId } })
}

/**
 * 批量到检
 */
export async function batchCheckin(orderIds) {
    await axios.put('/api/Order/BatchCheckin', orderIds)
}

/**
 * 重新到检
 */
export async function reCheckin(orderId) {
    await axios.put('/api/Order/ReCheckin', null, { params: { orderId } })
}

/**
 * 取消申请单
 */
export async function cancel(orderId) {
    await axios.put('/api/Order/Cancel', null, { params: { orderId } });
}

/**
 * 恢复申请单
 */
export async function restore(orderId) {
    await axios.put('/api/Order/Restore', null, { params: { orderId } })
}

