
export class OperationError extends Error {            //此类异常会提示给用户

    isTooltip: boolean;
    isWarning: boolean;

    constructor(message, isTooltip, isWarning) {
        super(message);
        this.isTooltip = isTooltip;
        this.isWarning = isWarning;
    }

    process() {

        let message = this.message;
        if (!message) {
            //只是中断业务，不提示任何消息 （多用于确认对话框点取消后中断流程）
            return;
        }

        if (this.isTooltip) {
            if (this.isWarning)
                $util.message.warn(message);
            else
                $util.message.error(message);
        }
        else {
            if (this.isWarning)
                $util.modal.warn({ title: '提示', content: message });
            else
                $util.modal.error({ title: '错误', content: message});
        }
    }
}
