/**
 * 获取设备预约详情
 */

export async function getScheduleDetailByDevice(deviceId, startDate, days = 7) {
    let params = { deviceId, startDate, days };
    let result = await axios.get('/api/Order/ScheduleDetailByDevice', { params });
    return result.data as IPeroidData[];
}


export interface IPeroidData {
    IsOver: boolean;
    IsExpired: boolean;
    UsingCount: number;
    MaxCount: number;
    UsingTime?: string;
    RemainTime?: string;
    MaxTime?: string;
    Date: string;
    StartTime: string;
    EndTime: string;
}