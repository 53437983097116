

export const searchOpt = {
    keywordPlaceholder: '姓名、患者编号、住院号、身份证号、手机号',
}

export function getPatientColumns() {
    return [
        {
            dataField:    'GlobalPatientId', caption: '患者编号',
            width:        150, 
        },
        { dataField: 'Name', caption: '姓名', width: 140 },
        { dataField: 'GenderDisplay', caption: '性别', width: 80, },
        { dataField: 'AgeDisplay', caption: '年龄', width: 80, },
        { dataField: 'IDCard', caption: '身份证号', width: 160, },
        { dataField: 'SocietyNumber', caption: '社保号', width: 160, },
        {
            dataField: 'DateOfBirth', caption: '出生日期', width: 100,
            dataType:  'date', format: $util.gridFormat.day
        },
        { dataField: 'Telephone', caption: '联系电话', width: 120, },
        { dataField: 'InpatientNumber', caption: '住院号', width: 120, },
        { dataField: 'ClinicalNumber', caption: '门诊号', width: 120, },
        { dataField: 'Address', caption: '地址', width: 160, },
        { dataField: 'Note', caption: '备注', width: 160, },
        {
            caption:  '最后更新时间', dataField: 'LastUpdateTime', width: 160,
            dataType: 'date', format: $util.gridFormat.date
        },
    ];
}