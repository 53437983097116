<template>
  <slot></slot>
</template>

<script setup lang="ts">

import { onMounted, onUnmounted } from 'vue'
import { DragSplitHelper }        from '../../scripts/controls/extend/dragSplit'

interface Props {
  thumbId?: string
  viewId?: string,
  viewPart?: string,
  storeKey?: string,
}

const props = withDefaults(defineProps<Props>(), {
  viewPart: 'right',
})

let dragHelper = new DragSplitHelper(props.thumbId, props.viewId);


onMounted(() => {
  dragHelper.enable({
        changeLeftPart: props.viewPart !== 'right',
        storeKey:       props.storeKey,
      }
  );
});

onUnmounted(() => {
  dragHelper.clear();
})

</script>

<style scoped>

</style>