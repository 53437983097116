<template>
  <a-modal 
    v-model:visible="para.visible" 
    title="历史修改记录"
    :centered="true"
    :width="900" 
    :footer="null">

    <DxDataGrid style="height: 280px;width:100%;"
                v-bind="$util.grid.gridOption({selectionMode: 'single', pagerVisible: false})"
                :focused-row-enabled="false"
                :dataSource="para.source"
                :columns="columns">

      <template #oldValue="{ data }">
        <div v-html="(data.data.OldValue == null ? '': data.data.OldValue.replace(/\r\n/ig,'<br/>'))" class="lh150"/>
      </template>

      <template #newValue="{ data }">
        <div v-html="(data.data.NewValue == null ? '': data.data.NewValue.replace(/\r\n/ig,'<br/>'))" class="lh150"/>
      </template>
    </DxDataGrid>
  </a-modal>
</template>

<script setup lang="ts">

import { PatientEditLogContext, getGridColumns } from "../scripts/patientEditlog";

let columns = getGridColumns();
let ctx     = new PatientEditLogContext();
let para    = ctx.para;

defineExpose({
  show(patientId: string) {
    ctx.show(patientId);
  }
})

</script>

<style scoped>
.lh150{
  line-height: 150%;;
}
</style>