<template>
  <a-modal title="切换子系统" :footer="null"
           v-model:visible="actionOpt.showSwitchPortal">
    <a-card v-for="portal in portals" size="small"
            style="margin-bottom: 12px; background: whitesmoke;">
      <a class="portal-item" @click="()=>switchPortal(portal)"
         style="font-size: 15px">
        {{ portal.Name }}
      </a>
    </a-card>
  </a-modal>
</template>

<script setup lang="ts">

import { actionOpt }       from "../scripts/actions";
import * as userApi        from "@api/system/user"
import { ref }             from 'vue'
import { loadSessionData } from '@scripts/ris/session'

let portals = ref([]);
userApi.getPortals().then(value => portals.value = value);

async function switchPortal(portal) {
  if ($ris.portal.id === portal.Id) {
    actionOpt.showSwitchPortal = false;
    return;
  }

  await userApi.changePortal(portal.Id);
  await loadSessionData(true);

  $util.message.success('切换子系统成功');
  actionOpt.showSwitchPortal = false;

  await $ris.router.push('/');
  actionOpt.refresh();
}


</script>

<style scoped>

.portal-item {

}

</style>