export class Defer<T = any> {

    resolve: (value: T) => void;
    reject: (value?: any) => void;
    promise: Promise<T>;

    constructor() {
        this.promise = new Promise<T>((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        })
    }
}