export class DragSplitHelper {

    thumbId: string;
    layerId: string;

    constructor(thumbId: string, layerId: string) {
        this.thumbId = thumbId;
        this.layerId = layerId;
    }

    //启用拖拽
    enable(exOpt: IDragExOpt) {

        if (exOpt.changeLeftPart === undefined) {
            exOpt.changeLeftPart = false;
        }

        enableDragSplit(this.thumbId, this.layerId, exOpt);
    }

    //控件释放时，用以清空相应事件
    clear() {
        clearDragSplit(this.thumbId);
    }
}

interface IDragExOpt {
    changeLeftPart?: boolean,
    minWidth?: number,
    storeKey?: string,
    parentSelector?: (thumb: HTMLElement) => HTMLElement,
}

function enableDragSplit(thumbId: string, layerId: string, exOpt: IDragExOpt) {

    let thumb          = document.getElementById(thumbId);
    thumb.style.cursor = 'col-resize';

    let minWidth = exOpt.minWidth || 200;

    loadInitWidth();

    let parent = thumb.parentElement;

    if (exOpt.parentSelector) {
        //当thumb.parent 范围不是可移动的所有范围时，需要手动传入 (表现为thumb无法移动)
        parent = exOpt.parentSelector(thumb);
    }
    // 鼠标按下事件
    thumb.onmousedown = function (e) {

        let startX = e.clientX;

        let virtualThumb              = document.createElement('div');
        virtualThumb.style.background = 'gray';
        virtualThumb.style.width      = '8px';
        virtualThumb.style.position   = 'absolute';
        virtualThumb.style.top        = getElementTop(thumb) + 'px';
        virtualThumb.style.left       = getElementLeft(thumb) + 'px';
        virtualThumb.style.height     = thumb.clientHeight + 'px';
        virtualThumb.style.cursor     = 'col-resize';
        virtualThumb.style.zIndex     = '1024';

        document.body.append(virtualThumb);

        let offset           = 0;
        document.onmousemove = function (e) {

            let mouseOffset     = (e.clientX - startX);
            let leftSideOffset  = thumb.offsetLeft + mouseOffset;
            let rightSideOffset = (parent.clientWidth + parent.offsetLeft) - thumb.offsetLeft - mouseOffset;

            //两边间距都要预留最小宽度
            if ((leftSideOffset > minWidth) && (rightSideOffset > minWidth)) {
                offset                        = mouseOffset;
                virtualThumb.style.marginLeft = offset + 'px';
            }
        };

        // 鼠标松开事件
        document.onmouseup = function (evt) {
            document.onmousemove = null;
            document.onmouseup   = null;

            let view  = document.getElementById(layerId);
            let width = view.clientWidth + (exOpt.changeLeftPart ? offset : -offset);

            setWidth(width);

            virtualThumb.remove();
        };
        return false;
    }

    //获取绝对位置（left)
    function getElementLeft(element) {
        let actualLeft = element.offsetLeft;
        let current    = element.offsetParent;

        while (current !== null) {
            actualLeft += current.offsetLeft;
            current = current.offsetParent;
        }

        return actualLeft;
    }

    //获取绝对位置（top)
    function getElementTop(element) {
        let actualTop = element.offsetTop;
        let current   = element.offsetParent;

        while (current !== null) {
            actualTop += current.offsetTop;
            current = current.offsetParent;
        }
        return actualTop;
    }


    //设置宽度
    function setWidth(width: number) {
        let view         = document.getElementById(layerId);
        view.style.width = width + 'px';

        _store.saveLayout(exOpt.storeKey, width);
    }


    //加载保存的位置
    function loadInitWidth() {
        let storeWidth = _store.getLayout(exOpt.storeKey);
        if (storeWidth)
            setWidth(storeWidth as number);
    }
}

function clearDragSplit(thumbId: string) {
    let thumb = document.getElementById(thumbId);
    if (thumb)
        thumb.onmousedown = null;
}


class DragStore {

    private dragStore = {} as any;
    private storeKey  = 'store.drag-splitter'

    constructor() {
        this.initStore();
    }

    initStore() {
        //todo 因为indexDB时异步操作，恢复布局会引起界面闪烁， 后续考虑优化，使用IndexDB存储，并做成通用的存储
        let content = localStorage.getItem(this.storeKey);
        if (!content)
            return;

        this.dragStore = JSON.parse(content);
    }

    saveLayout(key: string, width: number) {
        if (!key)
            return;

        this.dragStore[key] = width;

        let content = JSON.stringify(this.dragStore);
        localStorage.setItem(this.storeKey, content);
    }

    getLayout(key: string) {
        if (!key)
            return undefined;

        return this.dragStore[key];
    }
}

let _store = new DragStore();
