<template>
  <div class="layer-vertical" style="height: 100%; ">

    <keywordField style="margin: 0 0 8px 0; max-width: 90%"
                  v-model:value="data.para.Keyword"
                  :tool-tip="searchOpt.keywordPlaceholder"
                  @onSearch="doSearch()"/>

    <DxDataGrid :ref="view.gridRef" class="layer-content"
                v-bind="$util.grid.gridOption({ selectionMode: 'single', pagerVisible: false })"
                :dataSource="data.source"
                @rowDblClick="p => emit('onDblClick', p)"
                :columns="getPatientColumns()">
    </DxDataGrid>
  </div>
</template>

<script setup lang="ts">
import * as api                            from "@api/patient/patient";
import KeywordField                        from '@src/pages/common/search/widgets/keywordField.vue'
import { getPatientColumns, searchOpt }    from "../../../search/scripts/scheme";
import { onMounted, reactive, ref, watch } from "vue";
import { GridContext }                     from "@scripts/controls/extend/gridHelper";
import { PatientData }                     from "@src/pages/patient/main/scripts/common";

let props = defineProps<{
  sourcePatient: PatientData,
}>()

let view = {
  gridRef: ref(undefined),
};

let data = reactive({
  para:   {
    Keyword:  undefined,
    PagePara: {
      Skip: 0,
      Take: 30,
    }
  },
  source: [],
})

let emit = defineEmits(['onDblClick']);

onMounted(updateSourcePatient);
watch(() => props.sourcePatient, updateSourcePatient);


async function updateSourcePatient() {
  data.para.Keyword = props.sourcePatient.Name;
  await doSearch();
}

async function doSearch() {
  let currentId = props.sourcePatient.GlobalPatientId;
  let result    = await api.search(data.para);
  let patients  = result.Items as PatientData[];
  data.source = patients.filter(i => i.GlobalPatientId !== currentId);
}

function focusPatient() {
  return (new GridContext(view.gridRef)).getFocusData();
}

let viewApi = {
  focusPatient,
}

defineExpose(viewApi);
export type PatientViewAPI = typeof viewApi;


</script>

<style scoped>

</style>