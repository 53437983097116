<template>
  <a-modal v-model:visible="data.visible"
           title="重新预约"
           :width="920"
           @cancel="onCancel" @ok="onCommit">
    <div >
      <div>
        <a-space>
          <span>重新预约原因：</span>
          <a-radio-group v-model:value="scheduleReason.value" :options="scheduleReason.options"></a-radio-group>
        </a-space>
      </div>
      <a-space style="margin-top: 8px; margin-bottom: 8px">
        <a-checkbox v-model:checked="printInfo.enable.value">打印检查小条</a-checkbox>
        数量：
        <a-select v-model:value="printInfo.count.value"
                  :options="printInfo.countOptions">
        </a-select>
        <span>预约时间：</span>
        <span style="color: red; font-weight: bold;">{{ data.detail }}</span>
      </a-space>
    </div>
    <ScheduleEditor v-if="data.visible"
                    v-model:deviceId="data.deviceId"
                    :order="data.order" @onSelect="onSelect"/>
  </a-modal>
</template>

<script setup lang="ts">

import * as api                from '@api/order/register'
import { IPeroidData }         from '@api/order/schedule'
import { Defer, ScheduleInfo } from '@scripts/types'
import { ScheduleUtil }        from '@src/pages/order/share/scheduleEditor/scripts/util'
import ScheduleEditor          from "./scheduleEditor.vue";
import { reactive }            from "vue";
import { getGlobalAutoRef }    from '@scripts/util/StoreHelper'


let printInfo = {
  enable:       getGlobalAutoRef('print.check.enable', false),
  count:        getGlobalAutoRef('print.check.count', 1),
  countOptions: [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
  ]
};

let scheduleReason = reactive({
  value:   1,
  options: [
    { value: 1, label: '患者不适' },
    { value: 2, label: '设备异常' },
    { value: 3, label: '其他' },
  ]
});


let viewApi = {
  show
}

export type ReScheduleApi = typeof viewApi;
defineExpose(viewApi);

let data = reactive({
  visible:  false,
  detail:   '',
  order:    undefined as OrderView,
  deviceId: undefined as string,
});


let _result       = undefined as Defer<boolean>;
let _scheduleInfo = undefined as ScheduleInfo;

function show(order: OrderView) {

  _scheduleInfo = undefined;
  _result       = new Defer<boolean>();

  data.visible  = true;
  data.detail   = getTimeInfo();
  data.order    = order;
  data.deviceId = order.Order.DeviceId;

  return _result.promise;
}

function onSelect(e: IPeroidData) {
  _scheduleInfo = {
    Date:      e.Date,
    StartTime: e.StartTime,
    EndTime:   e.EndTime,
  };

  data.detail   = getTimeInfo();
}

//提交
async function onCommit() {
  let scheduleInfo = $util.deepClone(_scheduleInfo || data.order.Schedule);
  if (!scheduleInfo) {
    $util.throwError('未选择预约时间');
  }

  //更新设备信息
  (scheduleInfo as any).DeviceId = data.deviceId;
  await api.reSchedule(data.order.Id, scheduleInfo);

  if (printInfo.enable.value) {
    await $ris.common.printOrder(data.order.Id, 'Schedule', printInfo.count.value);
  }

  _result.resolve(true);
  data.visible = false;
}

//取消
function onCancel() {
  _result.resolve(false);
  data.visible = false;
}

//获取预约时间段
function getTimeInfo() {
  let scheduleInfo = $util.deepClone(_scheduleInfo || data.order?.Schedule);
  return ScheduleUtil.scheduleDetail(scheduleInfo)
}


</script>

<style scoped>

</style>