/**
 * 显示年龄
 */
export function ageDisplay(order) {

    let age     = order.Patient.Age;
    let ageUnit = order.Patient.AgeUnit;

    if (!age || !ageUnit)
        return '';

    let ageUnitDisplay = getDicDisplay('AgeUnit', ageUnit);
    return `${age}${ageUnitDisplay}`;
}

/**
 * 性别
 */
export function genderDisplay(order) {
    return getDicDisplay('Gender', order.Patient.Gender);
}

/**
 * 获取数据字典显示
 */
function getDicDisplay(category, code) {
    let source = $ris.dataDic[category];
    if (!source) {
        return '-'
    }

    let dic = source.find(i => i.Code === code);
    if (!dic) {
        return '-';
    }

    return dic.Name;
}


/**
 * 时间格式化输出 (天）
 */
export function dayToStr(time) {
    return formatTime(time, 'day');
}

/**
 * 时间格式化输出 (天）
 */
export function timeToStr(time) {
    return formatTime(time, 'dateTime');
}

function formatTime(time, format: 'dateTime' | 'day') {
    if (!time)
        return undefined;

    let fmt = format === 'dateTime' ? $util.format.date : $util.format.day;
    return $util.dayjs(time).format(fmt);
}