let createObjectURL = webkitURL.createObjectURL || URL.createObjectURL;
let revokeObjectURL = webkitURL.revokeObjectURL || URL.revokeObjectURL;

export class PdfViewerHelper {

    private blobUrl = undefined as string;     //如果传入的是二进制对象，通过bloburl的方式展示，需要及时释放

    /**
     * 获取预览url，支持url， blob， base64等方式的内容传入
     */
    async getUrl(src: any) : Promise<string> {

        if (typeof src === 'string') {
            let fileUrl = src as string;
            return fileUrl.startsWith('data:') ? (await this.base64ToBlobUrl(fileUrl)) : fileUrl      //认为data:打头的为base64结构的pdf文件
        }

        if (src instanceof Blob) {
            return this.createBlobUrl(src);
        }

        console.error(src);
        $util.throwError('无效PDF地址')
    }

    /**
     * 释放blobUrl
     */
    destory() {
        this.releaseBlobUrl();
    }

    //根据blob二进制对象创建url
    private createBlobUrl(blobData) {
        this.releaseBlobUrl();

        this.blobUrl = createObjectURL(blobData);
        return this.blobUrl;
    }

    //释放掉bloburl所占用的资源
    private releaseBlobUrl() {
        if (!this.blobUrl) {
            return;
        }

        revokeObjectURL(this.blobUrl);
        this.blobUrl = undefined;
    }

    //base64类型的二进制转换为bloburl
    private async base64ToBlobUrl(url) {
        let res  = await fetch(url);
        let blob = await res.blob();
        return this.createBlobUrl(blob);
    }
}
