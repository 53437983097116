//ant相关控件
import { AntControls }    from './antd'
import { DxControls }     from "./dx";
import { CustomControls } from "./custom";

//自定义样式
import '/src/style/ris/ris.global.css'

export default {
    install: (app, options) => {
        app.use(AntControls);
        app.use(DxControls);
        app.use(CustomControls);
    }
}