<template>

  <div v-if="!order" class="empty-container">
    <a-empty style="width: 100%" description="无申请单"/>
  </div>

  <PdfReport v-else-if="viewType === 'report'" style="height: 100%" :report-id="data.reportId"/>

  <DxScrollView v-else-if="viewType === 'order'">

    <orderViewer :order="order" :allow-add-comments="false" :hide-link-on-pid="true"
                 style="margin-right: 8px; margin-left: 8px"/>

  </DxScrollView>



  <div v-else-if="viewType === 'reportFollowUp'"
       style="padding: 8px; overflow-y: auto; ">
    <RptFollowUp  :showEditBtn="true" style="margin-bottom: 24px"
                  :readOnly="order.IsArchived || readOnly"
                  :report-id="data.reportId"/>
  </div>


</template>

<script setup lang="ts">

import OrderViewer from "@src/pages/order/share/orderViewer/orderViewer.vue";
import PdfReport   from "@src/pages/report/share/pdfReport.vue";
import RptFollowUp from '@src/pages/report/operation/widgets/followUpInfo.vue'

import * as reportSearchApi from '@api/report/search'
import { DetailViewType }   from "../../main/scripts/common";
import { reactive, watch }  from "vue";

let props = defineProps<{
  order: OrderView,
  viewType: DetailViewType,
  readOnly?: boolean,
}>();

let data = reactive({
  reportId: undefined as string,
});

watch(() => props.order, async order => {
  if (!order)
    return;

  data.reportId = await reportSearchApi.getReportIdByOrder(order.Id);
});

</script>

<style scoped>

</style>