<template>

  <a-config-provider :locale="locale" v-if="data.isLoaded">
    <login v-if="data.isLogin"/>
    <main-frame v-else :is-full-screen="data.isFullScreen"/>
  </a-config-provider>

</template>

<script setup lang="ts">

import MainFrame      from "@src/pages/desktop/frame.vue";
import Login          from "@src/pages/desktop/login.vue";
import { globalView } from '@scripts/ris/common'
import { router }     from "@scripts/ris/router";

import zhCN from 'ant-design-vue/es/locale/zh_CN';
import enUS from 'ant-design-vue/es/locale/en_US';

import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');

import { reactive } from 'vue'


zhCN.Modal.justOkText = "确定"

//antd有个bug会导致对话框的中文提示会变成英文的， 这里通过修改英文的语言来规避这个问题
enUS.Modal.okText     = "确定"
enUS.Modal.cancelText = "取消"
enUS.Modal.justOkText = "确定"

let locale = zhCN;

router.afterEach(onAfterRoute);

let data = reactive({
  isLoaded:     false,
  isLogin:      false,
  isFullScreen: false,
});


function onAfterRoute(to, from) {

  let path       = to.path.toLowerCase();
  let isFullView = to.query.isFullView as string

  data.isLoaded     = true;
  data.isLogin      = (path === '/login');
  data.isFullScreen = (isFullView?.toLowerCase() === 'true');

  globalView.showNavIcon.value = false;
}


</script>

<style>
#app {
  font-family: "微软雅黑", arial, helvetica, clean, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
</style>