<template>
  <DxScrollView style="height: 100%; padding-left: 8px;">
    <a-directory-tree expandAction="click"
                      style="margin-top: 8px"
                      :treeData="nodes"
                      :icon="getNodeIcon"
                      @select="onSelect"
                      v-model:selectedKeys="data.selectedKeys"
                      v-model:expandedKeys="data.expandedKeys">
      <template #title="{title, dataRef}">
        <slot name="title" :title="title" :dataRef="dataRef">
          {{ title }}
        </slot>
      </template>
    </a-directory-tree>
  </DxScrollView>
</template>

<script setup lang="ts">

import * as tree                      from '@scripts/controls/extend/tree'
import { reactive, onMounted, watch } from 'vue'

let props = defineProps<{

  //树节点
  nodes: TreeNodeData[],

  //选中的节点
  selectedKey?: string | number,

  //展开的节点
  expandedKeys?: (string | number)[],

  //默认图标，对叶子节点有效
  defaultIcon?: string,
}>();

let data = reactive({
  expandedKeys: props.expandedKeys,
  selectedKeys: props.selectedKey ? [props.selectedKey] : [],
});

watch(() => props.selectedKey, value => data.selectedKeys = props.selectedKey ? [props.selectedKey] : [])

const emit = defineEmits<{
  (e: 'onSelect', key: any, data: TreeNodeData): void,
  (e: 'update:selectedKey', value: any,): void,
}>();


onMounted(() => {
  //对初始选中的节点触发选中事件
  let node = tree.findTreeNode(props.nodes, props.selectedKey);
  if (node) {
    emit('onSelect', node.key, node);
  }
})

//选中事件
function onSelect(keys: number[], e) {

  if (keys.length === 0 && e.node) {
    //antdv3 有去选中的效果，且没有版本禁用， 这里用代码禁用去选中
    data.selectedKeys = [e.node.key];
    keys              = [e.node.key];
  }

  let key      = keys[0];
  let nodeData = e.node.dataRef as TreeNodeData;

  if (nodeData.children) {
    return;
  }

  emit('update:selectedKey', key);
  emit("onSelect", key, nodeData);
}


//获取节点图标
function getNodeIcon(e) {
  let defaultIcon = props.defaultIcon || 'FileTextOutlined'
  return tree.getNodeIcon(e, defaultIcon);
}

</script>

<style scoped>

</style>