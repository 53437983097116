<template>
  <a-card size="small" style="margin-top: 12px; background: lightyellow; height: 92px">
    <div>
      <a-steps size="small" class="sub-title-as-diff desc-align-left">
        <a-step v-for="n in data.nodes" :status="n.status" :title="n.name" :subTitle="n.timeDiff">
          <template #icon>
            <ris-icon :icon="n.icon"/>
          </template>
          <template #description>
            <div>{{ n.time }}</div>
            <div>{{ n.staff }}</div>
          </template>
        </a-step>

      </a-steps>
    </div>

  </a-card>


</template>

<script setup lang="ts">

import { reactive, watch, onMounted } from 'vue'

let props = defineProps<{
  orderId?: string,
}>();

let data = reactive({
  nodes: [],
});

watch(() => props.orderId, loadNodes);
onMounted(() => loadNodes(props.orderId))

async function loadNodes(orderId) {
  if (!orderId)
    return [];

  let rsp = await axios.get('/api/Order/Search/GetOrderTracking', { params: { orderId } });
  if (props.orderId !== orderId)
    return;

  let lstIdx=rsp.data.length-1;
  data.nodes = rsp.data.map((n,i)=>{
    return mapNode(n,i<lstIdx ? rsp.data[i+1] : null);
  });
}


function mapNode(node, nextNode = null) {
  let traceNode = {
    name:   undefined,
    icon:   undefined,
    time:   undefined,
    staff:  node.Staff,
    status: node.Time ? 'finish' : 'wait',
    timeDiff:undefined
  };

  traceNode.time = node.Time ? $util.dayjs(node.Time).format('YYYY-M-D H:mm') : undefined;

  let action     = getNodeAction(node.Action);
  traceNode.name = action[0];
  traceNode.icon = action[1];
  
  if(nextNode && nextNode.Time && node.Time){
    traceNode.timeDiff= duration($util.dayjs(nextNode.Time).diff(node.Time));///60000;//m
  }

  return traceNode; 
}

function duration(ms){
  ms = Math.round(ms/60000);
  let dur='';
  [{v:1440,u:'天'},{v:60,u:'小时'},{v:1,u:'分'}].forEach(p=>{
    let v = Math.floor(ms/p.v);
    if (dur == '') {
      if (v>0) dur=`${dur}${v}${p.u}`;
    } else {
      dur = `${dur}${v}${p.u}`;
    }
    ms = ms % p.v;
  });
  return !!dur ? dur : '0分';
}


function getNodeAction(action) {
  switch (action) {
    case 'Schedule':
      return ['预约', 'CalendarOutlined'];
    case 'Checkin':
      return ['到检', 'SolutionOutlined'];
    case 'Study':
      return ['检查', 'InstagramOutlined'];
    case 'Report':
      return ['报告', 'ReconciliationOutlined'];
    case 'Print':
      return ['打印', 'PrinterOutlined'];
    case 'Cancel':
      return ['取消', 'CloseCircleOutlined'];
  }
}

</script>

<style scoped>
.sub-title-as-diff:deep(.ant-steps-item-subtitle){
  position: absolute;
  left:32px;
  top:-8px;
}
.sub-title-as-diff:deep(.ant-steps-item){
  padding-top: 4px;
}
.desc-align-left:deep(.ant-steps-item-description){
  margin-left: -30px;
}
</style>