/**
 * 获取所有纸质申请单
 * @param orderId
 * @returns {Promise<string[]>}
 */
export async function getAll(orderId) {
    let rsp = await axios.get('/api/Order/PaperOrder', { params: { orderId } });
    return rsp.data;
}

export async function add(orderId, content) {
    let rsp = await axios.post(
        '/api/Order/PaperOrder',
        { content },
        { params: { orderId } }
    );
    return rsp.data;
}


export async function remove(recordId) {
    await axios.delete('/api/Order/PaperOrder', { params: { recordId } })
}


export function preview(imageId) {
    return '/api/Order/PaperOrder/Preview' + '?imageId=' + imageId;
}

/**
 * 查询申请单是否有纸质申请单
 */
export async function hasPaperOrder(orderId) {
    let rsp = await axios.get('/api/Order/PaperOrder/HasPaperOrder', { params: { orderId } });
    return rsp.data as boolean;
}