<template>

  <!--  左右两栏的布局类型，  左右分别有独立滚动条 目前缺少拖拽导航栏宽度的功能-->
  <div style="height: 100%; display: flex; flex-direction: row;">
    <div id="navBar" class="left" v-show="showNavBar">
      <slot name="navBar"></slot>
    </div>

    <drag-splitter thumb-id="navThumb" view-id="navBar"
                   v-if="showNavBar"
                   view-part="left" :storeKey="splitterStoreKey">
      <div id="navThumb"></div>
    </drag-splitter>

    <div style="width: 100%; overflow-x: hidden">
      <slot></slot>
    </div>
  </div>


</template>
<script setup lang="ts">


import { globalView } from '@scripts/ris/common'
import DragSplitter   from "./DragSplitter.vue";

import { onActivated, onMounted, ref } from 'vue'


const props = defineProps<{
  splitterStoreKey?: string,
}>();

let showNavBar = ref(true);


onMounted(onNavBarShown)
onActivated(onNavBarShown);

function onNavBarShown() {
  globalView.showNavIcon.value = true;
  globalView.showNavBar        = showNavBar;
}


</script>

<style scoped>
#navThumb {
  width: 8px;
  cursor: col-resize;
  z-index: 100;
  border: solid 1px #E1E1E1;
  background-color: #FFF;
}

#navThumb:hover {
  background-color: #DDD;
}

.left {
  flex: none;
  width: 300px;
  overflow-x: scroll;

  background-color: #f5f5f5;
  box-shadow: -5px 0 14px -8px rgba(0, 0, 0, 0.15) inset;
}

</style>