import dxDataGrid from "devextreme/ui/data_grid";

/**
 * 获取grid实例，ref和reactive的方式自动适配
 */
export function instance(gridRef): dxDataGrid {

    let gridData = gridRef;

    if (typeof gridRef === 'function') {    //工厂函数
        gridData = gridRef();
    }

    if (gridData?.value) {          //ref类型
        gridData = gridData.value;
    }

    return gridData.instance;
}

/**
 * 获取grid帮助类
 */
export function getGridHelper(gridRef) {
    return new GridContext(gridRef);
}


/**
 * dxDataGrid帮助类，是dxDataGrid的一个简化的封装
 */
export class GridContext<T = any> {
    private gridRef: any;

    constructor(gridRef) {
        this.gridRef = gridRef;
    }

    get value() {
        return instance(this.gridRef)
    }

    //使用于无法直接绑获取grid ref的场景
    //gridRef成员是lazy的，只要使用前设置了即可
    setGridRef(gridRef) {
        this.gridRef = gridRef;
    }

    /**
     * 自定义列
     */
    customColumns() {
        this.value.showColumnChooser();
    }

    //恢复布局
    resetLayout(){
        this.value.state({});
    }

    /**
     * 获取选中的列
     */
    getSelectedKeys() {
        return this.value.getSelectedRowKeys();
    }

    /**
     * 设置选中的列
     */
    async setSelectKeys(keys :any[], preserve = false) {
        await this.value.selectRows(keys, preserve);
    }

    /**
     * 获取选中的项
     */
    getSelectedItems() {
        return this.value.getSelectedRowsData() as T[];
    }

    /**
     * 清除选中状态
     */
    clearSelection() {
        this.value.clearSelection();
    }

    /**
     * 获取当前聚焦的数据
     */
    getFocusData(): T | undefined {
        let rowIndex = this.value.option('focusedRowIndex')
        return this.getRowData(rowIndex);
    }

    // //聚焦到某一行， 用于grid刷新后选中默认值
    // focusRow(key) {
    //     //控件本身有问题，设置数据绑定后，会出现点击时grid列闪烁现象, 只能用这种方式
    //     this.value.option('focusedRowKey',key);
    //     this.value.option('selectedRowKeys', [key])
    // }

    //通过id获取行数据
    getDataById(id: string | number) {
        let index = this.value.getRowIndexByKey(id);

        return this.getRowData(index);
    }

    private getRowData(rowIndex: number) {
        let rows = this.value.getVisibleRows();
        let row  = rows[rowIndex];

        return (row?.rowType === 'data') ? row?.data : undefined;
    }

    //如果当前没有选中的行， 则选中第一行， 用于搜索后的初始化
    tryFocusFirstRow(){
        if (this.getFocusData()){
            return;
        }

        let firstRow = this.value.getRowElement(0);
        if (firstRow) {
            this.value.focus(firstRow[0]);
            this.value.selectRowsByIndexes([0]).then();
        }
    }

    /**
     * 导出数据
     */
    exportData() {

        // api: https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxDataGrid/Methods/#exportToExcelselectionOnly
        //此接口不推荐使用， 有时间改为： https://js.devexpress.com/Documentation/ApiReference/Common/Utils/excelExporter/#exportDataGridoptions

        // @ts-ignore
        this.value.exportToExcel();
    }
}


//method api可参考  https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Methods/