import { ReportViewOpt } from '@src/pages/report/main/scripts/viewOption'
import { reactive }      from 'vue'
import { logout }           from "@scripts/ris/auth/login";

export let actionOpt = reactive({
    showDownloadView: false,
    showHelpView:     false,
    showLockScreen:   false,
    showSwitchPortal: false,
    viewSession:      Symbol(),
    refresh:          () => actionOpt.viewSession = Symbol(),        //主要用于切换子系统后的组件显示更新
});

/**
 * 点击时的行为
 */
export function invoke(node: WorkNode) {
    if (node.validation) {
        node.validation();
    }
    if (node.route) {
        $ris.router.push(node.route);
    }
    else if (node.action) {
        node.action();
    }
}

// 双击事件
export function dbInvoke(node: WorkNode) {
    if (node.validation) {
        node.validation();
    }
    if (node.route) {
        var target = '_blank'   
        var routeData = $ris.router.resolve({path: node.route})      
        window.open(routeData.href, target)
    }
    else if (node.action) {
        node.action();
    }
}


export interface WorkNode {

    //显示的名称
    label: string,

    //短名称，用于左侧导航栏
    shortLabel?: string,

    // 点击时切换的路由， 和action互斥
    route?: string,

    //点击时执行的函数，和route互斥
    action?: () => any,

    //在侧边导航栏显示时的图标， 使用的是antd的图标
    icon?: string,

    //在工作站显示时候的图片
    image?: string,


    //打开工作台前执行的校验函数
    validation?: () => void,

    visible?: boolean,

    subMenu?: WorkNode[]
}

/**
 * 获取工作站节点
 */
export function getWorkNodes(forNavBar: boolean) {

    let actions = $ris.user.Actions;         //当前用户所有的权限
    let isManage = actions['ManagerOrder'] || actions["ManageBusiness"] || actions["ManageFinance"];

    //let portalType     = $ris.portal.config.PortalType;
    //let writeReport    = ReportViewOpt.writeRportInRis
    //let showClinicView = ReportViewOpt.isClinicalDoctor;

    let workNodes: WorkNode[] = [
        {
            label:      '首页',
            shortLabel: '首页',
            route:      '/',
            icon:       'DesktopOutlined',
            visible:    true,
        },
        {
            label:      '委托登记',
            shortLabel: '登记',
            route:      '/consignmentRegist',
            icon:       'InsertRowAboveOutlined',
            image:      'browser.png',
            visible:    actions['RegisterOrder'],
        },
        {
            label:      '委托登记-空',
            shortLabel: '登记(空)',
            route:      '/consignmentRegistEmpty',
            icon:       'InsertRowAboveOutlined',
            image:      'browser.png',
            visible:    actions['RegisterOrderEmpty'],
        },
        {
            label:      '预约登记',
            shortLabel: '登记',
            route:      '/order',
            icon:       'CalendarOutlined',
            image:      'browser.png',
            visible:    false,
        },
        {
            label:      '委托管理',
            shortLabel: '管理',
            route:      '/consignmentManage',
            icon:       'SolutionOutlined',
            image:      'polaroids.png',
            visible:    actions['ManagerOrder'] || actions['ManagerEnterAppear'],
        },  
        {
            label:      '委托管理(空)',
            shortLabel: '管理(空)',
            route:      '/consignmentManage_Empty',
            icon:       'SolutionOutlined',
            image:      'polaroids.png',
            visible:    actions['ManagerOrderEmpty'],
        },       
        {
            label:      '约点处理',
            shortLabel: '约点',
            route:      '/aboutpoint',
            icon:       'FieldTimeOutlined',
            image:      'polaroids.png',
            visible:    actions['ManagerOrder'],
        },
        {
            label:      '委托处理',
            shortLabel: '业务',
            route:      '/consignmentProcess',
            icon:       'TransactionOutlined',
            image:      'polaroids.png',
            visible:    actions['ManageBusiness'] || actions['ManagerEnterAppear'],
        },
        {
            label:      '委托处理',
            shortLabel: '业务(空)',
            route:      '/consignmentProcess_Empty',
            icon:       'TransactionOutlined',
            image:      'polaroids.png',
            visible:    actions['ManagerOrderEmpty'],
        },
        {
            label:      '预置场位',
            shortLabel: '场位',
            route:      '/yardPresetting',
            icon:       'ControlOutlined',
            image:      'polaroids.png',
            visible:    actions['ManagerPosition'],
        },
        {
            label:      '费用查询',
            shortLabel: '费用',
            route:      '',
            icon:       'TransactionOutlined',
            image:      'static/image/workplace/feiyong.png',
            visible:    actions['ManageFinance'],
            subMenu: [{
                label: "应收查询",
                shortLabel: '应收',
                route:      '/consignmentReceivable',
                icon:       'TransactionOutlined',
                image:      'polaroids.png',
                visible: actions['ManageFinance']
            },
            {
                label: "账单中心",
                shortLabel: '账单',
                route:      '/billingCenter',
                icon:       'TransactionOutlined',
                image:      'polaroids.png',
                visible: actions['ManageFinance']
            },
            {
                label: "退款记录",
                shortLabel: '退款',
                route:      '/refundRecord',
                icon:       'TransactionOutlined',
                image:      'polaroids.png',
                visible: actions['ManageFinance']
            }]
        },      
        // {
        //     label:      '业务',
        //     shortLabel: '业务',
        //     route:      '/patient',
        //     icon:       'IdcardOutlined',
        //     image:      'contacts.png',
        //     visible:    actions['ManagerUser'],
        // },
        // {
        //     label:      '堆场预置',
        //     shortLabel: '堆场',
        //     route:      '/perform',
        //     icon:       'DatabaseOutlined',
        //     image:      'lens.png',
        //     visible:    actions['ManagerUser'],
        // },
        // {
        //     label:      '数据统计',
        //     shortLabel: '统计',
        //     route:      '/stat',
        //     icon:       'BarChartOutlined',
        //     image:      'piechart.png',
        //     visible:    actions['ManagerUser'],
        // },
        {
            label:      '系统管理',
            shortLabel: '系统',
            route:      '/setting',
            icon:       'ControlOutlined',
            image:      'gear.png',
            visible:    actions["ManageSystem"] || actions["ManageShip"] || actions["ManageSystemFinance"],
        },
        {
            label:      '调度任务',
            route:      '/ScheduleTask',
            icon:       'DeploymentUnitOutlined',
            shortLabel: '调度',
            visible:    (actions['ManagerOrder'] || actions['ManagerEnterAppear'] || actions['ManagerOrderEmpty']),
        }, 
        {
            label:      '场图信息',
            shortLabel: '场图',
            route:      '/FieldPattern',
            icon:       'BoxPlotOutlined',
            image:      'polaroids.png',
            visible:    (actions['ManagerOrder'] || actions['ManagerEnterAppear'] || actions['ManagerOrderEmpty']) ,
        },
        {
            label:      '车载系统',
            shortLabel: '车载',
            route:      '/vehiclemounted',
            icon:       'SolutionOutlined',
            image:      'polaroids.png',
            visible:    (actions['ManagerOrder'] || actions['ManagerEnterAppear'] || actions['ManagerOrderEmpty']),
        },
    ];


    workNodes = workNodes.filter(n => n.visible);

    //todo 这个条件不准确，后续改成显式标记
    if (forNavBar) {
        return workNodes.filter(n => n.icon !== undefined);
    }
    else {
        return workNodes.filter(n => n.image);
    }
}

/**
 * 获取快捷方式节点
 */
export function getActionNodes() {

    let actionNodes: WorkNode[] = [
        {
            label: '用户中心', route: '/account',
            icon:  'UserOutlined', image: 'profle.png'
        },
        // {
        //     label: '帮助', action: () => actionOpt.showHelpView = true,
        //     icon:  'QuestionCircleOutlined', image: 'booklet.png'
        // },
        // {
        //     label: '下载', action: () => actionOpt.showDownloadView = true,
        //     icon:  'DownloadOutlined', image: 'download.png'
        // },
        // {
        //     label:   '切换子系统', action: () => actionOpt.showSwitchPortal = true,
        //     icon:    'SwapOutlined', image: 'stack.png',
        //     visible: $ris.user.HasMultiPortal,
        // },
        // {
        //     label: '锁屏', action: () => actionOpt.showLockScreen = true,
        //     icon:  'LockOutlined', image: 'key.png'
        // },
        {
            label: '安全退出', action: doLogout,
            icon:  'PoweroffOutlined', image: 'power.png'
        },
    ];

    return actionNodes.filter(i => i.visible !== false);
}

export function changePortal() {
    actionOpt.showSwitchPortal = true;
    actionOpt.refresh()
}

export async function doLogout() {
    let msg = '退出系统后，所有未保存的数据将丢失。确定要退出系统吗？';
    if (await $util.dialogs.confirm(msg)) {
        await logout();
        //await $util.client.closePacsClient();
    }
}


export function checkPerformStation() {
    let devices = $ris.stations.perform.devices;
    if (devices.length === 0)
        $util.throwError('无匹配检查室，请联系管理员', { isTooltip: false });
}