<template>

  <div style="border:#F1F1F1 solid 1px;padding:8px;border-radius:4px;">
    <NavBar @onDateChanged="onDateChanged">
      <template #device>
        <a-space v-if="!hideDevice">
          <span>检查室:</span>
          <a-select style="width: 220px;margin-right:8px;" @change="onDeviceChanged"
                    v-model:value="ctx.deviceId" :options="ctx.deviceList"/>
        </a-space>
      </template>
    </NavBar>

    <div style="min-height:160px;">
      <PeriodInfo style="width: 180px" v-for="info in ctx.details" @click="onSelect(info)"
                  :data="info" :is-selected="ctx.selectValue === getSelectValue(info)" :disabled="info.IsExpired"/>
    </div>
  </div>

</template>

<script setup lang="ts">

import { IPeroidData }                from '@api/order/schedule'
import * as api                       from '@api/order/schedule'
import { onMounted, reactive, watch } from 'vue'
import PeriodInfo                     from "./widgets/periodInfo.vue";
import NavBar                         from "./widgets/navBar.vue";

let props = defineProps<{
  order?: OrderView,
  deviceId: string,
  hideDevice?: boolean,
}>()

let ctx = reactive({
  date:        $util.dayjs().format($util.format.day),
  details:     [] as IPeroidData[],
  deviceId:    undefined as string,
  deviceList:  [],
  selectValue: undefined as string,
});

ctx.deviceId = props.deviceId;

let emits = defineEmits(['onSelect', 'update:deviceId']);

let order = props.order as OrderView;

//预约时外部修改设备
watch(()=> props.deviceId, ()=> ctx.deviceId = props.deviceId);

watch([() => ctx.date, () => ctx.deviceId], loadDetails)
watch(() => order.Order.ModalityCode, updateDeviceList);

onMounted(loadDetails);
updateDeviceList();

function onDateChanged(date) {
  ctx.date = date;
}

async function loadDetails() {

  ctx.details = [];
  ctx.details = await api.getScheduleDetailByDevice(ctx.deviceId, ctx.date, 1);

  ctx.selectValue = order.Order.DeviceId === ctx.deviceId
                    ? getSelectValue(order.Schedule as IPeroidData)
                    : undefined;
}

function getSelectValue(e: IPeroidData) {
  if (!e)
    return undefined;

  return `${e.Date} ${e.StartTime}`;
}

function onSelect(e: IPeroidData) {
  if (e.UsingCount >= e.MaxCount) {
    $util.message.error('可预约数已满');
    return;
  }

  ctx.selectValue = getSelectValue(e);
  emits('onSelect', e);
}

function updateDeviceList() {
  let devices    = $util.form.filterDevicesByOrder(order.Order);
  ctx.deviceList = $util.form.getOptions(devices, 'ExamRoom').options;
}

function onDeviceChanged() {
  emits('update:deviceId', ctx.deviceId);
}

</script>

<style scoped>

</style>