import { watch } from 'vue'
import * as session from "@scripts/ris/session";
import { CAIntegration } from './caIntegration'
import { LoginResult } from '../auth/login';

export class LoginProxy {
    options: ILoginOptions = null;
    param: ILoginPara = null;
    ca: CAIntegration = null;

    constructor(opts: ILoginOptions, para: ILoginPara, logined: boolean = false) {
        this.options = opts;
        this.param = para;
        this.ca = new CAIntegration();
        if (logined) this.param.LoginType = this.curLoginType;

        watch(() => this.param.LoginType, (v) => {
            this.onLoginTypeChanged(v);
        });
    }

    private onLoginTypeChanged(loginType) {
        this.param.UserName = '';
        this.param.Password = '';
        this.param.CACertificateId = '';

        if (this.options.rules) {
            if (loginType == 'CA') {
                this.options.rules.UserName = [];
                this.options.rules.Password = [{ required: true, message: '请输入密码' }];
                this.bindUkeyList();
            }
            else {
                this.options.rules.UserName = [{ required: true, message: '请输入用户名' }];
                this.options.rules.Password = [{ required: true, message: '请输入密码' }];
            }
        }
    }

    /**
     * 获取并绑定Ukey列表
     */
    private async bindUkeyList(certUserId: string | null = null) {
        let keys = await this.ca.getCertList(
            () => this.bindUkeyList(certUserId),
            () => { this.param.LoginType = 'User'; }
        );
        if (keys == undefined) keys = [];

        this.options.certs = [];
        let exists = false;
        keys.forEach(el => {
            if (certUserId == el.Id) exists = true;
            this.options.certs.push({
                label: el.Name,
                value: el.Id,
                certUserId: el.CertificateUserId
            });
        });

        if (certUserId) {
            if (exists) {
                this.param.CACertificateId = certUserId
            } else {
                this.param.CACertificateId = undefined;
            }
        } else {
            this.param.CACertificateId = keys.length > 0 ? keys[0].Id : undefined;
        }

    }

    /**
     * 根据配置获取登录选项
     */
    loadLoginOptions() {
        let options = [];
        if (this.options.setting.EnableCAIntegration) {
            (this.options.setting.SystemLoginType || "").split('|').forEach((str, idx) => {
                if (!!str) options.push({ label: loginTypeDict[str] || str, value: str });
            });
        }

        if (options.length == 0) {
            options.push({ label: loginTypeDict.User, value: 'User' });
        }

        this.param.LoginType = options[0].value;
        this.options.loginTypeOption = options;
    }

    login(dftLogin: () => Promise<LoginResult>, updateStore: boolean = true) {
        if (updateStore) this.ca.clearCAUserInfo();
        if (this.param.LoginType == 'CA') {
            return this.ca.login({
                CertId: this.param.CACertificateId,
                CertUserId: this.options.certs.find(c => c.value == this.param.CACertificateId).certUserId,
                Password: this.param.Password,
                ServerNodeId: ''
            }, updateStore);
        }
        return dftLogin();
    }

    async loadAndBindCurUser() {
        this.param.CACertificateId = undefined;
        if (this.curLoginType == 'CA') {
            let certUserId = this.ca.curUserInfo?.CertId;
            await this.bindUkeyList(certUserId);
            if (!this.param.CACertificateId) {
                alert(`请插入登录时使用的CA USB Key后重试。`);
                return false;
            }
        }
        return true;
    }

    /**
     * 获取当前登录类型
     */
    get curLoginType() {
        let caUseInfo = this.ca.curUserInfo;
        if (caUseInfo && !!caUseInfo.LoginType) {
            return caUseInfo.LoginType;
        }
        return 'User';
    }
}

const loginTypeDict = {
    'User': '用户名密码登录',
    'CA': 'CA登录',
    'CA_QRCode': '移动CA登录'
};

export interface ILoginOptions {
    setting: session.LoginSetting,
    certs: any[],
    loginTypeOption: any[],
    rules: {
        UserName: any[],
        Password: any[]
    }
}

export interface ILoginPara {
    LoginType: string,
    Password: string,
    UserName: string,
    CACertificateId: string
}