<template>
  <div style=" background: white; border: 1px solid rgb(235, 237, 240);  margin-top: 2px; margin-bottom: 2px;">

    <a-form layout="inline" style="margin-left: 24px; margin-bottom: 8px; margin-top: 8px">

      <a-form-item label="检查项目">
        <a-select placeholder="全部" :allowClear="true" style="width: 180px"
                  v-model:value="ctx.data.checkItemFilter"
                  :options="ctx.data.checkItems"/>
      </a-form-item>

      <a-form-item :colon="false">
        <template #label></template>
        <a-select style="width:  380px"
                  v-model:value="ctx.data.activeOrderId"
                  :options="ctx.data.orderList"/>
      </a-form-item>
    </a-form>


  </div>
</template>

<script setup lang="ts">

import { PatientData }          from "../../main/scripts/common";
import { OrderTimeLineContext } from "../scripts/orderTimeLine";
import { watch }                from "vue";

let props = defineProps<{
  //患者信息
  patient: PatientData,

  //当前选中的申请单 （输出）
  order: OrderView,
}>();

let emit = defineEmits(['update:order']);
let ctx  = new OrderTimeLineContext();

ctx.onOrderChange = order => emit('update:order', order);
watch(() => props.patient, value => ctx.updatePatient(value), { immediate: true });

</script>

<style scoped>

</style>