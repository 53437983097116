<template>
  <div class="layer-vertical">
    <basic-info :patient="patient" :readOnly="readOnly"/>

    <OrderTimeLine :patient="patient" v-model:order="data.order"/>

    <ViewTypes :order="data.order"
               v-model:viewType="data.viewType" />

    <div class="layer-content detail">
      <DetailView :order="data.order" style=" height: 100%; margin-bottom: 4px;"
                  :readOnly="readOnly"
                  :view-type="data.viewType"/>
    </div>
  </div>

</template>

<script setup lang="ts">

import BasicInfo     from './widgets/basicInfo.vue'
import OrderTimeLine from './widgets/orderTimeLine.vue'
import ViewTypes     from './widgets/viewTypes.vue'
import DetailView    from './widgets/detailView.vue'

import { DetailViewType, PatientData } from "../main/scripts/common";
import { reactive }                    from "vue";

defineProps<{
  patient: PatientData,
  readOnly?: boolean,
}>();

let data = reactive({
  order:    undefined as OrderView,
  viewType: 'order' as DetailViewType,
});


</script>

<style scoped>

.detail {
  background: white;
  margin-top: -8px;
  margin-left: 1px;
  overflow-y: scroll;
  border: lightgray 1px;
}

</style>