/**
 * select项的model
 */
import { BasicDataType } from '@scripts/types'


export class SelectOption {

    options      = [];
    showSearch   = true;
    allowClear   = false;
    mode         = undefined as 'multiple' | 'tags' | 'combobox' | undefined;
    filterOption = searchFilter;

    dataType = undefined as string;
    //选中的再options不匹配后的回调值查询
    fallback = undefined as (value: any) => Promise<any>;

    constructor(source: string | BasicData[], filter, labelField: string) {
        let { options, dataType } = getSourceInfo(source, filter, labelField);

        this.options  = options;
        this.dataType = dataType;
        this.fallback = value => getBasicDataName(this.dataType, value);
    }
}

/**
 * 根据基础数据Id或数据字典Code查询名称
 * @param dataType 基础数据类型（如果是数据字典，格式为 DataDic.Group）
 * @param idOrCode
 */
export async function getBasicDataName(dataType: BasicDataType | string | undefined, idOrCode: string) {

    if (!dataType) {
        return undefined;
    }

    if (dataType.startsWith('DataDic.')){
        //数据字典
        let group = dataType.substring(8);
        let params = { code: idOrCode, group }

        let result = await $axios.get('/api/System/Util/GetDataDicName', { params })
        return result.data as string;
    }
    else {
        //基础数据
        let params = { id: idOrCode, dataType }
        let result = await $axios.get('/api/System/Util/GetBasicDataName', { params })
        return result.data as string;
    }
}

//获取内置数据源，支持根据字符串自动识别， 支持筛选器
export function getSourceInfo(dataSource: string | BasicData[], filter, labelField) {

    let source: BasicData[];
    if (typeof (dataSource) === "string") {
        source = getSourceByName(dataSource as string);
    }
    else {
        source = dataSource as BasicData[];
    }

    if (!source) {
        source = [];
    }

    if (filter) {
        source = source.filter(filter);
    }

    //todo 对于检查室的特殊处理

    labelField     = labelField || 'Name';
    let valueField = 'Id';

    if (source.length > 0 && source[0].Id === undefined) {
        valueField = 'Code';
    }

    let options = source.map(i => {
        let searchField = i[labelField] + i.Code + (i.MnemonicCode || '');
        return {
            label:       i[labelField],
            value:       i[valueField],
            searchField: searchField,
        };
    });

    let dataType  = getDataType(source);
    return { options, dataType };
}

function getDataType(source: any[]){

    //这儿有bug，如果长度为0的时候无法获取， 后续改成主动传入或更精准的判定方式
    let firstItem = source[0];
    if (!firstItem){
        return undefined;
    }

    let dataType  = firstItem.DataType;
    if (dataType) {
        return dataType;
    }

    //数据字典类型用 DataDic.Group 的方式标记
    let group = (firstItem as any)?.Group;
    if (group) {
        return  'DataDic.' + group;
    }
}


function getSourceByName(name): BasicData[] {
    let source = $ris.dataDic[name];
    if (source)
        return source;

    source = $ris.basicData[name]
    if (source)
        return source;

    return undefined;
}


/**
 * select 的过滤函数
 */
export function searchFilter(input, option) {
    input = input.toLowerCase();
    return option.searchField?.toLowerCase().indexOf(input) >= 0;
}
