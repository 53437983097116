import { ref } from "vue";

export type OrderScheme = ReturnType<typeof getOrderScheme>

export function getOrderScheme() {

    let scheme = {
        Patient: {
            Name:          field('患者姓名'),
            SpellName:     field('拼音名'),
            Gender:        field('性别').asSelect('Gender'),
            Age:           field('年龄'),
            AgeUnit:       field('null').asSelect('AgeUnit'),
            DateOfBirth:   field('出生日期'),
            Nation:        field('民族').asSelect('Nation', true),
            IDCard:        field('身份证号'),
            Marriage:      field('婚姻状况').asSelect('Marriage', true),
            SocietyNumber: field('社保号'),
            Telephone:     field('联系电话'),
            Address:       field('地址'),
            Email:         field('邮箱'),
        },
        Visit:   {
            IsVIP:               field('VIP'),
            VipReportDoctorId:   field('null').asSelect('Staffs'),
            PatientType:         field('患者来源').asSelect('PatientType'),
            Critical:            field('危重值').asSelect('Critical'),
            EmergencyDegree:     field('紧急程度').asSelect('EmergencyDegree'),
            DoctorAdviceType:    field('医嘱类型').asSelect('DoctorAdviceType'),
            MedicalRecordNumber: field('病案号'),
            VisitSerialNumber:   field('就诊流水号'),
            InpatientNumber:     field('住院号'),
            ClinicalNumber:      field('门诊号'),
            RoomNumber:          field('病区号'),
            BedNumber:           field('病床号'),
            PatientIdentity:     field('身份').asSelect('PatientIdentity', true),
            ChargeType:          field('费用类别').asSelect('ChargeType', true),
            AllergyHistory:      field('过敏史'),
            InfectionResult:     field('传染病检查').asSelect('InfectionResult', true),
            HpTestResult:        field('UBT').asSelect('HpTestResult', true),
            ClinicalDiagnosis:   field('临床诊断'),
            LaboratoryReport:    field('化验结果'),
            OtherDiagnosis:      field('其它诊断'),
            Symptom:             field('症状'),
            Sign:                field('体征'),
            DiseaseHistory:      field('病史'),
            PastHistory:         field('既往史'),
        },
        Order:   {
            HisPatientId:      field('HIS患者编号'),
            HisOrderCode:      field('HIS申请单号'),
            ApplyDepartmentId: field('申请科室').asSelect('Departments'),
            ApplyDoctorId:     field('申请医生').asSelect('Staffs', true),
            ExecDepartmentId:  field('检查科室').asSelect('Departments', true),
            ModalityCode:      field('设备类型').asSelect('Modality'),
            DeviceId:          field('检查室').asSelect('Devices'),
            OrderSpecialClass: field('专项检查'),
            DiagnoseGroupId:   field('诊断组').asSelect('DiagnosisGroups', true),
            HisExamName:       field('HIS检查项目'),
            CheckItems:        field('检查项目'),
            TotalFee:          field('总费用'),
            SpecialRequire:    field('特殊要求'),
            Note:              field('备注'),
        },
    }


    for (let field in scheme.Patient) {
        fixFields('Patient', field);
    }

    for (let field in scheme.Visit) {
        fixFields('Visit', field);
    }

    for (let field in scheme.Order) {
        fixFields('Order', field);
    }

    initFieldsDisplay(scheme);

    function fixFields(target, field) {
        let value    = scheme[target][field] as SchemeField;
        value.target = target;
        value.field  = field;
    }

    return scheme;
}


/**
 * 应用子系统显示隐藏字段
 */
function initFieldsDisplay(scheme: OrderScheme) {

    let schemeFields = Object.values(scheme).flatMap(Object.values) as SchemeField[];

    let config         = $ris.portal.config.OrderFields;
    let requiredFields = new Set(config.RequiredFields);
    let hideFields     = new Set(config.HideFields);
    let removeFields   = new Set(config.RemovedFields);


    for (const scheme of schemeFields) {
        let path           = scheme.target + '.' + scheme.field;
        scheme.displayFlag = getDisplayFlag(path);
    }


    //强制为必填的字段
    let defaultRequiredFields = [
        scheme.Patient.Name,
        scheme.Patient.SpellName,
        scheme.Patient.Gender,

        scheme.Visit.PatientType,

        scheme.Order.ModalityCode,
        scheme.Order.DeviceId,
    ];

    defaultRequiredFields.forEach(f => f.displayFlag = 'required');


    //获取字段显示标志
    function getDisplayFlag(path: string): DisplayFlagType {
        if (requiredFields.has(path)) {     //对必填字段设置required标记, 部分字段是默认必填的，不受此配置限制
            return 'required';
        }

        if (removeFields.has(path)) {   //对删除字段设置removed标记
            return 'removed'
        }

        if (hideFields.has(path)) {     //对可隐藏字段设置visible标记
            return 'autoHide';
        }

        return undefined;
    }
}


let defaultVisible = ref(true);
let defaultFalse   = ref(false);

type DisplayFlagType = 'required' | 'removed' | 'autoHide' | undefined

export class SchemeField {
    label: string;
    type?: 'text' | 'select';
    displayFlag: DisplayFlagType;

    dic?: string;                   //字典类型， select类型有效
    selectOpt: any;
    allowClear?: boolean;           //是否可以清除， select类型有效

    target?: string;                 //一级对象字段，绑定使用，Patient | Visit | Order
    field?: string;                 //二级对象字段，绑定使用


    constructor(label) {
        this.type  = 'text';
        this.label = label;
    }


    asSelect(source: string, allowClear: boolean = false) {
        this.type       = 'select';
        this.dic        = source;
        this.allowClear = allowClear;

        this.selectOpt = $util.form.getOptions(source);
        return this;
    }


    get visible() {
        switch (this.displayFlag) {
            case 'autoHide':
                return $ris.stations.order.viewOption.showAll;
            case 'removed':
                return defaultFalse;
            default:
                return defaultVisible;
        }
    }

    get required() {
        return this.displayFlag === 'required';
    }

}


function field(label: string) {
    return new SchemeField(label);
}



