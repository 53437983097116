import * as api from '@api/main/caClient'
import { Modal } from "ant-design-vue";
import { StoreHelper } from "@src/scripts/util/StoreHelper"
import { loginByCert, LoginResult, LoginType } from '@scripts/ris/auth/login'

export class CAIntegration {
    private supportedFeatures: number | any = 0;
    private enable: boolean = false;
    private dlg = null;
    private store = new StoreHelper<CAUserInfo>('caUserInfo');

    private showMessage(content: string, retry: Function = null, cancel: Function = null) {
        if (!this.dlg) {
            this.dlg = Modal.confirm({
                centered: true,
                keyboard: false,
                content,
                okButtonProps: { disabled: !retry, type: 'primary' },
                cancelButtonProps: { disabled: !cancel },
                onCancel: () => {
                    cancel && cancel();
                    this.dlg.destroy();
                    this.dlg = null;
                },
                onOk: () => {
                    retry && retry();
                    this.dlg = null;
                },
                cancelText: '取消',
                okText: '重试',
                autoFocusButton: 'ok'
            })
        }
        else {
            this.dlg.update({
                content, okButtonProps: { disabled: !retry },
                cancelButtonProps: { disabled: !cancel },
                cancelText: '取消',
                okText: '重试',
                onCancel: () => {
                    cancel && cancel();
                    this.dlg.destroy();
                    this.dlg = null;
                },
                onOk: () => {
                    retry && retry();
                    this.dlg = null;
                }
            });
        }
    }

    private hideMessage() {
        this.dlg && this.dlg.destroy();
        this.dlg = null;
    }

    private async load() {
        try {
            await api.ping();
            let features = await api.getFeatures();
            this.enable = true;
            this.supportedFeatures = features;
        }
        catch (e) {
            throw lang.clientHelperNotInstalled;
        }
    }

    async isSupportGetSignaturePicture(): Promise<boolean> {
        if (!this.enable) await this.load();
        return (this.supportedFeatures & api.CAFeatures.GetSignaturePicture) != 0;
    }

    async getCertList(fRetry: Function, fCancel: Function) {
        this.showMessage(lang.caInitializing);
        try {
            await this.load();

            this.showMessage(lang.loadingCerts);

            let certs: api.CACertInfo[] = null;
            try {
                certs = await api.getCertificates();
            }
            catch (certErr) {
                this.showMessage(`读取证书失败.${certErr}`, fRetry, fCancel);
                return;
            }

            if (certs == null || certs.length == 0) {
                this.showMessage(lang.noDevicePlugged, fRetry, fCancel);
                return [];
            } else {
                this.hideMessage();
                return certs;
            }
        }
        catch (error:any) {
            this.showMessage(error, null, fCancel);
        }
    }

    async loginToCA(userInfo: api.CALoginRequest): Promise<api.CAVerifyResult> {
        this.showMessage(lang.Logining);

        try {
            await api.login(userInfo.CertId, userInfo.Password);

            this.showMessage(lang.validatingCert);

            var uniqueId = await api.getCertUniqueId(userInfo.CertId);

            this.showMessage(lang.gettingBoundUserInfo);
            return {
                UniqueId: uniqueId,
                CertificateId: userInfo.CertId,
                UserCert: await api.exportCertificate(userInfo.CertId)
            } as api.CAVerifyResult;
        } finally {
            this.hideMessage();
        }
    }

    async login(userInfo: api.CALoginRequest, updateStore: boolean = true): Promise<LoginResult> {
        let caloginRslt = await this.loginToCA(userInfo);
        let result = await loginByCert(caloginRslt.UniqueId, userInfo.ServerNodeId, 'CA')

        if (updateStore) {
            this.store.setValue({
                LoginType: 'CA',
                CertificateUserId: userInfo.CertUserId,
                CertId: caloginRslt.CertificateId,
                UserCert: caloginRslt.UserCert,
                StaffId: result.StaffId
            } as CAUserInfo);
        }

        return result;
    }

    /**
     * 检查登录状态，由于单点登录没有传递CA登录信息，因此需要检测登录状态，通过签名和验签
     * @param certId 
     * @returns 
     */
    async checkLogin() {
        let certId = this.curUserInfo.CertId;
        if (await api.isCertAvailable(certId)) {
            return await api.isLoggedIn(certId);
        }
        throw lang.loginCertNotFound;
    }

    getSignaturePicture(certId: string) {
        return api.getSignatureBase64Image(certId);
    }

    clearCAUserInfo() {
        this.store.removeValue();
    }

    get curUserInfo() {
        return this.store.getValue();
    }

    async createSignature(originalData: string) {
        let certInfo = this.store.getValue();
        if (!certInfo) return;

        try {
            this.showMessage(lang.signCreating);

            let signaResult = {
                OriginalData: originalData,
                SignedData: await api.createSignature(certInfo.CertId, originalData),
                TimeStamp: null,
                ReportId: undefined,
                SystemCode: undefined,
                CertificateId: certInfo.CertId,
                CertificateData: certInfo.UserCert
            };

            this.showMessage(lang.signCreated);

            if ($ris.appSetting.EnableCATimeStamp) {
                this.showMessage(lang.timeStampCreating);

                signaResult.TimeStamp = await api.createTimeStamp(originalData);

                this.showMessage(lang.timeStampCreated);
            }
            await $util.delay(300);
            return signaResult;
        }
        finally {
            this.hideMessage();
        }
    }

    async verifySignature(certificate: string, originalData: string, signature: string, timeStamp: string) {
        let rslt = {
            Sign: await api.verifySignature(certificate, originalData, signature)
        } as VerifyResult;

        if (!!timeStamp) {
            try {
                rslt.TimeStamp = await api.verifyTimeStamp(timeStamp, originalData);
            }
            catch (e) {
                rslt.TimeStamp = false;
            }
        }
        return rslt;
    }
}

export interface CAUserInfo {
    LoginType: LoginType,
    CertificateUserId: string,
    CertId: string,
    UserCert: string,
    StaffId: string
}

export interface VerifyResult {
    Sign: boolean,
    TimeStamp?: boolean
}

export const lang = {
    10000: "成功",
    10001: "失败",
    10002: "获取sn失败，未安装驱动程序/未插Key/无法取得签名证书SN！",
    10003: "签名验签失败",
    10004: "修改pin码失败",
    10005: "校验pin码失败",
    10006: "获取用户信息失败",
    10007: "认证失败。",
    10008: "网络问题。",
    10009: "pin长度不对，应当为6-8位",
    10010: "证书驱动没有装",
    10011: "证书Key没有就绪",
    11000: "出现未知错误",
    20000: "PIN值错误",
    20001: "不能为空",
    20002: "uKey与用户不一致，请确认",
    20003: "该报告无需执行验签操作，因为它不是已验签报告",
    verify: "验签",
    noBindedUser: "当前证书没有绑定用户。",
    getBindedUserError: "获取CA绑定用户异常！",
    noSignature: "此报告没有进行签名。",
    notCurrentUser: "证书非当前登录用户。",
    // waiting
    caInitializing: "正在连接CA服务...",
    loadingCerts: "正在读取证书...",
    loggingIn: "正在验证登录信息...",
    validatingCert: "正在验证证书...",
    gettingCertInfo: "正在获取证书信息...",
    gettingBoundUserInfo: "正在获取用户信息...",
    signCreating: '正在进行签章...',
    signCreated: '签章完成.',
    timeStampCreating: '正在创建时间戳...',
    timeStampCreated: '创建时间戳完成.',
    // failures
    unexpectedError: "发生了未知错误。",
    caInitializingFailed: "连接 CA 服务失败。",
    loadingCertsFailed: "读取证书失败。",
    clientHelperNotInstalled: "miPlatform 客户端助手未安装或相关服务未启动。",
    noDevicePlugged: "没有检测到 CA 证书。请插入 CA USB Key。",
    certNotFound: "选择的证书未找到。USB Key 未插入或已断开。",
    certNotLoggedIn: "证书未登录，或登录状态丢失。请重新登录。",
    loginCertNotFound: "未找到登录时所用证书。请插入正确的 USB Key。",
    startingClientHelperFailed: "启动 miPlatform 客户端助手失败。请稍后重试。",
    retryLoading: "重新加载",
    Logining: "登录中，请稍候...",
};