<template>
  <div>
    <action-view/>

    <paper-order :ref="view.paperOrder"/>
    <order-detail :ref="view.orderDetail"/>
    <his-order :ref="view.hisOrder"/>
    <ReSchedule :ref="view.reSchedule"/>
    <ReportViewer :ref="view.reportViewer"/>
    <prompt :ref="view.promot" />
    <relogin :ref="view.reLogin"/>
    
  </div>

</template>

<script setup lang="ts">

import Prompt       from '../../common/dialgos/prompt.vue'
import ActionView   from "./actionView.vue";
import PaperOrder   from "../../order/share/paperOrder/paperOrder.vue";
import OrderDetail  from "../../order/share/orderViewer/orderDetail.vue";
import HisOrder     from "../../order/share/hisOrder/hisOrder.vue";
import ReportViewer from '../../report/share/reportViewer.vue';
import ReSchedule   from '../../order/share/scheduleEditor/reSchedule.vue';
import relogin      from "@src/pages/desktop/actions/relogin.vue"


import { globalView } from "@scripts/ris/common";


let view = globalView;

</script>

<style scoped>

</style>