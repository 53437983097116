<template>

  <div>
    <a-badge-ribbon text="未追踪" v-if="!data.CreateTime"/>
    <a-descriptions bordered size="small" :column="2">
      <a-descriptions-item label="患者姓名">
        {{ data.PatientName }}
      </a-descriptions-item>

      <a-descriptions-item label="性别">
        {{ data.Gender }}
      </a-descriptions-item>

      <a-descriptions-item label="年龄">
        {{ data.Age }}
      </a-descriptions-item>

      <a-descriptions-item label="患者来源">
        {{ data.PatientType }}
      </a-descriptions-item>

      <a-descriptions-item label="患者编号">
        {{ data.PatientId }}
      </a-descriptions-item>

      <a-descriptions-item label="检查号">
        {{ data.AccessionNumber }}
      </a-descriptions-item>

      <a-descriptions-item label="检查时间">
        <div style="width: 220px;">
          {{ $util.display.timeToStr(data.StudyTime) }}
        </div>
      </a-descriptions-item>

      <a-descriptions-item label="病理号">
        <a-input style="width: 220px; margin-top: -4px; margin-bottom: -4px"
                 v-model:value="data.PathologyNo" :disabled="disabled"/>
      </a-descriptions-item>

      <a-descriptions-item label="检查项目" span="2">
        {{ data.CheckItems }}
      </a-descriptions-item>

    </a-descriptions>

    <a-divider orientation="left">病例追踪内容</a-divider>
    <a-form :labelCol="{ span: 3 }" :wrapper-col="{ offset: 1, span: 20 }">
      <a-form-item label="符合度">
        <a-radio-group :options="conformityOpt()"
                       v-model:value="data.Conformity" :disabled="disabled"/>
      </a-form-item>

      <a-form-item label="临床诊断">
        <a-textarea :auto-size="{ minRows: 2 }" :maxlength="2048"
                    v-model:value="data.ClinicalDiagnosis" :disabled="disabled"/>
      </a-form-item>

      <a-form-item label="影像诊断">
        <a-textarea :auto-size="{ minRows: 2 }" :maxlength="2048"
                    v-model:value="data.ImageDiagnosis" :disabled="disabled"/>
      </a-form-item>

      <a-form-item label="临床诊疗方法">
        <a-textarea :auto-size="{ minRows: 2 }" :maxlength="2048"
                    v-model:value="data.ClinicalDiagnosisMethord" :disabled="disabled"/>
      </a-form-item>

      <a-form-item label="病理结果">
        <a-textarea :auto-size="{ minRows: 2 }" :maxlength="2048"
                    v-model:value="data.PathologyResult" :disabled="disabled"/>
      </a-form-item>

      <a-form-item label="治疗转归">
        <a-textarea :auto-size="{ minRows: 2 }" :maxlength="2048"
                    v-model:value="data.OutcomeOfTherapy" :disabled="disabled"/>
      </a-form-item>
    </a-form>

    <a-button v-if="showEditBtn && !disabled" type="primary" style="margin-left: 24px" @click="save">
      <ris-icon icon="SaveOutlined"/>
      保存
    </a-button>
  </div>

</template>

<script setup lang="ts">

import { computed, ref, watch } from 'vue'
import * as api                 from '@api/report/exInfo'


const props = defineProps<{
  reportId?: string,
  readOnly?: boolean,
  showEditBtn?: boolean,
}>();

let canEdit  = $ris.user.Actions.ApproveReport || $ris.user.Actions.WriteReport;
let disabled = computed(() => props.readOnly || !canEdit);

defineExpose({
  save,
});

let data = ref<any>({});
watch(() => props.reportId, () => update(), { immediate: true });

async function save() {
  await api.setReportFollowUp(data.value);
  await update(false);
  $util.message.success("保存成功!");
}

async function update(restValue = true) {
  if (restValue) {
    data.value = {};
  }

  data.value = await api.getReportFollowUp(props.reportId);
}


function conformityOpt() {
  return [
    { label: '未知', value: 0 },
    { label: '不符合', value: 1 },
    { label: '符合', value: 2 },
  ];
}

</script>

<style lang="less" scoped>

.ant-descriptions ::v-deep(.ant-descriptions-item-label) {
  padding: 6px 16px; /* 调低label高度，减少空间占用 */
  width: 96px;
}


</style>