import * as gridEx     from '@scripts/controls/extend/grid'
import * as gridHelper from '@scripts/controls/extend/gridHelper'


const instance   = gridHelper.instance;
const helper     = gridHelper.getGridHelper;

let gridUtil = {
    gridOption : gridEx.makeGridOption,
    instance,
    helper,

    customColumns(gridRef) {
        helper(gridRef).customColumns();
    },


    /**
     * 清除选中状态
     */
    clearSelection(gridRef) {
        helper(gridRef).clearSelection();
    },


    getFocusData(gridRef) {
        return helper(gridRef).getFocusData();
    },

    /**
     * 获取选中的key
     */
    getSelectedKeys(gridRef) {
        return helper(gridRef).getSelectedKeys();
    },

    /**
     * 获取选中的项
     */
    getSelectItems(gridRef) {
        return helper(gridRef).getSelectedItems();
    },


}

export default gridUtil;


//searchPanel   <DxSearchPanel :visible="false" v-model:text="searchText"/>

//method api可参考  https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Methods/